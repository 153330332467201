import React, { useState } from 'react';

import { Grid, Flex, TableView, TableHeader, TableBody, Row, Column, repeat, Text } from '@adobe/react-spectrum';
import { tableResultsCellRenderers } from '../../resultsListing/renderers/TableResultsCellRenderers';
import { Lightbox } from 'react-modal-image';
import isMobile from 'ismobilejs';

export function CommandersPane(props) {
    const mobile = isMobile(window.navigator).phone;

    const [imgPreviewUri, setImgPreviewUri] = useState(null);
    const renderBlob = (tier) => {
        const columns = [
            { name: '#', uid: 'position', maxWidth: 70, minWidth: 70, width: 70 },
            { name: 'Commander(s)', uid: 'names', maxWidth: 600, minWidth: 100 },
            // { name: 'Entries', uid: 'entries', maxWidth: 70, minWidth: 70, width: 70 },
            { name: 'Win %', uid: 'winRate', maxWidth: 80, minWidth: 80, width: 80 },
        ];

        const getColumnRenderer = (item) => {
            let content;

            return <div style={{ fontSize: '0.7rem' }}>{item.name}</div>;
        };

        let count = 0;
        const rows =
            props?.metaBlob?.meta?.tiers?.[tier?.toLowerCase()]?.ids?.map((id) => {
                const _metadata = props?.metaBlob?.commandersMetadata?.[id];

                return {
                    id,
                    position: _metadata.position,
                    names: _metadata?.names || [],
                    entries: _metadata?.entries || 0,
                    winRate: parseFloat(_metadata?.metaShare || 0).toFixed(2),
                    imageUris: _metadata?.imageUris || [],
                };
            }) || [];

        return (
            <div id={`__cardsByType__${tier}`}>
                <Flex direction={'column'} maxWidth={900} marginStart={10} marginEnd={20}>
                    <div className={'commanderTiersTileStatisticsHeader'}>Tier {tier}</div>
                    <TableView overflowMode="wrap" height={700} density="compact">
                        <TableHeader columns={columns}>
                            {(column) => (
                                <Column
                                    key={column.uid}
                                    align={column.uid === 'date' ? 'end' : 'start'}
                                    maxWidth={column?.maxWidth}
                                    width={column?.width}
                                    minWidth={column?.minWidth}
                                >
                                    {getColumnRenderer(column)}
                                </Column>
                            )}
                        </TableHeader>
                        <TableBody items={rows}>
                            {(item) => (
                                <Row>
                                    {(columnKey) =>
                                        tableResultsCellRenderers.CARD_LIST(item, columnKey, setImgPreviewUri)
                                    }
                                </Row>
                            )}
                        </TableBody>
                    </TableView>
                </Flex>
            </div>
        );
    };

    return (
        <Flex direction={'column'}>
            {imgPreviewUri && (
                <div onClick={() => setImgPreviewUri(null)}>
                    <Lightbox
                        medium={''}
                        large={imgPreviewUri}
                        hideDownload={true}
                        hideZoom={true}
                        onClose={() => setImgPreviewUri(null)}
                    />
                </div>
            )}

            <Text alignSelf={'center'} marginTop={50} marginBottom={50} UNSAFE_style={{ color: '#ffdd00' }}>
                <b>
                    Win percentage is the number of top 16 results for a given commander divided by the total number of
                    top 16 slots available.
                </b>
            </Text>

            <Grid columns={repeat('auto-fit', mobile ? 400 : 650)} gap={50} justifyContent="center">
                {renderBlob('One')}
                {renderBlob('Two')}
                {renderBlob('Three')}
                {renderBlob('Four')}
            </Grid>
        </Flex>
    );
}
