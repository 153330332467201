import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector, getDomainPrefix, getUser } from '../../DynamoConnector';
import { fetchCommanderLeaderboardItems } from './commandersSlice';
import { fetchAll, SORT_MAP } from './leaderboardSlice';
import { fetchPreviewDeck, setPreviewIsShowingFalse } from './previewSlice';
import { fetchAuthorsItems } from './authorsSlice';
import { fetchPod } from './podsSlice';
import { fetchCommanderDetails } from './commanderDetailsSlice';
import { fetchAuthorDetails } from './authorDetailsSlice';
import { fetchDeck } from './deckSlice';
import { fetchMetaList } from './metaSlice';
import { fetchCardById } from './cardsSlice';
import { fetchArchetypesItems } from './archetypesSlice';
import { finalizePatreonCode, getManageDecksList, getUserProfile } from './profileSlice';
import { fetchUserDetails } from './userDetailsSlice';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        windowWidth: 0,
        isHydrated: false,
        stats: {
            totalCount: 0,
            ranges: {
                saltRating: {
                    high: 0.0,
                    low: 0.0,
                },
                interactionRating: {
                    high: 0.0,
                    low: 0.0,
                },
                comboRating: {
                    high: 0.0,
                    low: 0.0,
                },
            },
        },
        route: {
            path: '',
            previousRoute: '',
            // isFirstRouteChange: true,
        },
        title: '',
        preventNextLoadMore: false,
        isSendingEmail: false,
        hasSentEmail: false,
        firstRouteHandled: false,
        routeChangeRefreshPending: null,
        allowBetaFeatures: false,
    },
    reducers: {
        setWindowWidth: (state, action) => {
            state.windowWidth = action.payload;
        },
        setStats: (state, action) => {
            state.stats = action.payload;
        },
        setIsHydrated: (state) => {
            state.isHydrated = true;
        },
        _updateAppRoute: (state, action) => {
            state.route = {
                // set previous route before overriding
                previousRoute: state.route.path,
                //set current route
                path: action.payload,
                // isFirstRouteChange: false,
            };
            state.preventNextLoadMore = true;
        },
        _updateAppTitle: (state, action) => {
            state.title = action.payload;
        },
        resetPreventNextLoadMore: (state) => {
            state.preventNextLoadMore = false;
        },
        setIsSendingEmail: (state, action) => {
            state.isSendingEmail = action.payload;
        },
        setHasSentEmail: (state, action) => {
            state.hasSentEmail = action.payload;
        },
        setFirstRouteHandled: (state, action) => {
            state.firstRouteHandled = true;
        },
        setRouteChangeRefreshPending: (state, action) => {
            state.routeChangeRefreshPending = action.payload;
        },
        setAllowBetaFeatures: (state, action) => {
            state.allowBetaFeatures = action.payload;
        },
    },
});

// Actions
export const {
    setStats,
    setIsHydrated,
    resetPreventNextLoadMore,
    setIsSendingEmail,
    setHasSentEmail,
    setFirstRouteHandled,
    setRouteChangeRefreshPending,
    setAllowBetaFeatures,
    _updateAppRoute,
    _updateAppTitle,
} = appSlice.actions;

const parseDeckId = (uri) => {
    if (window.location.pathname.includes(`/deck/`)) {
        return window.location.pathname.substring(window.location.pathname.indexOf(`/deck/`) + 6);
    }

    // const indexString = `/details/deck/`;
    // if (uri.indexOf(indexString) > -1) {
    //     return uri.substring(uri.indexOf(indexString) + indexString.length);
    // }

    return null;
};

// Selectors
export const hydrate = () => (dispatch) => {
    dispatch(setIsHydrated(true));
    dispatch(getAppStats());
    // dispatch(fetchMetaList(false));
    dispatch(
        fetchAll(
            null,
            {
                query: '',
                sources: [
                    'www.moxfield.com',
                    'www.archidekt.com',
                    'www.tappedout.net',
                    'www.manabox.app',
                    'www.mtggoldfish.com',
                    'www.deckstats.net',
                    'www.topdecked.com',
                    'www.hareruyamtg.com',
                    // 'www.melee.gg',
                ],
            },
            true,
            SORT_MAP.POWERLEVEL
        )
    );

    let uri = window.location.toString();
    if (uri.indexOf(`//`)) {
        uri = uri.substring(uri.indexOf('//') + 2);
    }

    const indexString = `/`;
    const initialRoute = uri.substring(uri.indexOf(indexString));
    console.log(JSON.stringify(new URL(document.location).searchParams));

    try {
        let params = new URL(document.location).searchParams;

        if (params.get('beta')) {
            dispatch(setAllowBetaFeatures(true));
        }

        if (window.location?.href?.includes('localhost')) {
            dispatch(setAllowBetaFeatures(true));
        }
    } catch (error) {}

    const user = getUser();
    if (user) {
        dispatch(getUserProfile());
    }

    if (window.location.href.includes(`settings?code`)) {
        const code = new URLSearchParams(window.location.search).get('code');
        dispatch(finalizePatreonCode(code));
    }

    handleAndDispatchNavLabelChanges(getParsedRoute(initialRoute), dispatch);
    // dispatch(setAppRoute(initialRoute));
    // dispatch(handleUpdatedAppRoute({ route: initialRoute }));
};

export const getAppStats = () => (dispatch) => {
    DynamoConnector.getStats((results) => {
        dispatch(setStats(results));
    });
};

const getParsedRoute = (route) => {
    // console.log(JSON.stringify(route));
    const isCommanders = route.includes(`/commanders`);
    const isAuthor = route.includes(`/authors`);
    const isUser = route.includes(`/user`);
    const isPod = route.includes(`/pods`);
    const isCard = route.includes(`/cards`);

    let parsedRoute = route.indexOf(`details/deck/`) > -1 ? `/details` : route;
    parsedRoute = isCommanders ? `/commanders` : parsedRoute;
    parsedRoute = isAuthor ? `/authors` : parsedRoute;
    parsedRoute = isPod ? `/pods` : parsedRoute;
    parsedRoute = isCard ? `/cards` : parsedRoute;
    parsedRoute = isUser ? `/user` : parsedRoute;

    return parsedRoute;
};

const handleAndDispatchNavLabelChanges = (route, dispatch) => {
    let newRoute = '';
    let newTitle = 'Leaderboard';
    const parsedRoute = getParsedRoute(route);

    switch (parsedRoute) {
        case '/changelog':
            newRoute = `/changelog`;
            newTitle = `Changelog`;
            break;
        case '/faq':
            newRoute = `/faq`;
            newTitle = `FAQ`;
            break;
        case '/commanders':
            newRoute = route;
            newTitle = `Commanders`;
            break;
        case '/details':
            newRoute = route;
            newTitle = `Details`;
            break;
        case '/contact':
            newRoute = `/contact`;
            newTitle = `Contact`;
            break;
        case '/user':
            newRoute = route;
            newTitle = `Users`;
            break;
        case '/authors':
            newRoute = route;
            newTitle = `Authors`;
            break;
        case '/bot':
            newRoute = route;
            newTitle = `Discord Bot`;
            break;
        case '/meta':
            newRoute = route;
            newTitle = `cEDH Meta`;
            break;
        case '/powerlevels':
            newRoute = route;
            newTitle = `Power Levels`;
            break;
        case '/algorithm':
            newRoute = route;
            newTitle = `Algorithm`;
            break;
        case '/archetypes':
            newRoute = route;
            newTitle = `Archetypes`;
            break;
        case '/cards':
            newRoute = route;
            newTitle = `Cards`;
            break;
        case '/profile':
            newRoute = route;
            newTitle = `Profile`;
            break;
        case '/linkedaccounts':
            newRoute = route;
            newTitle = `Accounts`;
            break;
        case '/managedecks':
            newRoute = route;
            newTitle = `Decks`;
            break;
        case '/settings':
            newRoute = route;
            newTitle = `Settings`;
            break;
        default:
            newRoute = `/`;
            newTitle = `Leaderboard`;
    }

    dispatch(_updateAppTitle(newTitle));
    return newRoute;
};

export const handleUpdatedAppRoute = (payload) => (dispatch) => {
    let { route } = payload;
    dispatch(getAppStats());

    if (route) {
        const parsedRoute = getParsedRoute(route);
        const id = route.substring(route.indexOf('/id/') + 4);

        switch (parsedRoute) {
            case '/pods':
                if (route?.includes(`/id/`)) {
                    dispatch(fetchPod(id));
                }

                break;
            case '/commanders':
                dispatch(setPreviewIsShowingFalse());

                if (route?.includes(`/id/`) && !global.__hasSwitchedRoutes) {
                    dispatch(fetchCommanderDetails(id));
                } else {
                    if (!global.__hasLoadedCommanders || !global.__hasSwitchedRoutes) {
                        dispatch(fetchCommanderLeaderboardItems(null, true, SORT_MAP.POWERLEVEL));
                        global.__hasLoadedCommanders = true;
                    }
                }

                break;
            case '/details':
                const deckId = parseDeckId(route);

                if (deckId) {
                    // dispatch(fetchPreviewDeck(deckId));
                    dispatch(fetchDeck(deckId));
                }

                break;
            case '/authors':
                dispatch(setPreviewIsShowingFalse());

                if (route?.includes(`/id/`) && !global.__hasSwitchedRoutes) {
                    dispatch(fetchAuthorDetails(id));
                } else {
                    if (!global.__hasLoadedAuthors || !global.__hasSwitchedRoutes) {
                        dispatch(fetchAuthorsItems(null, true, SORT_MAP.POWERLEVEL));
                        global.__hasLoadedAuthors = true;
                    }
                }

                break;
            case '/settings':
                if (!global.__hasSwitchedRoutes) {
                    dispatch(setAppRoute({ route: `/}` }));
                }

                break;
            case '/user':
                if (route?.includes(`/id/`) && !global.__hasSwitchedRoutes) {
                    dispatch(fetchUserDetails(id));
                }

                break;
            case '/cards':
                dispatch(setPreviewIsShowingFalse());

                if (route?.includes(`/id/`)) {
                    dispatch(fetchCardById(id));
                }

                break;
            case '/archetypes':
                dispatch(fetchArchetypesItems(null, true, SORT_MAP.POWERLEVEL));
                break;
            // case '/algorithm':
            // case '/powerlevels':
            case '/meta':
                dispatch(fetchMetaList(false));
                break;
            case '/managedecks':
                // if (!global.__hasSwitchedRoutes) {
                dispatch(getManageDecksList());
                // }
                break;
            default:
                dispatch(setPreviewIsShowingFalse());
        }

        handleAndDispatchNavLabelChanges(route, dispatch);
    }

    global.__hasSwitchedRoutes = true;
};

export const setAppRoute = (payload) => (dispatch) => {
    let { route } = payload;
    if (route) {
        const parsedRoute = getParsedRoute(route);
        const newRoute = handleAndDispatchNavLabelChanges(route, dispatch);
        dispatch(_updateAppRoute(newRoute));
    }
};

export const sendFeedback = (payload) => (dispatch) => {
    dispatch(setIsSendingEmail(true));

    DynamoConnector.postContactPing(payload, () => {
        dispatch(setIsSendingEmail(false));
        dispatch(setHasSentEmail(true));
    });
};

export default appSlice.reducer;
