import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Cell, Content, Flex, Text, View } from '@adobe/react-spectrum';
import { SORT_MAP } from '../../data/redux/slices/leaderboardSlice';
import './Commanders.css';
import {
    commandersFetchFiltered,
    fetchCommanderLeaderboardItems,
    setCommandersSortBy,
    setCommandersSortDirection,
    setSelectedListIndex,
} from '../../data/redux/slices/commandersSlice';
import { getGrades } from '../../data/ScoreDisplayUtils';
import { Helmet } from 'react-helmet';
import { ResultsListing } from '../resultsListing/ResultsListing';
import { setAppRoute } from '../../data/redux/slices/appSlice';
import { FilterPanel } from '../filterpanel/FilterPanel';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { fetchCommanderDetails } from '../../data/redux/slices/commanderDetailsSlice';

export function Commanders() {
    const dispatch = useDispatch();
    const listItems = useSelector((state) => state?.commanders?.listItems);
    const nextCursor = useSelector((state) => state?.commanders?.nextCursor);
    const isFetching = useSelector((state) => state?.commanders?.isFetching);
    const loadingState = useSelector((state) => state?.commanders?.loadingState);
    const searchFilters = useSelector((state) => state?.commanders?.filters);
    const details = useSelector((state) => state.commanders?.details);
    const sortBy = useSelector((state) => state?.commanders?.sortBy);
    const selectedListKey = useSelector((state) => state.commanders.selectedListKey);
    const selectedListIndex = useSelector((state) => state.commanders.selectedListIndex);

    const previewIsShowing = useSelector((state) => state.preview.isShowing);

    const handleSortChange = (sortIndex) => {
        dispatch(setCommandersSortBy(SORT_MAP[sortIndex], searchFilters));
        dispatch(fetchCommanderLeaderboardItems(null, true, SORT_MAP[sortIndex], sortDirection));
    };

    const sortDirection = useSelector((state) => state.commanders.sortDirection);
    const handleSortDirectionChange = (direction) => {
        dispatch(setCommandersSortDirection(direction));
        dispatch(fetchCommanderLeaderboardItems(null, true, sortBy, direction, searchFilters));
    };

    const handleFiltersChange = (filters) => {
        dispatch(commandersFetchFiltered(filters, sortBy));
    };

    const handleIndexSelection = (evn) => {
        dispatch(setSelectedListIndex(evn));
    };

    const getCellRenderer = (item, columnKey) => {
        switch (columnKey) {
            case 'tileImages':
                item?.tileImages?.[0] || null;
                const colors = item?.colorIdentity?.match(/./g) || [];

                const getCellImage = () => {
                    if (item?.tileImages?.length > 1) {
                        return (
                            <div className="cellAvatar">
                                <Flex direction={'row'}>
                                    {item.tileImages.map((imageUri) => {
                                        return (
                                            <div
                                                style={{ maxWidth: '32px', overflow: 'hidden', alignContent: 'center' }}
                                            >
                                                <img style={{ marginLeft: -32 }} src={imageUri} height="50px" />
                                            </div>
                                        );
                                    })}
                                </Flex>
                            </div>
                        );
                    } else {
                        return (
                            <div className="cellAvatar">
                                <img src={item?.tileImages?.[0]} height="50px" />
                            </div>
                        );
                    }
                };

                return (
                    <div>
                        <Content height={90}>
                            <Flex direction={'column'} height={90}>
                                {getCellImage()}
                                <div className="cellAvatarManaSymbols">
                                    {colors && colors.length > 0 && (
                                        <div>
                                            {colors?.map((symbol) => {
                                                return (
                                                    <span
                                                        key={`${item?.id}__color${symbol?.toLowerCase()}`}
                                                        className={`mana xs s${symbol?.toLowerCase()}`}
                                                        style={{ opacity: '1' }}
                                                    ></span>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </Flex>
                        </Content>
                    </div>
                );
                break;
            case 'score':
                let score = item.hasOwnProperty('score') ? item.score : item[sortBy?.field];

                if (sortBy.field === SORT_MAP.COUNT.field) {
                    score = item['powerLevelRating'];
                }

                const grade = getGrades(item);
                const prop = sortBy?.enum.toLowerCase();
                let floatValue = parseFloat(/[0-9]*\.[0-9]/.exec(score.toString())?.[0] || '0');
                if (!floatValue.toString().includes(`.`)) {
                    floatValue = `${floatValue}.0`;
                }

                return (
                    <div>
                        <Flex direction={'column'} justifyContent={'space-around'}>
                            <div className="SaltCell" style={{ color: grade?.[prop]?.color, float: 'right' }}>
                                {floatValue}
                            </div>
                            <span className="SaltCellSubData" style={{ marginTop: 5 }}>
                                {item?.count} DECK{item?.count > 1 ? 'S' : ''}
                            </span>
                        </Flex>
                    </div>
                );
                break;
            case 'commanders':
                let commanderList = item?.commanders;
                if (!Array.isArray(commanderList)) {
                    commanderList = [item?.commanders];
                }

                commanderList = commanderList.map((item) => {
                    if (item.hasOwnProperty('name')) {
                        return item.name;
                    }

                    return item;
                });

                let commanders = ``;
                commanderList.forEach((commander) => {
                    if (commanders === ``) {
                        commanders = commander;
                    } else {
                        commanders = `${commanders}\n${commander}`;
                    }
                });

                return (
                    <div>
                        <div className="TextCell">{commanders}</div>
                    </div>
                );

                break;
            default:
                return (
                    <div>
                        <div className="TextCell">{item[columnKey]}</div>
                    </div>
                );
        }
    };

    const getColumnRenderer = (item) => {
        let content;

        if (item.uid === 'score') {
            content = <div />;
        } else if (item.uid === 'tileImages') {
            content = <div />;
        } else {
            content = item.name;
        }

        return <div style={{ minWidth: '83px' }}>{content}</div>;
    };

    const handleLeaderboardSelectionChange = (id) => {
        try {
            if (id) {
                dispatch(fetchCommanderDetails(id));
                dispatch(setAppRoute({ route: `/commanders/id/${id}` }));
            }
        } catch (error) {}
    };

    const handleLoadMore = () => {
        if (!isFetching && nextCursor !== null) {
            dispatch(fetchCommanderLeaderboardItems(nextCursor, false, sortBy, sortDirection));
        }
    };

    const max = window.innerWidth - 180;
    let columns = [
        { name: 'tileImages', uid: 'tileImages', maxWidth: 65, minWidth: 65, width: 65 },
        { name: 'Commander(s)', uid: 'commanders', maxWidth: max, minWidth: 200, width: max },
        { name: '', uid: 'score', width: 90 },
    ];

    let commanderNames = '';
    let commanderNamesOneLine = '';

    for (let i = 0; i < details?.commanders?.length; i++) {
        if (i > 0) {
            commanderNames = `${commanderNames}\n`;
            commanderNamesOneLine = `${commanderNamesOneLine} + `;
        }

        commanderNames = `${commanderNames}${details?.commanders?.[i]?.name}`;
        commanderNamesOneLine = `${commanderNamesOneLine}${details?.commanders?.[i]?.name}`;
    }

    const filteredListItems = (listItems || []).filter((item) => {
        return item !== null && item.hasOwnProperty('id');
    });

    const helmetName = `Commanders`;
    const helmetCanonical = `https://www.commandersalt.com/commanders`;
    const helmetDescription = `Who is the saltiest Commander of them all?`;
    const helmetTitle = `Commandersalt.com - Who is the saltiest Commander of them all?`;

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs />
            <ResultsListing
                includeSortByCount={true}
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSortChange={handleSortChange}
                handleSortDirectionChange={handleSortDirectionChange}
                onFiltersChange={handleFiltersChange}
                filters={searchFilters}
                restrictToCommander={true}
                disableNavChanges={true}
                allowDeckPreview={false}
                handleSelectionChange={handleLeaderboardSelectionChange}
                hasMore={nextCursor !== null}
                handleIndexSelection={handleIndexSelection}
                selectedListIndex={selectedListIndex}
                selectedListIndexPrefix={'commanders_'}
                selectedListKey={selectedListKey}
                columns={columns}
                showFilterPanel={true}
                columnRenderer={getColumnRenderer}
                listItems={filteredListItems}
                cellRenderer={getCellRenderer}
                previewIsShowing={previewIsShowing}
                handleLoadMore={handleLoadMore}
                loadingState={loadingState}
            />
        </Flex>
    );
}
