import React from 'react';
import { useSelector } from 'react-redux';
import { Content, ContextualHelp, Divider, Flex, Heading, Switch, Text } from '@adobe/react-spectrum';

import { BaseDeckPane } from './BaseDeckPane';
import { Bar, BarChart, Legend, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { getPrettyLabel } from '../../../data/CategoryPrettyDisplayUtils';

export function BracketsPane() {
    const deck = useSelector((state) => state.deck.deck);
    const cards = useSelector((state) => state.deck.cards);

    const getCategories = () => {
        const list = {};

        Object.keys(deck?.details?.brackets?.categories)
            ?.filter((category) => {
                switch (category) {
                    case 'restock':
                        return false;
                    case 'cedhStaples':
                        return deck?.details?.brackets?.wotcBracket === 5;
                    default:
                        return true;
                }
            })
            ?.map((category) => {
                let subCategoryList = {};
                let items = [];
                let catObjectKey =
                    category === 'twoCardInfiniteCombo' || category === 'earlyGameInfiniteCombos' ? 'combos' : category;
                let label = getPrettyLabel(catObjectKey);

                if (category === 'twoCardInfiniteCombo' || category === 'earlyGameInfiniteCombos') {
                    subCategoryList[category] = {
                        label: `${getPrettyLabel(category).toUpperCase()}`,
                        id: category,
                        count: deck.details.brackets.categories[category].list.length,
                        list:
                            deck.details.brackets.categories[category].list.map((_key) => {
                                let source = null;
                                deck?.details?.combos?.list?.forEach((combo) => {
                                    if (combo.id === _key) {
                                        source = combo;
                                    }
                                });

                                if (source) {
                                    return {
                                        id: _key,
                                        type: 'combo',
                                        cards: (source?.cards || []).map((__card) => {
                                            return cards?.[__card];
                                        }),
                                    };
                                }
                            }) || [],
                        parentCategory: catObjectKey,
                    };

                    if (list.hasOwnProperty(catObjectKey)) {
                        list[catObjectKey].subCategories = {
                            ...list[catObjectKey].subCategories,
                            ...subCategoryList,
                        };
                        list[catObjectKey].list = Array.from(
                            new Set(list[catObjectKey].list.concat(subCategoryList[category].list))
                        );

                        const foundIds = [];
                        list[catObjectKey].list = list[catObjectKey].list.filter((item) => {
                            if (!foundIds.includes(item.id)) {
                                foundIds.push(item.id);
                                return true;
                            }

                            return false;
                        });

                        list[catObjectKey].count = list[catObjectKey].list.length;
                    } else {
                        items = items.concat(subCategoryList[category].list);
                    }
                } else {
                    items = deck.details.brackets.categories[category].list.map((item) => {
                        return {
                            ...item,
                            ...cards?.[item],
                        };
                    });
                }

                if (!list.hasOwnProperty(catObjectKey)) {
                    list[catObjectKey] = {
                        label: `${label?.toUpperCase()}`,
                        id: catObjectKey,
                        count: items?.length || 0,
                        list: items,
                        color: `#FFFFFF`,
                        subCategories: subCategoryList,
                    };
                }
            });

        Object.keys(list).forEach((key) => {
            if (list?.[key]?.list?.length < 1) {
                delete list?.[key];
            } else {
                Object.keys(list?.[key]?.subCategories).forEach((subKey) => {
                    if (list?.[key]?.subCategories?.[subKey]?.list?.length < 1) {
                        delete list?.[key]?.subCategories?.[subKey];
                    }
                });
            }
        });

        return list;
    };

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    window.addEventListener(
        'resize',
        function (event) {
            // console.log(`sdfksdf`);
            // forceUpdate();
        },
        true
    );

    const temp = getCategories();

    return (
        <BaseDeckPane
            title={'Power Levels'}
            fieldName="name"
            content={<div />}
            showScore={false}
            categories={getCategories()}
        >
            <Flex direction="column">
                <span className="panelTextMedium">
                    <p />
                    Baseline score: {deck?.details?.brackets?.wotcBracket}
                    <ContextualHelp variant="help">
                        <Heading> Bracket Score</Heading>
                        <Content>
                            <Text>
                                This score is based on the criteria laid out by Wizards in their Brackets announcement.
                            </Text>
                        </Content>
                    </ContextualHelp>
                </span>

                <span className="panelTextMedium">
                    Realistic score: {deck?.details?.brackets?.csBracket}
                    <ContextualHelp variant="help">
                        <Heading>Realistic Bracket Score</Heading>
                        <Content>
                            <Text>
                                The baselines that are defined by WotC leave a lot of room for subjective
                                interpretation. We try to better quantify the baseline score based on other deck
                                attributes, such as synergy, manabase, etc.
                            </Text>
                        </Content>
                    </ContextualHelp>
                    <p />
                </span>

                <span className="panelTextSmall">
                    Game changers: {deck.details.brackets.categories.gameChangers.count}
                </span>
                <span className="panelTextSmall">Tutors: {deck.details.brackets.categories.tutors.count}</span>
                <span className="panelTextSmall">
                    Mass land denial/destruction: {deck.details.brackets.categories.massLandDenial.count}
                </span>
                <span className="panelTextSmall">Extra turns: {deck.details.brackets.categories.extraTurns.count}</span>
                {/*<span className="panelTextSmall">Restock effects: {deck.details.brackets.categories.restock.count}</span>*/}
                <span className="panelTextSmall">
                    Two card infinite combos: {deck.details.brackets.categories.twoCardInfiniteCombo.count}
                </span>
                <span className="panelTextSmall">
                    Early game infinites: {deck.details.brackets.categories.earlyGameInfiniteCombos.count}
                </span>
                {deck?.details?.brackets?.wotcBracket === 5 && (
                    <Flex direction={'column'}>
                        <span className="panelTextSmall" style={{ color: '#ff1a1a' }}>
                            cEDH Staples: {deck.details.brackets.categories.cedhStaples.count}
                        </span>
                        <span className="panelTextSmall" style={{ marginLeft: 20, color: '#fbacac' }}>
                            * these cards were found in at least 20% of recent tournament deck entries
                        </span>
                    </Flex>
                )}

                {deck?.details?.brackets?.csBracket < deck?.details?.brackets?.wotcBracket && (
                    <Flex direction="column">
                        <span className="panelText" style={{ fontSize: '15px', fontWeight: 'bolder' }}>
                            <p />
                            Analysis
                        </span>
                        <Flex direction="column" UNSAFE_style={{ fontSize: '.9rem' }}>
                            <Text>
                                Based strictly on the guidelines from WotC, your deck is{' '}
                                <b>bracket {deck?.details?.brackets?.wotcBracket}</b>. However, this deck may struggle
                                to keep up with decks in that bracket as it appears to have the overall optimization
                                typically found in <b>bracket {deck?.details?.brackets?.csBracket}</b> decks.
                                <p />
                                We would suggest either trying to adjust your deck to better conform to bracket{' '}
                                {deck?.details?.brackets?.csBracket} guidelines, or to upgrade it to make it more
                                performant in bracket {deck?.details?.brackets?.wotcBracket}.
                            </Text>
                            <p />
                            <div style={{ marginLeft: '50px' }}>
                                {deck?.details?.brackets?.showSuggestions && (
                                    <Flex direction="column">
                                        Downgrade suggestions:
                                        <ul>
                                            {deck?.details?.brackets?.reasons?.tutors && (
                                                <li>Reduce the number of tutors</li>
                                            )}
                                            {deck?.details?.brackets?.reasons?.massLandDenial && (
                                                <li>Remove any land denial and/or mass land destruction</li>
                                            )}
                                            {deck?.details?.brackets?.reasons?.extraTurns && (
                                                <li>Lower the number of extra turn cards</li>
                                            )}
                                            {deck?.details?.brackets?.reasons?.gameChangers && (
                                                <li>See if there are any game changers that you can remove</li>
                                            )}
                                            {deck?.details?.brackets?.reasons?.twoCardInfiniteCombo && (
                                                <li>Reduce the number of two card combos in the deck</li>
                                            )}
                                        </ul>
                                    </Flex>
                                )}
                            </div>
                            <div style={{ marginLeft: '50px' }}>
                                <Flex direction="column">
                                    Upgrade suggestions:
                                    <ul>
                                        {deck.comboRating < 300 && <li>Increase/solidify your win conditions</li>}
                                        {/*{deck?.details?.brackets?.reasons?.massLandDenial && (*/}
                                        {/*    <li>Remove any land denial and/or mass land destruction</li>*/}
                                        {/*)}*/}
                                        {deck?.details?.manabase?.percentage < 1 && <li>Optimize the manabase</li>}
                                        {deck?.synergyRating < 500 && <li>Increase overall deck synergy</li>}
                                        {deck?.details?.powerLevel?.spikeScorePieces?.interaction?.answers < 300 && (
                                            <li>
                                                Add more ways to interact with your opponents, such as removal,
                                                counterspells, stax, etc
                                            </li>
                                        )}
                                    </ul>
                                </Flex>
                            </div>
                        </Flex>
                    </Flex>
                )}

                {deck?.details?.brackets?.csBracket > deck?.details?.brackets?.wotcBracket && (
                    <Flex direction="column">
                        <span className="panelText" style={{ fontSize: '15px', fontWeight: 'bolder' }}>
                            <p />
                            Analysis
                        </span>
                        <Text>
                            This deck looks like it will perform above the level that is indicated by the bracket
                            guidelines.{' '}
                            <b>
                                <i>Be sure to articulate this in your rule zero conversations.</i>
                            </b>
                        </Text>
                    </Flex>
                )}

                {deck?.details?.brackets?.csBracket === deck?.details?.brackets?.wotcBracket && (
                    <Flex direction="column">
                        <span className="panelText" style={{ fontSize: '15px', fontWeight: 'bolder' }}>
                            <p />
                            Analysis
                        </span>
                        <Text>
                            The system works! This looks to be a solid bracket {deck?.details?.brackets?.csBracket}{' '}
                            deck.
                        </Text>
                    </Flex>
                )}
                <div style={{ height: '50px' }} />
                <Divider width="100%" marginTop="10px" size={'S'} />
            </Flex>
        </BaseDeckPane>
    );
}
