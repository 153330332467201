import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Divider, Text, ActionButton, DialogTrigger, Dialog, Heading, Content } from '@adobe/react-spectrum';
import ShowMenu from '@spectrum-icons/workflow/ShowMenu';
import './HeaderBar.css';
import { setAppRoute } from '../../data/redux/slices/appSlice';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getId, getInstallations } from 'firebase/installations';
import isMobile from 'ismobilejs';
import RealTimeCustomerProfile from '@spectrum-icons/workflow/RealTimeCustomerProfile';
import { useAuth } from 'react-oidc-context';
import { fetchUserDetails } from '../../data/redux/slices/userDetailsSlice';
import { fetchAuthorDetails } from '../../data/redux/slices/authorDetailsSlice';
import { AvatarImage } from '../avatarImage/AvatarImage';

export function HeaderBar(props) {
    const dispatch = useDispatch();
    // const auth = global.__auth; //useAuth();
    const auth = useAuth();

    const mobile = isMobile(window.navigator).phone;
    const title = useSelector((state) => state?.app?.title);
    const route = useSelector((state) => state?.app?.route);
    const profileName = useSelector((state) => state?.profile?.metadata?.profileName);
    const avatarUrl = useSelector((state) => state?.profile?.metadata?.avatarUri);

    let headerCaption = '';
    let headerTitle = route.path === '/profile' ? profileName : title;

    switch (route.path) {
        case '':
        case '/':
            headerCaption = 'Let that sweet salt flow!';
            break;
        case '/archetypes':
            headerCaption = 'All of the different ways to annoy your friends!';
            break;
        case '/card':
            headerCaption = '';
            break;
        case '/commanders':
            headerCaption = 'The saltier, the better!';
            break;
        case '/authors':
            headerCaption = 'Who is the saltiest of them all?';
            break;
        case '/contact':
            headerCaption = 'File any grievances here';
            break;
        case '/faq':
            headerCaption = 'Eh? Why dis? Who dat?';
            break;
        case '/managedecks':
            headerCaption = 'Manage your decks';
            break;
        case '/changelog':
            headerCaption = 'Information about recent changes';
            break;
        default:
            headerCaption = '';
    }

    const handleDiscordBotLink = async () => {
        logEvent(getAnalytics(), 'install_bot_click');
        window.open(
            `https://discord.com/oauth2/authorize?client_id=1130881153998540800&permissions=2147483648&scope=bot`,
            `_blank`
        );
        const installations = getInstallations(global.firebaseApp);
    };

    const handleDiscordLink = async () => {
        logEvent(getAnalytics(), 'discord_click');
        window.open(`https://discord.gg/Yb2CAxcJcf`, `_blank`);
        const installations = getInstallations(global.firebaseApp);
    };

    const handleBlueSkyLink = async () => {
        logEvent(getAnalytics(), 'bluesky_click');
        window.open(`https://bsky.app/profile/commandersalt.bsky.social`, `_blank`);
        const installations = getInstallations(global.firebaseApp);
    };

    const handlePatreonLink = async () => {
        logEvent(getAnalytics(), 'patreon_click');
        window.open(
            `https://patreon.com/user?u=4796225&utm_medium=clipboard_copy&utm_source=copyLink&utm_campaign=creatorshare_creator&utm_content=join_link`,
            `_blank`
        );
        const installations = getInstallations(global.firebaseApp);
    };

    const handleHomeClick = async () => {
        dispatch(setAppRoute({ route: '/' }));
    };

    const handleNewsClick = async () => {
        dispatch(setAppRoute({ route: '/changelog' }));
    };

    const handleHeaderClick = (route) => {
        logEvent(getAnalytics(), `header_open_${route}`);
        dispatch(setAppRoute({ route }));
    };

    const handleMenuClick = (route, closeMethod) => {
        logEvent(getAnalytics(), `header_open_${route}`);
        dispatch(setAppRoute({ route }));
        closeMethod();
    };

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleResizeWindow = () => setScreenWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener('resize', handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, []);

    const handleProfileClick = () => {
        logEvent(getAnalytics(), `header_open_profile`);
        dispatch(fetchUserDetails(profileName));
        dispatch(setAppRoute({ route: `/user/id/${encodeURIComponent(profileName)}` }));
    };

    const headerClassName = screenWidth < 1000 ? 'layered-image-mobile' : 'layered-image';

    return (
        <Flex direction={'row'} justifyContent={'center'}>
            <Flex direction="column" maxWidth={`100vw`} width={2000} alignItems="center">
                <Flex
                    direction="row"
                    width="100%"
                    // margin="5px"
                    justifyContent="space-between"
                    height="54px"
                    UNSAFE_style={{ backgroundColor: '#0e0e0e' }}
                >
                    <Flex direction={'row'}>
                        <div onClick={handleHomeClick} style={{ display: 'block', cursor: 'pointer' }}>
                            <Flex direction="row" UNSAFE_style={{ marginTop: '10px' }}>
                                <img
                                    src="/resources/chef-kiss-header.png"
                                    width="30px"
                                    height="30px"
                                    alt="CommanderSalt.com - Your new source of inspiration for degenerate MtG Commander/EDH decks!"
                                    style={{ paddingLeft: '10px', paddingRight: '10px' }}
                                />
                                <Text UNSAFE_className="HeaderText">COMMANDERSALT.COM</Text>
                            </Flex>
                        </div>
                        {screenWidth > 1000 && (
                            <Flex direction={'row'} marginTop={10} marginStart={50} columnGap={30} alignSelf={'start'}>
                                <Divider orientation={'vertical'} size={'S'} height={30} />
                                <nav
                                    className="navLinksHeader"
                                    onClick={() => {
                                        handleHeaderClick(`/meta`);
                                    }}
                                >
                                    cEDH META
                                </nav>
                                <nav
                                    className="navLinksHeader"
                                    onClick={() => {
                                        handleHeaderClick(`/commanders`);
                                    }}
                                >
                                    COMMANDERS
                                </nav>
                                <nav
                                    className="navLinksHeader"
                                    onClick={() => {
                                        handleHeaderClick(`/authors`);
                                    }}
                                >
                                    AUTHORS
                                </nav>
                                <nav
                                    className="navLinksHeader"
                                    onClick={() => {
                                        handleHeaderClick(`/changelog`);
                                    }}
                                >
                                    CHANGELOG
                                </nav>
                                <nav
                                    className="navLinksHeader"
                                    onClick={() => {
                                        handleHeaderClick(`/faq`);
                                    }}
                                >
                                    FAQ
                                </nav>
                            </Flex>
                        )}
                    </Flex>
                    <span style={{ marginRight: '5px', alignSelf: 'center', paddingRight: 5 }}>
                        <span style={{ marginLeft: '5px', alignSelf: 'right' }}>
                            <DialogTrigger type="popover" mobileType="tray">
                                <ActionButton isQuiet={true}>
                                    <AvatarImage size={35} avatarUrl={avatarUrl} showDefaultIcon={true} />
                                </ActionButton>
                                {(close) => (
                                    <Dialog>
                                        <Heading
                                            UNSAFE_style={{
                                                fontSize: '1rem',
                                                fontWeight: 'bolder',
                                                color: 'red',
                                            }}
                                        >
                                            commandersalt
                                        </Heading>
                                        <Content minHeight="100vh">
                                            <Flex direction="column">
                                                <Divider size={'S'} marginTop={20} />
                                                {!auth?.isAuthenticated && !auth.isLoading && (
                                                    <Flex direction="column">
                                                        <nav
                                                            className="navLinks"
                                                            onClick={() => {
                                                                close();
                                                                void auth.signinRedirect();
                                                            }}
                                                        >
                                                            LOG IN / SIGN UP
                                                        </nav>
                                                    </Flex>
                                                )}
                                                {auth.isAuthenticated && (
                                                    <Flex direction="column">
                                                        <Heading
                                                            UNSAFE_style={{
                                                                fontSize: '.9rem',
                                                                fontWeight: 'bolder',
                                                                color: '#b0eaff',
                                                            }}
                                                        >
                                                            {profileName}
                                                        </Heading>
                                                        <nav
                                                            className="navLinks"
                                                            onClick={() => {
                                                                close();
                                                                handleProfileClick();
                                                            }}
                                                        >
                                                            PROFILE
                                                        </nav>
                                                        <nav
                                                            className="navLinks"
                                                            onClick={() => {
                                                                handleMenuClick(`/linkedaccounts`, close);
                                                            }}
                                                        >
                                                            LINKED ACCOUNTS
                                                        </nav>
                                                        <nav
                                                            className="navLinks"
                                                            onClick={() => {
                                                                handleMenuClick(`/managedecks`, close);
                                                            }}
                                                        >
                                                            MANAGE DECKS
                                                        </nav>
                                                        <nav
                                                            className="navLinks"
                                                            onClick={() => {
                                                                handleMenuClick(`/settings`, close);
                                                            }}
                                                        >
                                                            SETTINGS
                                                        </nav>
                                                        <nav
                                                            className="navLinks"
                                                            onClick={() => {
                                                                close();
                                                                void auth.signoutSilent();
                                                                global.signOutRedirect();
                                                                // void auth.signoutRedirect();
                                                            }}
                                                        >
                                                            LOG OUT
                                                        </nav>
                                                        <Divider marginTop={10} marginBottom={10} size={'S'} />
                                                    </Flex>
                                                )}
                                                {screenWidth < 1000 && (
                                                    <Flex direction="column">
                                                        <nav
                                                            className="navLinks"
                                                            onClick={() => {
                                                                handleMenuClick(`/`, close);
                                                            }}
                                                        >
                                                            LEADERBOARD
                                                        </nav>
                                                        <nav
                                                            className="navLinks"
                                                            onClick={() => {
                                                                handleMenuClick(`/commanders`, close);
                                                            }}
                                                        >
                                                            COMMANDERS
                                                        </nav>
                                                        <nav
                                                            className="navLinks"
                                                            onClick={() => {
                                                                handleMenuClick(`/authors`, close);
                                                            }}
                                                        >
                                                            AUTHORS
                                                        </nav>
                                                        <Divider marginTop={10} marginBottom={10} size={'S'} />

                                                        <nav
                                                            className="navLinks"
                                                            onClick={() => {
                                                                handleMenuClick(`/meta`, close);
                                                            }}
                                                        >
                                                            cEDH META
                                                        </nav>
                                                    </Flex>
                                                )}
                                                <nav
                                                    className="navLinks"
                                                    onClick={() => {
                                                        handleMenuClick(`/powerlevels`, close);
                                                    }}
                                                >
                                                    POWER LEVELS
                                                </nav>
                                                <nav
                                                    className="navLinks"
                                                    onClick={() => {
                                                        handleMenuClick(`/cards`, close);
                                                    }}
                                                >
                                                    CARDS
                                                </nav>
                                                <nav
                                                    className="navLinks"
                                                    onClick={() => {
                                                        handleMenuClick(`/archetypes`, close);
                                                    }}
                                                >
                                                    ARCHETYPES
                                                </nav>
                                                <nav
                                                    className="navLinks"
                                                    onClick={() => {
                                                        handleMenuClick(`/algorithm`, close);
                                                    }}
                                                >
                                                    THE ALGORITHM
                                                </nav>
                                                <Divider marginTop={10} marginBottom={10} size={'S'} />
                                                {screenWidth < 1000 && (
                                                    <Flex direction="column">
                                                        <nav
                                                            className="navLinksSubSection"
                                                            onClick={() => {
                                                                handleMenuClick(`/changelog`, close);
                                                            }}
                                                        >
                                                            - CHANGELOG
                                                        </nav>
                                                        <nav
                                                            className="navLinksSubSection"
                                                            onClick={() => {
                                                                handleMenuClick(`/faq`, close);
                                                            }}
                                                        >
                                                            - FAQ
                                                        </nav>
                                                        <Divider marginTop={10} marginBottom={10} size={'S'} />
                                                    </Flex>
                                                )}
                                                {/*<nav*/}
                                                {/*    className="navLinksSubSection"*/}
                                                {/*    onClick={() => {*/}
                                                {/*        handleMenuClick(`/contact`, close);*/}
                                                {/*    }}*/}
                                                {/*>*/}
                                                {/*    - CONTACT*/}
                                                {/*</nav>*/}
                                                <Flex direction={'row'} marginTop={30} marginBottom={20} columnGap={10}>
                                                    <span style={{ marginRight: '1px', alignSelf: 'right' }}>
                                                        <ActionButton
                                                            onPress={handleDiscordBotLink}
                                                            aria-label="Download the Discord bot!"
                                                            isQuiet="true"
                                                        >
                                                            <img src="/resources/discordBot.png" height="25px" />
                                                        </ActionButton>
                                                    </span>
                                                    <span style={{ marginRight: '1px', alignSelf: 'right' }}>
                                                        <ActionButton
                                                            onPress={handlePatreonLink}
                                                            aria-label="Support us on Patreon!"
                                                            isQuiet="true"
                                                        >
                                                            <img src="/resources/patreon.png" height="20px" />
                                                        </ActionButton>
                                                    </span>
                                                    <span style={{ marginRight: '1px', alignSelf: 'right' }}>
                                                        <ActionButton
                                                            onPress={handleBlueSkyLink}
                                                            aria-label="Follow us on BlueSky!"
                                                            isQuiet="true"
                                                        >
                                                            <img src="/resources/bluesky.png" height="25px" />
                                                        </ActionButton>
                                                    </span>
                                                    <span style={{ marginRight: '1px', alignSelf: 'right' }}>
                                                        <ActionButton
                                                            onPress={handleDiscordLink}
                                                            aria-label="Join our Discord!"
                                                            isQuiet="true"
                                                        >
                                                            <img src="/resources/discord.png" height="25px" />
                                                        </ActionButton>
                                                    </span>
                                                </Flex>
                                            </Flex>
                                        </Content>
                                    </Dialog>
                                )}
                            </DialogTrigger>
                        </span>
                    </span>
                </Flex>
                <Flex direction="column" UNSAFE_className={headerClassName}>
                    <div className="leaderboard-title">{headerTitle}</div>
                    <div className="leaderboard-subtitle">{headerCaption}</div>
                </Flex>
            </Flex>
        </Flex>
    );
}
