import { configureStore } from '@reduxjs/toolkit';
import leaderboardReducer from '../slices/leaderboardSlice';
import importReducer from '../slices/importSlice';
import previewReducer from '../slices/previewSlice';
import deckReducer from '../slices/deckSlice';
import appReducer from '../slices/appSlice';
import commandersReducer from '../slices/commandersSlice';
import commanderDetailsReducer from '../slices/commanderDetailsSlice';
import authorsReducer from '../slices/authorsSlice';
import upgradesReducer from '../slices/upgradesSlice';
import authorDetailsReducer from '../slices/authorDetailsSlice';
import podsReducer from '../slices/podsSlice';
import searchReducer from '../slices/searchSlice';
import metaReducer from '../slices/metaSlice';
import archetypesReducer from '../slices/archetypesSlice';
import cardsReducer from '../slices/cardsSlice';
import profileReducer from '../slices/profileSlice';
import userDetailsSlice from '../slices/userDetailsSlice';

const reducerMap = {
    leaderboard: leaderboardReducer,
    import: importReducer,
    preview: previewReducer,
    deck: deckReducer,
    app: appReducer,
    commanders: commandersReducer,
    commanderDetails: commanderDetailsReducer,
    authorDetails: authorDetailsReducer,
    authors: authorsReducer,
    pods: podsReducer,
    search: searchReducer,
    upgrades: upgradesReducer,
    meta: metaReducer,
    archetypes: archetypesReducer,
    cards: cardsReducer,
    profile: profileReducer,
    userDetails: userDetailsSlice,
};

export default configureStore({ reducer: { ...reducerMap } });
