import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';

export const upgradesSlice = createSlice({
    name: 'upgrades',
    initialState: {
        categories: [],
        list: [],
        isFetching: false,
        loadingState: 'idle',
        nextCursor: null,
        selectedListKey: null,
        selectedListIndex: -1,
        forceSkipAutoLoad: false,
        hasDoneFirstLoad: false,
    },
    reducers: {
        emptyUpgradesList: (state, action) => {
            state.list = action.payload.map((item) => {
                return {
                    ...item,
                    key: item?.id,
                    storeKey: 'upgradesList',
                };
            });
        },
        setUpgradesList: (state, action) => {
            const { Items, LastEvaluatedKey, isReload } = action.payload;

            let i = -1;
            const _list =
                (Items || [])
                    .filter((item) => {
                        return item !== null;
                    })
                    .map((item) => {
                        i += 1;

                        return {
                            ...item,
                            key: item?.id,
                            storeKey: 'upgradesList',
                            indexPrefix: 'upgradesList_',
                            index: i + state?.list?.length || 0,
                        };
                    })
                    ?.sort((a, b) => {
                        return parseFloat(b.score) - parseFloat(a.score);
                    }) || [];

            if (!isReload) {
                state.list = state.list.concat(_list);
            } else {
                state.selectedListKey = null;
                state.selectedListIndex = -1;
                state.list = _list;
            }

            const test = state.list;
            state.nextCursor = LastEvaluatedKey || null;
            state.isFetching = false;
            state.loadingState = 'idle';
            state.hasDoneFirstLoad = true;
        },
        setUpgradesFetchingTrue: (state, action) => {
            state.loadingState = action.payload.loadingState;
            state.isFetching = true;
            state.nextCursor = null;
            // state.forceSkipAutoLoad = true;

            if (action?.payload?.isReload) {
                state.categories = [];
                state.list = [];
                state.nextCursor = null;
            }
        },
        resetUpgrades: (state, action) => {
            state.categories = [];
            state.list = [];
            state.nextCursor = null;
            state.hasDoneFirstLoad = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const { resetUpgrades, setUpgradesList, emptyUpgradesList, setUpgradesFetchingTrue } = upgradesSlice.actions;

export const fetchUpgradesListByCategory =
    (id, category, allowReservedList, colorFilters = null, selectedTypes = [], cursor, isReload = false) =>
    (dispatch) => {
        cursor = cursor !== -1 ? cursor : null;
        dispatch(setUpgradesFetchingTrue({ loadingState: cursor ? `loadingMore` : `loading`, isReload }));

        if (isReload) {
            dispatch(emptyUpgradesList([]));
        }

        DynamoConnector.getDeckUpgrades(
            id,
            category,
            allowReservedList,
            colorFilters,
            selectedTypes,
            cursor,
            (results) => {
                dispatch(setUpgradesList({ ...results, isReload }));
            }
        );
    };

export default upgradesSlice.reducer;
