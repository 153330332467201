import React from 'react';
import { withAuthenticationRequired } from 'react-oidc-context';
import { ManageDecks } from './ManageDecks';

class ManageDecksContainer extends React.Component {
    render() {
        return <ManageDecks />;
    }
}
export default withAuthenticationRequired(ManageDecksContainer);
