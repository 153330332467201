import React from 'react';
import { Flex, Text } from '@adobe/react-spectrum';
import './FAQ.css';
import { Helmet } from 'react-helmet';
import { FaqPane } from './FaqPane';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { FilterPanel } from '../filterpanel/FilterPanel';
import { getDomainPrefix } from '../../data/DynamoConnector';

export function FAQ() {
    return (
        <Flex direction="column">
            <Helmet>
                <title>FAQ</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href="https://www.commandersalt.com/faq" />
                <meta name="description" content="Frequently asked questions" />
                <meta name="twitter:title" content="Commandersalt.com - FAQ" />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content="Commandersalt.com - FAQ" />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content="Frequently asked questions" />
            </Helmet>
            <BreadCrumbs />
            <Flex
                direction="column"
                // alignItems="baseline"
                height="100%"
                UNSAFE_style={{ width: 'calc(100dvw - 20px)' }}
                maxWidth="800px"
                gap="size-100"
                alignSelf={'center'}
            >
                <h1>Hello!</h1>
                <Text>
                    Please understand that this site is a hobby project which is funded out of my own pocket. If you
                    would like to contribute,{' '}
                    <a href={`https://www.patreon.com/user?u=4796225`} target={'_blank'}>
                        please join the Patreon.
                    </a>
                </Text>
                <Text>
                    &nbsp;&nbsp;&nbsp;&nbsp; Is the site 100% accurate and infallible? Emphatic{' '}
                    <b>
                        <i>NO</i>
                    </b>
                    .
                </Text>
                <Text>
                    &nbsp;&nbsp;&nbsp;&nbsp; Am I a world leading expert in the field of cEDH design philosphy? Again,
                    emphatic{' '}
                    <b>
                        <i>NO</i>
                    </b>
                    .
                </Text>
                <Text>
                    I'm just a guy that loves to code and loves the game. It is sincerely funny to me how much hate the
                    site gets in Reddit and Discord - it is one to thing to celebrate salty deck building, but to{' '}
                    <i>literally make people salty just by existing?</i> Amazing! Lol. It's every stax player's dream.
                </Text>
                <Text>
                    At the end of the day, power levels only really work if your playgroup has a set "source of truth"
                    to get the scoring from. There are a few pretty awesome alternatives to my site that you should
                    check out if you dislike what I am putting out:
                    <ul>
                        <li>
                            <a href={'https://edhpowerlevel.com/'} target={'_blank'}>
                                EDHPowerLevel
                            </a>
                        </li>
                        <li>
                            <a href={'https://deckcheck.co/'} target={'_blank'}>
                                DeckCheck.co
                            </a>
                        </li>
                        <li>
                            <a
                                href={'https://mtg.cardsrealm.com/en-us/tools/commander-power-level-calculator'}
                                target={'_blank'}
                            >
                                Cards Realm
                            </a>
                        </li>
                    </ul>
                </Text>
                <p />
                <Text>
                    That being said... thanks for visiting my site :D Hopefully any questions you may have are answered
                    below. If not, please feel free to reach out via the contact form, or join the Discord!
                </Text>

                <h1>General</h1>
                <FaqPane isOpen={true} title="Who built this?">
                    <Text>I've been playing MtG off and on since Revised/3rd Edition came out.</Text>
                    <Text>
                        I played a lot of Legacy and Modern competitively, but between work and being a dad, my
                        available time to keep up on meta shifts and the like has gone down considerably. I play
                        commander pretty exclusively nowadays for the social interaction.
                    </Text>
                    <Text>
                        My decklists are <a href="https://www.moxfield.com/users/grumbledore">available on Moxfield</a>,
                        and
                        <a href="https://commandersalt.com/#/authors/id/grumbledore">here</a>.
                    </Text>
                    <Text>
                        My play style is primarily stax and control, which honestly probably leads to some sampling bias
                        in the calculations, but I do strive to be objective 😂.
                    </Text>
                    <Text>As for my day job, I am a senior software engineer at a large tech firm.</Text>
                </FaqPane>
                <FaqPane isOpen={true} title="How did this start?">
                    <Text>
                        The idea for this site started because my core playgroup enjoyed making our decks as salty as we
                        could. Naturally, this turned in to a competition.
                    </Text>
                    <Text>
                        As the competition evolved, the question of how to fully quantify a deck's salt level became
                        more and more of a question. We were, of course, intimately familiar with edhrec.com's famous
                        salt list. In fact, a couple of us used it as a deck building tool. Given that - the solution
                        was fairly obvious. I made a node script that aggregated a deck's overall salt score based on
                        the scores of it's constituent cards.
                    </Text>
                    <Text>
                        We showed this to a few other people that we played with in our lgs, and quickly realized that
                        the idea was amusing enough that other people wanted to participate.
                    </Text>
                    <Text>
                        Thus... <i>this</i>.
                    </Text>
                    <Text>
                        Once this was publically available, I noticed that people were thinking that the site was
                        indicating that saltier decks are inherently better than others. This is largely my fault, as
                        the site more or less celebrates degeneracy.
                    </Text>
                    <Text>
                        However, this did lead me to start looking at how to objectively quantify power levels. I had
                        seen them discussed a lot, and to be frank, I always kind of thought it was conceptually dumb.
                        It is far too subjective of a scale to be used outside of a core playgroup. Still though, it was
                        an interesting problem from a data point of view, so I started building out a system to try and
                        calculate it.
                    </Text>
                </FaqPane>
                <h1>Common questions/misconceptions</h1>
                <FaqPane isOpen={true} title="Card scores are determined in a vacuum">
                    <Text>
                        Each card is parsed and assigned a score based on mechanical quality of functionality, as well
                        as casting cost.
                        <p />
                        However, when determining power levels, the <b>score value of each card</b> is weighted relative
                        to its synergy with the rest of the deck.
                    </Text>
                </FaqPane>
                <FaqPane isOpen={true} title="The site prefers infinite combos and cEDH staples">
                    <Text>
                        This one is kind of funny to me. In order to have any concept of an actual power level scale,
                        you have to have a ceiling.
                        <p />
                        There is no better representation of maximum efficiency and speed than cEDH meta. So yes, that
                        is the standard each deck is measured against.
                        <p />
                        Let's be real here - nobody wants to be told their deck is a 4... Especially when it's actually
                        higher and the site undervalued it. However, I will{' '}
                        <b>always prefer to undervalue rather than overvalue</b>, simply because I do not want to be the
                        reason somebody adds a Mana Crypt to a precon and thinks it will perform well in a cEDH
                        environment/tournament.
                        <p />
                        Is it objectively perfect and accurate? Absolutely not. I don't even know if its possible, to be
                        completely honest. That is, however, the fun part of this.
                    </Text>
                </FaqPane>
                <FaqPane isOpen={true} title="The site prioritizes expensive cards">
                    <Text>
                        No pricing information is taken into account in any way. In fact, I strip that off of the data
                        from Scryfall before saving it in my own database.
                        <p />
                        Why? Because it leads to confirmation bias.
                        <p />
                        All of that being said, higher prices generally indicate better cards (the caveat here are
                        reserved list cards... There are a lot that are complete dumpster fires but super expensive). If
                        the algorithm is working correctly, it should appear to be prioritizing expensive cards based
                        solely on the fact that they are objectively better than others.
                    </Text>
                </FaqPane>
                <FaqPane
                    isOpen={true}
                    title="The site looks at cEDH meta lists to determine card scoring and priorities"
                >
                    <Text>
                        I index cEDH meta so that I get a list of staples and also to determine what tiers commanders
                        fall into.
                    </Text>
                    <Text>
                        There is <b>no logic anywhere in the app that increases deck score based on cEDH staples</b>.
                        The main reason I get a list of staples is so that I can count them as a category for salt.
                    </Text>
                    <Text>
                        There is <b>no logic that compares deck lists against known cEDH lists</b>, either from sites
                        like the cEDH database, or internally to decks that have already been imported to Commandersalt.
                        That would create a lot of confirmation bias and lower objectivity.
                    </Text>
                </FaqPane>
                <h1>Technical</h1>
                <FaqPane isOpen={true} title="What is the tech stack?">
                    <Text>The front end is built in React.</Text>
                    <Text>The backend is built in NodeJS on AWS Lambda services.</Text>
                </FaqPane>
                <FaqPane isOpen={true} title="How are cards processed?">
                    <Text>
                        This is probably one of the most common questions I get about all of this, especially when I say
                        there's no AI.
                    </Text>
                    <Text>The TLDR; is regex. Lots and lots of regex lol.</Text>
                    <Text>
                        The more involved answer is that I have tried to build out a system which effectively "reads" a
                        card in order to determine what it is attempting to do. I took this path because there are a few
                        main tenants that I am trying to adhere to:
                        <p />
                        <ul>
                            <li>No crowd sourced data, such as the Scryfall tagger</li>
                            <li>No card specific logic: there isn't code that says "Assassin's Trophy is removal"</li>
                            <li>
                                Automatic card lookups and parsing. This allows the site to utilize cards as soon as
                                they come out without me having to manually add them to the system
                                <ul>
                                    <li>
                                        There is a caveat here, in that <b>new mechanics</b> occasionally require
                                        updates to be handled. However, a lot of them boil down to using the same set of
                                        core mechanics that the site already knows how to handle.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Text>
                    <Text>
                        Each card has a "contextual map" that is procedurally built for it. This contains inferences
                        about what players each line of text affects, what types of cards it cares about, what zones the
                        card is coming from and going to, etc. This is obviously pretty tricky, and therefore error
                        prone.
                    </Text>
                    <Text>
                        Card categories are then assigned based on the contextual data generated in the previous step.
                    </Text>
                    <Text>
                        Example JSON blobs for cards: <br />
                        <a
                            href={`${getDomainPrefix()}/details?id=kenrith_the_returned_king&isCard=true`}
                            target={'_blank'}
                        >
                            Kenrith, the Returned King
                        </a>
                        <br />
                        <a
                            href={`${getDomainPrefix()}/details?id=sheoldred_the_apocalypse&isCard=true`}
                            target={'_blank'}
                        >
                            Sheoldred, the Apocalypse
                        </a>
                        <br />
                    </Text>
                </FaqPane>
                <FaqPane isOpen={true} title="How is deck synergy derived?">
                    <Text>Synergy is hands down the most complicated and error prone part of the site. </Text>{' '}
                    <Text>
                        In a nut shell, it procedurally analyzes each line of text on each card against each line of
                        text on each other card in the deck. It then builds a map of causal relationships between these
                        effects.
                    </Text>
                </FaqPane>
                <h1>I have complaints!</h1>
                <Text>
                    You have the luxury of embarking upon one of several paths at this point:
                    <ul>
                        <li>
                            <b>
                                <i>Contribute —</i>
                            </b>{' '}
                            Let me know! Either by emailing me via the contact form, or by joining the Discord.
                        </li>
                        <li>
                            <b>
                                <i>Disparage —</i>
                            </b>{' '}
                            Grab your pitchforks and angrily head on over to your favorite subreddit or Discord of
                            choice. This is, of course, the most emotionally satisfying option.
                        </li>
                        <li>
                            <b>
                                <i>Move On —</i>
                            </b>{' '}
                            Nobody is actually forcing you to use this... are they? (If they are, I am genuinely curious
                            lol)
                        </li>
                    </ul>
                </Text>
            </Flex>
        </Flex>
    );
}
