import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';
import { addNewItems, fetchAll, setLeaderboardFilters, SORT_MAP } from './leaderboardSlice';

export const commandersSlice = createSlice({
    name: 'commanders',
    initialState: {
        listItems: [],
        nextCursor: null,
        isFetching: false,
        loadingState: `idle`,
        filters: {
            query: '',
            queryType: '',
            range: {
                high: 10,
                low: 1,
            },
            colorFilters: '',
        },
        sortBy: SORT_MAP.POWERLEVEL,
        sortDirection: SORT_MAP.DIRECTION.DESC,
        selectedListKey: null,
        selectedListIndex: -1,
    },
    reducers: {
        setCommandersItems: (state, action) => {
            state.listItems = action.payload.map((item) => {
                return {
                    ...item,
                    key: item?.id,
                    storeKey: 'commanders',
                };
            });
        },
        setCommandersNextCursor: (state, action) => {
            state.nextCursor = action.payload;
        },
        addNewCommandersItems: (state, action) => {
            const { cursor, isReload } = action.payload;

            state.nextCursor = cursor || null;
            let i = -1;
            const newItems = action.payload.items
                .filter((item) => {
                    return item !== null;
                })
                .filter((item) => {
                    return item.commanders.length < 3; // && item.commanderIds.length > 0;
                })
                .map((item) => {
                    i += 1;

                    return {
                        ...item,
                        key: item?.id,
                        storeKey: 'commanders',
                        indexPrefix: 'commanders_',
                        index: i + state?.listItems?.length || 0,
                    };
                });

            if (!isReload) {
                state.listItems = state.listItems.concat(newItems);
            } else {
                state.listItems = newItems;
            }

            state.isFetching = false;
            state.loadingState = `idle`;
        },
        setCommandersIsFetching: (state, action) => {
            state.isFetching = action.payload.isFetching;
            state.loadingState = action.payload.loadingState;
        },
        setCommandersFilters: (state, action) => {
            state.filters = action.payload;
        },
        setCommandersSortDirection: (state, action) => {
            state.sortDirection = action?.payload || SORT_MAP.DIRECTION.DESC;
        },
        setCommandersSortBy: (state, action) => {
            state.sortBy = action?.payload || SORT_MAP.POWERLEVEL;
        },
        setSelectedListKey: (state, action) => {
            state.selectedListKey = action?.payload;
        },
        setSelectedListIndex: (state, action) => {
            state.selectedListIndex = action?.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setCommandersItems,
    setCommanderDetails,
    addNewCommandersItems,
    setCommandersIsFetching,
    setCommandersFilters,
    setCommandersSortBy,
    setCommandersSortDirection,
    setSelectedListKey,
    setSelectedListIndex,
} = commandersSlice.actions;

export const commandersFetchFiltered = (filters, sortBy, sortDirection) => (dispatch) => {
    dispatch(setCommandersFilters(filters));
    dispatch(fetchCommanderLeaderboardItems(null, true, sortBy, sortDirection, filters));
};

export const fetchCommanderLeaderboardItems =
    (cursor, isReload = false, sortBy, sortDirection, filters = { queryType: 'commander' }) =>
    (dispatch) => {
        cursor = cursor !== -1 ? cursor : null;

        if (isReload) {
            dispatch(setCommandersItems([]));
        }

        dispatch(
            setCommandersIsFetching({
                isFetching: true,
                loadingState: cursor ? `loadingMore` : `loading`,
            })
        );

        DynamoConnector.getList(cursor, 'commanders', sortBy?.index || null, filters, sortDirection, (results) => {
            dispatch(
                addNewCommandersItems({
                    items: results?.Items || [],
                    cursor: results?.LastEvaluatedKey ? results.LastEvaluatedKey : null,
                    type: null,
                    isReload,
                })
            );
        });
    };

export default commandersSlice.reducer;
