import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    ActionButton,
    AlertDialog,
    Cell,
    Checkbox,
    Column,
    Content,
    Dialog,
    DialogContainer,
    DialogTrigger,
    Divider,
    Flex,
    Form,
    Heading,
    Item,
    Menu,
    MenuTrigger,
    ProgressCircle,
    Row,
    TableBody,
    TableHeader,
    TableView,
    Text,
    TextField,
    View,
} from '@adobe/react-spectrum';
import './LinkedAccounts.css';
import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import WorkflowAdd from '@spectrum-icons/workflow/WorkflowAdd';
import Checkmark from '@spectrum-icons/workflow/Checkmark';
import Close from '@spectrum-icons/workflow/Close';
import RealTimeCustomerProfile from '@spectrum-icons/workflow/RealTimeCustomerProfile';
import {
    deleteAccountLink,
    getAccountLinkCode,
    postValidateAccountLink,
    resetAddProfileDialog,
} from '../../data/redux/slices/profileSlice';
import { GenericLoadingDialog } from '../genericLoadingDialog/GenericLoadingDialog';

export function LinkedAccounts() {
    const dispatch = useDispatch();

    const isFetching = useSelector((state) => state?.profile?.isFetching);
    const isFetchingIdentifierString = useSelector((state) => state?.profile?.accountLink?.isFetchingIdentifierString);
    const identifierString = useSelector((state) => state?.profile?.accountLink?.identifierString);
    const validationSuccessful = useSelector((state) => state?.profile?.accountLink?.validationSuccessful);
    const profiles = useSelector((state) => state?.profile?.profiles);
    const isValidating = useSelector((state) => state?.profile?.accountLink?.isValidating);

    const helmetName = `Linked Accounts`;
    const helmetCanonical = `https://www.commandersalt.com/linkedaccounts`;
    const helmetDescription = `The saltiest MtG deck builders on the planet!`;
    const helmetTitle = `Commandersalt.com - The saltiest MtG deck builders on the planet!`;

    const [showLinkDialog, setShowLinkDialog] = React.useState(false);
    const [linkSource, setLinkSource] = React.useState('Select');
    const [dialogFormValueValid, setDialogFormValueValid] = React.useState('invalid');
    const [username, setUsername] = useState(0);
    const [hasUpdatedProfile, setHasUpdatedProfile] = useState(false);
    const [hasPressedValidate, setHasPressedValidate] = useState(false);

    const [selectedKeys, setSelectedKeys] = useState(new Set());

    const sourceList = [
        { id: 'Moxfield', name: 'Moxfield' },
        { id: 'Archidekt', name: 'Archidekt' },
        { id: 'TappedOut', name: 'TappedOut' },
    ];

    const max = window.innerWidth - 180;

    let columns = [
        // { name: '', uid: 'check', maxWidth: 25 },
        { name: 'Profile Name', uid: 'username', maxWwidth: max },
        { name: 'Source', uid: 'provider', maxWidth: 125 },
    ];

    let rows = [];
    let iterator = 0;
    Object.keys(profiles || {}).map((profileProvider) => {
        Object.keys(profiles[profileProvider] || {}).map((profileName) => {
            rows.push({ id: iterator, username: profileName, provider: profileProvider, key: iterator });
            iterator += 1;
        });
    });

    const validateLinkCode = async () => {
        dispatch(postValidateAccountLink(linkSource.toLowerCase(), username));
        setHasPressedValidate(true);
    };

    const deleteSelectedProfiles = async () => {
        const selectedProfileIds = Array.from(selectedKeys).map((item) => {
            const iterator = parseInt(item);
            let tmp = { ...rows[iterator] };
            delete tmp.id;
            delete tmp.key;
            return tmp;
        });

        dispatch(deleteAccountLink(selectedProfileIds));
    };

    const dialogTextColorStyle = validationSuccessful ? '#010101' : '#FFFFFF';
    const finalDialogTextColorStyle = validationSuccessful ? '#FFFFFF' : '#010101';

    const handleFormChange = (text) => {
        let value = 'invalid';

        if (text && text !== '' && !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(text)) {
            value = 'valid';

            rows.map((row) => {
                if (row.username === text) {
                    value = 'invalid';
                }
            });
        }

        setDialogFormValueValid(value);
    };

    const deleteWarningText = `Are you sure you wish to remove ${
        Array.from(selectedKeys).length > 1 ? 'these connections' : 'this connection'
    } from Commandersalt?`;

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs
                content={
                    <Flex direction="row" gap="size-100" justifyContent="right">
                        <View
                            borderWidth="thin"
                            borderColor="light"
                            borderRadius="medium"
                            padding="size-50"
                            backgroundColor="gray-75"
                            minHeight="0px"
                        >
                            <Flex direction="row" gap="size-100">
                                <ActionButton
                                    aria-label="Add Account"
                                    // isDisabled={authorUrlButtonDisabled || !deck}
                                    onPress={() => {
                                        dispatch(resetAddProfileDialog());
                                        setLinkSource('Select');
                                        setShowLinkDialog(true);
                                    }}
                                    isQuiet="true"
                                    UNSAFE_style={{ cursor: 'pointer' }}
                                >
                                    <Text UNSAFE_className="reportCardSectionTitles" marginStart={10} top={7}>
                                        ADD ACCOUNT
                                    </Text>
                                    <WorkflowAdd />
                                </ActionButton>
                            </Flex>
                        </View>
                    </Flex>
                }
            />
            {isFetching && <GenericLoadingDialog />}
            {showLinkDialog && (
                <DialogContainer type={'modal'} isDismissable onDismiss={() => setShowLinkDialog(false)}>
                    <Dialog maxWidth={`calc(100vw - 20px)`} width={600}>
                        <Heading>ADD ACCOUNT CONNECTION</Heading>
                        <Divider size={'S'} />
                        <Content alignSelf={'center'} justifySelf={'center'} width={'100%'}>
                            <View overflow={'auto'}>
                                <Flex direction={'column'} rowGap={20}>
                                    <View>
                                        <span className={'header'} style={{ color: dialogTextColorStyle }}>
                                            Step 1
                                        </span>
                                        <Flex direction={'column'} rowGap={10}>
                                            <span style={{ color: dialogTextColorStyle }}>
                                                Select a deck builder site
                                            </span>
                                            {/*<Flex direction={'row'} justifyContent={'space-around'}>*/}
                                            <MenuTrigger>
                                                <ActionButton
                                                    isDisabled={validationSuccessful}
                                                    maxWidth={300}
                                                    minWidth={200}
                                                    UNSAFE_className={'synergyMenuBoxActionLabel'}
                                                >
                                                    {linkSource}
                                                </ActionButton>
                                                <Menu
                                                    UNSAFE_className={'synergyMenuBox'}
                                                    items={sourceList}
                                                    selectedKeys={linkSource}
                                                    onSelectionChange={(evn) => {
                                                        setLinkSource(evn?.currentKey);
                                                        dispatch(getAccountLinkCode(evn?.currentKey.toLowerCase()));
                                                    }}
                                                    selectionMode="single"
                                                >
                                                    {(item) => <Item>{item.name}</Item>}
                                                </Menu>
                                            </MenuTrigger>
                                            {/*</Flex>*/}
                                        </Flex>
                                    </View>
                                    {isFetchingIdentifierString && !identifierString && (
                                        <Flex direction={'row'} justifyContent={'space-around'} margin={10}>
                                            <ProgressCircle isIndeterminate="true" size="S" />
                                        </Flex>
                                    )}
                                    {identifierString && identifierString !== '' && (
                                        <View>
                                            <span className={'header'} style={{ color: dialogTextColorStyle }}>
                                                Step 2
                                            </span>

                                            <Flex
                                                direction="column"
                                                width="100%"
                                                justifyContent="space-between"
                                                rowGap={10}
                                            >
                                                <span style={{ color: dialogTextColorStyle }}>
                                                    Login to your account on {linkSource}, and edit your profile. Copy
                                                    and paste this text to your public bio:
                                                </span>
                                                <span />
                                                <span className={'identifierStringPanel'}>
                                                    <Flex direction={'row'} justifyContent={'space-around'} margin={10}>
                                                        <span style={{ color: dialogTextColorStyle }}>
                                                            {identifierString}
                                                        </span>
                                                    </Flex>
                                                </span>
                                                <span />
                                                <span style={{ color: dialogTextColorStyle }}>
                                                    Don't worry! This is temporary.
                                                </span>
                                                <span />
                                                <ActionButton
                                                    maxWidth={300}
                                                    minWidth={200}
                                                    aria-label="Validate"
                                                    isDisabled={validationSuccessful}
                                                    // isDisabled={authorUrlButtonDisabled || !deck}
                                                    onPress={() => setHasUpdatedProfile(true)}
                                                    UNSAFE_style={{ cursor: 'pointer' }}
                                                >
                                                    Done updating
                                                </ActionButton>
                                            </Flex>
                                        </View>
                                    )}
                                    {hasUpdatedProfile && (
                                        <View>
                                            <span className={'header'} style={{ color: dialogTextColorStyle }}>
                                                Step 3
                                            </span>
                                            <Flex direction="column" width="100%" rowGap={10}>
                                                <span style={{ color: dialogTextColorStyle }}>
                                                    Enter your username on {linkSource} and then press "Validate" to
                                                    check the code and link your account!
                                                </span>
                                                <Form validationBehavior="native">
                                                    <TextField
                                                        icon={<RealTimeCustomerProfile />}
                                                        isDisabled={validationSuccessful}
                                                        onChange={(val) => {
                                                            setUsername(val);
                                                            handleFormChange(val);
                                                        }}
                                                        maxWidth={300}
                                                        minWidth={300}
                                                        isRequired={true}
                                                        onSubmit={validateLinkCode}
                                                        autoFocus={false}
                                                        validationState={dialogFormValueValid}
                                                        UNSAFE_style={{ color: dialogTextColorStyle }}
                                                    />
                                                    {!isValidating && hasPressedValidate && (
                                                        <>
                                                            {validationSuccessful ? (
                                                                <Flex direction={'row'} margin={20}>
                                                                    <Checkmark size={'L'} />
                                                                    <span
                                                                        style={{
                                                                            marginLeft: 20,
                                                                            marginTop: 5,
                                                                            color: '#53FF53',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        Success!
                                                                    </span>
                                                                </Flex>
                                                            ) : (
                                                                <Flex direction={'row'} margin={20}>
                                                                    <Close size={'L'} />
                                                                    <span
                                                                        style={{
                                                                            marginLeft: 20,
                                                                            marginTop: 5,
                                                                            color: '#ff5353',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        Validation failed. Did you use the correct
                                                                        username?
                                                                    </span>
                                                                </Flex>
                                                            )}
                                                        </>
                                                    )}
                                                    {isValidating ? (
                                                        <Flex
                                                            direction={'row'}
                                                            justifyContent={'space-around'}
                                                            margin={10}
                                                            marginTop={15}
                                                        >
                                                            <ProgressCircle isIndeterminate="true" size="S" />
                                                        </Flex>
                                                    ) : (
                                                        <>
                                                            {!validationSuccessful && (
                                                                <ActionButton
                                                                    maxWidth={300}
                                                                    minWidth={200}
                                                                    aria-label="Validate"
                                                                    isDisabled={validationSuccessful}
                                                                    // isDisabled={authorUrlButtonDisabled || !deck}
                                                                    onPress={validateLinkCode}
                                                                    UNSAFE_style={{ cursor: 'pointer' }}
                                                                >
                                                                    Validate
                                                                </ActionButton>
                                                            )}
                                                        </>
                                                    )}
                                                </Form>
                                            </Flex>
                                        </View>
                                    )}
                                    {validationSuccessful && setHasUpdatedProfile && (
                                        <View>
                                            <span className={'header'} style={{ color: finalDialogTextColorStyle }}>
                                                Step 4
                                            </span>
                                            <Flex direction="column" width="100%" justifyContent="space-between">
                                                <span style={{ color: finalDialogTextColorStyle }}>
                                                    Account added!
                                                    <p />
                                                    You can now remove the link code from your profile on {linkSource} -
                                                    it is no longer needed.
                                                </span>
                                            </Flex>
                                        </View>
                                    )}

                                    <Divider size={'S'} />
                                    <Flex direction={'row-reverse'} columnGap={10} marginTop={30}>
                                        <ActionButton
                                            // onPress={handleDownloadPress}
                                            aria-label="Close"
                                            onPress={() => setShowLinkDialog(false)}
                                            UNSAFE_style={{
                                                cursor: 'pointer',
                                                float: 'right',
                                                paddingRight: 10,
                                            }}
                                        >
                                            <Flex direction={'row-reverse'}>
                                                <Text UNSAFE_className="printDialogButton" marginStart={10}>
                                                    CLOSE
                                                </Text>
                                            </Flex>
                                        </ActionButton>
                                    </Flex>
                                </Flex>
                            </View>
                        </Content>
                    </Dialog>
                </DialogContainer>
            )}
            <Flex direction={'column'} alignItems={'center'}>
                <Flex direction={'column'} rowGap={30} maxWidth={1000} margin={10}>
                    <Flex direction={'row'} justifyContent={'space-around'} width={'100%'}>
                        <Flex
                            direction={'row'}
                            justifyContent={'start'}
                            maxWidth={`calc(100vw - 20px)`}
                            width={'100vw'}
                            columnGap={20}
                        >
                            <Flex
                                direction={'row'}
                                justifyContent={'start'}
                                maxWidth={`calc(100vw - 20px)`}
                                width={'100vw'}
                                columnGap={20}
                                wrap
                            >
                                <span style={{ marginTop: 5 }}>With selected:</span>
                                <Flex direction={'row'} columnGap={10}>
                                    <DialogTrigger>
                                        <ActionButton
                                            aria-label="Delete"
                                            type="reset"
                                            isDisabled={Array.from(selectedKeys).length < 1}
                                            UNSAFE_style={{
                                                cursor: 'pointer',
                                                backgroundColor: '#cb3729',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            Delete
                                        </ActionButton>
                                        <AlertDialog
                                            variant={'destructive'}
                                            title="Confirm Delete"
                                            primaryActionLabel="Continue"
                                            cancelLabel="Cancel"
                                            onPrimaryAction={() => deleteSelectedProfiles()}
                                        >
                                            {deleteWarningText}
                                        </AlertDialog>
                                    </DialogTrigger>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex direction={'row'} justifyContent={'space-around'} width={'100%'}>
                        <TableView
                            aria-label="Example table with dynamic content"
                            maxWidth={`calc(100vw - 20px)`}
                            width={'100vw'}
                            selectionMode="multiple"
                            selectedKeys={selectedKeys}
                            onSelectionChange={setSelectedKeys}
                            minHeight={400}
                        >
                            <TableHeader columns={columns}>
                                {(column) => (
                                    <Column
                                        key={column.uid}
                                        align={column.uid === 'date' ? 'end' : 'start'}
                                        maxWidth={column.maxWidth}
                                    >
                                        {column.name}
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody items={rows}>
                                {(item) => <Row key={item.key}>{(columnKey) => <Cell>{item[columnKey]}</Cell>}</Row>}
                            </TableBody>
                        </TableView>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
