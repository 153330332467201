import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector, getClientDomainPrefix, getDomainPrefix } from '../../DynamoConnector';
import { SORT_MAP } from './leaderboardSlice';
import {
    addNewAuthorDetailsItems,
    fetchAuthorDetailsItems,
    setAuthorDetailsFilters,
    setAuthorDetailsIsFetching,
    setAuthorDetailsItems,
} from './authorDetailsSlice';
import { setAppRoute } from './appSlice';

export const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState: {
        profile: {},
        listItems: [],
        nextCursor: null,
        isFetching: false,
        filters: {
            query: '',
            queryType: '',
            range: {
                high: 10,
                low: 1,
            },
            colorFilters: '',
        },
        sortBy: SORT_MAP.POWERLEVEL,
        sortDirection: SORT_MAP.DIRECTION.DESC,
        isRefreshing: false,
    },
    reducers: {
        setIsRefreshing: (state, action) => {
            state.isRefreshing = action.payload;
        },
        setUserDetailsItems: (state, action) => {
            state.listItems = action.payload.map((item) => {
                return {
                    ...item,
                    key: item?.id,
                    storeKey: 'userDetails',
                };
            });
        },
        setUserDetailsNextCursor: (state, action) => {
            state.nextCursor = action.payload;
        },
        addNewUserDetailsItems: (state, action) => {
            const { cursor, isReload } = action.payload;

            state.nextCursor = cursor || null;
            let i = -1;
            const newItems = action.payload.items
                .map((item) => {
                    return {
                        ...item,
                        key: item?.id,
                        storeKey: 'userDetails',
                    };
                })
                .map((item) => {
                    i += 1;

                    return {
                        ...item,
                        key: item?.id,
                        storeKey: 'userDetails',
                        indexPrefix: 'userDetails_',
                        index: i + state?.listItems?.length || 0,
                    };
                });

            if (!isReload) {
                state.listItems = state.listItems.concat(newItems);
            } else {
                state.listItems = newItems;
            }
        },
        setUserDetailsFetching: (state, action) => {
            state.isFetching = action.payload.isFetching;
        },
        setUserDetailsFilters: (state, action) => {
            state.filters = action.payload;
        },
        setUserDetailsSortBy: (state, action) => {
            state.sortBy = action?.payload || SORT_MAP.POWERLEVEL;
        },
        setUserDetailsSortDirection: (state, action) => {
            state.sortDirection = action?.payload || SORT_MAP.DIRECTION.DESC;
        },
        setUserDetails: (state, action) => {
            state.profile = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setIsRefreshing,
    setUserDetailsItems,
    setUserDetails,
    addNewUserDetailsItems,
    setUserDetailsFetching,
    setUserDetailsFilters,
    setUserDetailsSortBy,
    setUserDetailsSortDirection,
    setSelectedListKey,
    setSelectedListIndex,
} = userDetailsSlice.actions;

// Action creators are generated for each case reducer function
export const {
    setProfileData,
    setIsFetchingIdentifierString,
    setIdentifierString,
    setIsValidating,
    setValidationSuccessful,
    setIsDeleting,
    resetAddProfileDialog,
    setManageDecksIsFetching,
    setManageDecksList,
} = userDetailsSlice.actions;

export const fetchUserDetails = (id) => (dispatch) => {
    dispatch(setUserDetailsFetching(true));
    dispatch(setUserDetails(null));

    DynamoConnector.getUserDetails(id, (results) => {
        if (results) {
            dispatch(setUserDetails(results || null));
            dispatch(fetchUserDetailsItems(null, null, true, SORT_MAP.POWERLEVEL, SORT_MAP.DIRECTION.DESC, results.id));
        } else {
            dispatch(setUserDetailsFetching(false));
            dispatch(setAppRoute({ route: '/' }));
        }
    });
};

export const userDetailsFetchFiltered = (filters, sortBy, sortDirection, userId) => (dispatch) => {
    dispatch(setUserDetailsFilters(filters));
    dispatch(fetchUserDetailsItems(null, filters, true, sortBy, sortDirection, userId));
};

export const fetchUserDetailsItems =
    (cursor, filters, isReload = false, sortBy, sortDirection, _id) =>
    (dispatch) => {
        let id = !_id ? null : _id;

        if (!id) {
            if (window.location.pathname.includes(`/id/`)) {
                id = window.location.pathname.substring(window.location.pathname.indexOf(`/id/`) + 4);
            }
        }

        if (id) {
            filters = {
                ...filters,
                id,
            };
        }

        cursor = cursor !== -1 ? cursor : null;

        if (isReload) {
            dispatch(setUserDetailsItems([]));
        }

        dispatch(
            setUserDetailsFetching({
                isFetching: true,
            })
        );

        DynamoConnector.getList(cursor, 'users', sortBy?.index || null, filters, sortDirection, (results) => {
            dispatch(
                addNewUserDetailsItems({
                    items: results?.Items || [],
                    cursor: results?.LastEvaluatedKey ? results.LastEvaluatedKey : null,
                    type: null,
                    isReload,
                })
            );
            dispatch(setUserDetailsFetching(false));
        });
    };

export default userDetailsSlice.reducer;
