import React, { useState } from 'react';
import {
    Text,
    Flex,
    ActionButton,
    SearchField,
    Radio,
    RadioGroup,
    RangeSlider,
    Dialog,
    Content,
    DialogTrigger,
    Button,
    ButtonGroup,
    Divider,
    Heading,
    View,
} from '@adobe/react-spectrum';
import './FilterPanel.css';
import { ManaSelector } from './ManaSelector';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Filter from '@spectrum-icons/workflow/Filter';

export function FilterPanel(props) {
    // hooks
    const [query, setQuery] = useState(props.filters?.query || '');
    const [queryType, setQueryType] = useState(props.filters?.queryType || 'commander');
    const [high, setHigh] = useState(props.filters?.range?.high || 10);
    const [low, setLow] = useState(props.filters?.range?.low || 0);
    const [colorFilters, setColorFilters] = useState(props?.filters?.colorFilters || null);

    const pushResetFilters = () => {
        setColorFilters(null);
        setLow(0);
        setHigh(10);

        props?.onFiltersChange &&
            props.onFiltersChange({
                query: '',
                queryType: 'commander',
                colorFilters: '',
                range: {
                    high: 10,
                    low: 0,
                },
            });
    };

    const pushFiltersChangeUpstream = () => {
        let type = queryType;

        if (props?.restrictToCommander) {
            type = 'commander';
        }

        if (props?.restrictToAuthor) {
            type = 'author';
        }

        props?.onFiltersChange &&
            props.onFiltersChange({
                query,
                queryType: type,
                colorFilters,
                range: {
                    high,
                    low,
                },
            });
    };

    const handleRangeSliderChange = (evn) => {
        setHigh(evn.end);
        setLow(evn.start);
    };

    const handleColorFiltersChange = (evn) => {
        setColorFilters(evn.colors);
    };

    return (
        <DialogTrigger type="popover" mobileType="tray">
            <ActionButton UNSAFE_style={{ height: 25 }} width={100} marginStart={20}>
                <Flex direction={'row'} justifyContent={'start'} marginEnd={20}>
                    <Filter />
                    Filters
                </Flex>
            </ActionButton>
            {(close) => (
                <Dialog>
                    <Heading>Filter results</Heading>
                    <Divider />
                    <Content>
                        <Flex direction="column" rowGap={20}>
                            <View isHidden={props?.hideQueryInput}>
                                <Text UNSAFE_className="filtersSectionLabel">SEARCH</Text>
                                <Flex direction="row" columnGap="10px" marginBottom={10}>
                                    <SearchField
                                        flexGrow={3}
                                        flexBasis={500}
                                        onChange={setQuery}
                                        isDisabled={props?.hideQueryInput}
                                        onClear={() => {
                                            setQueryType('');
                                        }}
                                        value={query}
                                    />
                                </Flex>
                                <RadioGroup
                                    orientation="horizontal"
                                    isDisabled={props?.hideQueryInput}
                                    onChange={(val) => {
                                        setQueryType(val);
                                    }}
                                    value={queryType}
                                >
                                    <Radio value="commander">Commander</Radio>
                                    <Radio
                                        isDisabled={
                                            props?.restrictToCommander ||
                                            props?.hideAuthorFilter ||
                                            props?.hideQueryInput
                                        }
                                        value="author"
                                    >
                                        Author
                                    </Radio>
                                    <Radio
                                        isDisabled={props?.restrictToCommander || props?.hideQueryInput}
                                        value="title"
                                    >
                                        Title
                                    </Radio>
                                </RadioGroup>
                            </View>
                            {!props?.disableColorSelector && (
                                <Flex direction="column">
                                    <Text UNSAFE_className="filtersSectionLabel">POWER LEVEL RANGE</Text>
                                    <RangeSlider
                                        // UNSAFE_className="filtersSectionLabel"
                                        onChange={handleRangeSliderChange}
                                        marginStart={0}
                                        labelPosition="side"
                                        label=" "
                                        minValue={1}
                                        maxValue={10}
                                        defaultValue={{ start: low, end: high }}
                                    />
                                </Flex>
                            )}
                            {!props?.disableColorSelector && (
                                <Flex direction="column" columnGap="10px">
                                    <Text UNSAFE_className="filtersSectionLabel">COLOR IDENTITY</Text>
                                    <ManaSelector
                                        handleColorFilterChange={handleColorFiltersChange}
                                        colorFilters={colorFilters}
                                    />
                                </Flex>
                            )}
                            <Divider size={'S'} />
                            <Flex direction={'row'} marginTop={20} justifyContent={'space-between'}>
                                <Button
                                    variant={'negative'}
                                    type={'reset'}
                                    onPress={async () => {
                                        pushResetFilters();
                                        close();
                                    }}
                                >
                                    Reset
                                </Button>
                                <Flex direction={'row-reverse'}>
                                    <ButtonGroup>
                                        <Button
                                            variant="secondary"
                                            onPress={async () => {
                                                pushFiltersChangeUpstream();
                                                close();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="accent"
                                            onPress={async () => {
                                                pushFiltersChangeUpstream();
                                                close();
                                            }}
                                        >
                                            Apply
                                        </Button>
                                    </ButtonGroup>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Content>
                </Dialog>
            )}
        </DialogTrigger>
    );
}
