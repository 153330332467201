import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    ActionButton,
    AlertDialog,
    Cell,
    Checkbox,
    Column,
    Content,
    Dialog,
    DialogContainer,
    DialogTrigger,
    Divider,
    Flex,
    Form,
    Heading,
    Item,
    Menu,
    MenuTrigger,
    ProgressCircle,
    Row,
    TableBody,
    TableHeader,
    TableView,
} from '@adobe/react-spectrum';
import './ManageDecks.css';
import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { postManageDecksListOperation } from '../../data/redux/slices/profileSlice';
import { GenericLoadingDialog } from '../genericLoadingDialog/GenericLoadingDialog';

export function ManageDecks() {
    const dispatch = useDispatch();

    const isFetching = useSelector((state) => state?.profile?.isFetching);
    const decks = useSelector((state) => state?.profile?.manageDecks?.decks || []);

    const helmetName = `Manage Decks`;
    const helmetCanonical = `https://www.commandersalt.com/linkedaccounts`;
    const helmetDescription = `The saltiest MtG deck builders on the planet!`;
    const helmetTitle = `Commandersalt.com - The saltiest MtG deck builders on the planet!`;
    const [selectedKeys, setSelectedKeys] = useState(new Set());

    const max = window.innerWidth - 180;

    let columns = [
        { name: 'Name', uid: 'title', maxWidth: max },
        { name: 'Visible', uid: 'visibility', maxWidth: 80 },
        { name: 'Author', uid: 'authorInfo', maxWidth: 80 },
        // { name: 'Commander(s)', uid: 'provider', maxWidth: max },
    ];

    let indexId = -1;
    const rows = decks.map((deck) => {
        indexId += 1;
        return {
            ...deck,
            deckId: deck.id,
            id: indexId,
            authorInfo: deck.hideAuthorInfo ? 'Hide' : 'Show',
            visibility: deck.isPrivate ? 'Private' : 'Public',
        };
    });

    const performOp = (op) => {
        dispatch(
            postManageDecksListOperation(
                op,
                selectedDecks.map((item) => {
                    return item.id;
                })
            )
        );

        setSelectedKeys(new Set());
    };

    let authorLabel = '';
    let authorAction = '';
    let visibilityLabel = '';
    let visibilityAction = '';

    const selectedDecks = Array.from(selectedKeys)
        .filter((item) => {
            return rows[parseInt(item)];
        })
        .map((item) => {
            const index = parseInt(item);
            return { ...rows[index], id: rows[index].deckId };
        });

    const deckPrivate =
        selectedDecks.filter((item) => {
            return item.isPrivate;
        }).length !== selectedDecks.length;

    const authorHide =
        selectedDecks.filter((item) => {
            return item.hideAuthorInfo;
        }).length !== selectedDecks.length;

    authorLabel = authorHide ? 'Hide author info' : 'Show author info';
    authorAction = authorHide ? 'hide_author' : 'show_author';
    visibilityLabel = deckPrivate ? 'Make private' : 'Make public';
    visibilityAction = deckPrivate ? 'mark_private' : 'mark_public';

    const deleteWarningText = `Are you sure you wish to remove ${
        selectedDecks.length > 1 ? 'these decks' : 'this deck'
    } from Commandersalt?`;

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs />
            {isFetching && <GenericLoadingDialog />}
            <Flex direction={'column'} alignItems={'center'}>
                <Flex direction={'column'} rowGap={30} maxWidth={1000} margin={10}>
                    <Flex direction={'row'} justifyContent={'space-around'} width={'100%'} minHeight={30}>
                        <Flex
                            direction={'row'}
                            justifyContent={'start'}
                            maxWidth={`calc(100vw - 20px)`}
                            width={'100vw'}
                            columnGap={20}
                            wrap
                            // isHidden={Array.from(selectedKeys).length === 0}
                        >
                            <span style={{ marginTop: 5 }}>With selected:</span>
                            <Flex direction={'row'} columnGap={10}>
                                <ActionButton
                                    aria-label="Mark Private"
                                    isDisabled={Array.from(selectedKeys).length < 1}
                                    onPress={() => performOp(visibilityAction)}
                                    UNSAFE_style={{ cursor: 'pointer' }}
                                >
                                    {visibilityLabel}
                                </ActionButton>
                                <ActionButton
                                    aria-label="Hide Author"
                                    isDisabled={Array.from(selectedKeys).length < 1}
                                    onPress={() => performOp(authorAction)}
                                    UNSAFE_style={{ cursor: 'pointer' }}
                                >
                                    {authorLabel}
                                </ActionButton>
                                <DialogTrigger>
                                    <ActionButton
                                        aria-label="Delete"
                                        type="reset"
                                        isDisabled={Array.from(selectedKeys).length < 1}
                                        UNSAFE_style={{
                                            cursor: 'pointer',
                                            backgroundColor: '#cb3729',
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        Delete
                                    </ActionButton>
                                    <AlertDialog
                                        variant={'destructive'}
                                        title="Confirm Delete"
                                        primaryActionLabel="Continue"
                                        cancelLabel="Cancel"
                                        onPrimaryAction={() => performOp('delete')}
                                    >
                                        {deleteWarningText}
                                    </AlertDialog>
                                </DialogTrigger>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex direction={'row'} justifyContent={'space-around'} width={'100%'}>
                        <TableView
                            aria-label="Example table with dynamic content"
                            maxWidth={`calc(100vw - 20px)`}
                            width={'100vw'}
                            selectionMode="multiple"
                            selectedKeys={selectedKeys}
                            onSelectionChange={setSelectedKeys}
                            minHeight={400}
                        >
                            <TableHeader columns={columns}>
                                {(column) => (
                                    <Column
                                        key={column.uid}
                                        align={column.uid === 'date' ? 'end' : 'start'}
                                        maxWidth={column.maxWidth}
                                    >
                                        {column.name}
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody items={rows}>
                                {(item) => <Row>{(columnKey) => <Cell>{item[columnKey]}</Cell>}</Row>}
                            </TableBody>
                        </TableView>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
