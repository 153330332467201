import React from 'react';
import { withAuthenticationRequired } from 'react-oidc-context';
import { Settings } from './Settings';

class SettingsContainer extends React.Component {
    render() {
        return <Settings />;
    }
}
export default withAuthenticationRequired(SettingsContainer);
// export default SettingsContainer;
