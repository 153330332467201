import React, { useRef, useState, useEffect, Children } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ActionButton,
    Divider,
    Flex,
    Item,
    Menu,
    MenuTrigger,
    Section,
    Switch,
    Text,
    Checkbox,
    View,
    ProgressCircle,
    TableHeader,
    Column,
    TableBody,
    Row,
    TableView,
    Cell,
    repeat,
    Grid,
    Content,
} from '@adobe/react-spectrum';

import { fetchUpgradesListByCategory, setUpgradesFetchingTrue } from '../../../data/redux/slices/upgradesSlice';
import DownloadFromCloud from '@spectrum-icons/workflow/DownloadFromCloud';
import { ManaSelector } from '../../filterpanel/ManaSelector';
import { useDebouncedCallback } from 'use-debounce';
import { getAnalytics, logEvent } from 'firebase/analytics';
import styles from './UpgradelizerPane.css';
import { resultsColumns } from '../../resultsListing/columns/ResultsColumns';
import { resultsColumnRenderers } from '../../resultsListing/renderers/ResultsColumnRenderers';
import { resultsCellRenderers } from '../../resultsListing/renderers/ResultsCellRenderers';
import { ResultsListing } from '../../resultsListing/ResultsListing';
import User from '@spectrum-icons/workflow/User';

export function UpgradelizerPane() {
    const dispatch = useDispatch();
    const deck = useSelector((state) => state.deck.deck);
    const cards = useSelector((state) => state.deck.cards);
    let upgradesList = useSelector((state) => state.upgrades.list);
    upgradesList = (upgradesList || []).filter((item) => {
        return item && item?.id;
    });
    const hasDoneFirstLoad = useSelector((state) => state.upgrades.hasDoneFirstLoad);
    const isFetching = useSelector((state) => state.upgrades.isFetching);
    const loadingState = useSelector((state) => state?.upgrades?.loadingState);
    const nextCursor = useSelector((state) => state?.upgrades?.nextCursor);
    const selectedListKey = useSelector((state) => state.upgrades.selectedListKey);
    const selectedListIndex = useSelector((state) => state.upgrades.selectedListIndex);

    const CATEGORY_CONTSTANTS = {
        COUNTERSPELL: {
            enum: 'COUNTERSPELL',
            label: 'Counters',
            shortLabel: 'Counters',
            field: 'counterspell',
            color: '#33ffb1',
            chartIndex: 1,
        },
        CANTRIP: {
            enum: 'CANTRIP',
            label: 'Draw',
            shortLabel: 'Draw',
            field: 'cantrip',
            color: '#008cff',
            chartIndex: 3,
        },
        RAMP: {
            enum: 'RAMP',
            label: 'Ramp',
            shortLabel: 'Ramp',
            field: 'ramp',
            color: '#00a600',
            chartIndex: 6,
        },
        STAX: {
            enum: 'STAX',
            label: 'Stax',
            shortLabel: 'Stax',
            field: 'stax',
            color: '#F8F8FF',
            chartIndex: 9,
        },
        TUTOR: {
            enum: 'TUTOR',
            label: 'Tutors',
            shortLabel: 'Tutors',
            field: 'tutor',
            color: '#0446c5',
            chartIndex: 10,
        },
        FASTMANA: {
            enum: 'FASTMANA',
            label: 'Fastmana',
            shortLabel: 'Fastmana',
            field: 'fastmana',
            color: 'rgb(215,179,0)',
            chartIndex: 12,
        },
        TAXES: {
            enum: 'TAXES',
            label: 'Taxes',
            shortLabel: 'Taxes',
            field: 'taxes',
            color: '#e8f5f5',
            chartIndex: 16,
        },
        GRAVEYARD_HATE: {
            enum: 'GRAVEYARD_HATE',
            label: 'Graveyard Hate',
            shortLabel: 'Graveyard',
            field: 'graveyard',
            color: '#157c1d',
            chartIndex: 19,
        },
        SPOT_REMOVAL: {
            enum: 'SPOT_REMOVAL',
            label: 'Spot Removal',
            shortLabel: 'Graveyard',
            field: 'graveyard',
            color: '#157c1d',
            chartIndex: 19,
        },
        BOARD_WIPES: {
            enum: 'BOARD_WIPES',
            label: 'Board Wipes',
            shortLabel: 'Wipes',
            field: 'boardWipes',
            color: '#157c1d',
            chartIndex: 19,
        },
        EVASION: {
            enum: 'EVASION',
            label: 'Evasion',
            shortLabel: 'Evasion',
            field: 'evasion',
            color: '#157c1d',
            chartIndex: 19,
        },
        OTHER_CONTROL: {
            enum: 'OTHER_CONTROL',
            label: 'Other Control',
            shortLabel: 'Control',
            field: 'otherControl',
            color: '#157c1d',
            chartIndex: 19,
        },
        DISCARD: {
            enum: 'DISCARD',
            label: 'Discard',
            shortLabel: 'Discard',
            field: 'discard',
            color: '#157c1d',
            chartIndex: 19,
        },
    };

    const CATEGORY_SECTION_CONSTANTS = {
        CONSISTENCY: {
            label: 'Consistency',
            enum: 'CONSISTENCY',
            items: [CATEGORY_CONTSTANTS.TUTOR.enum, CATEGORY_CONTSTANTS.CANTRIP.enum],
        },
        EFFICIENCY: {
            label: 'Efficiency',
            enum: 'EFFICIENCY',
            items: [CATEGORY_CONTSTANTS.FASTMANA.enum, CATEGORY_CONTSTANTS.RAMP.enum],
        },
        INTERACTION: {
            label: 'Interaction',
            enum: 'INTERACTION',
            items: [
                CATEGORY_CONTSTANTS.SPOT_REMOVAL.enum,
                CATEGORY_CONTSTANTS.BOARD_WIPES.enum,
                CATEGORY_CONTSTANTS.COUNTERSPELL.enum,
                CATEGORY_CONTSTANTS.STAX.enum,
                CATEGORY_CONTSTANTS.TAXES.enum,
                CATEGORY_CONTSTANTS.GRAVEYARD_HATE.enum,
                CATEGORY_CONTSTANTS.EVASION.enum,
                CATEGORY_CONTSTANTS.OTHER_CONTROL.enum,
                CATEGORY_CONTSTANTS.DISCARD.enum,
            ],
        },
    };

    const baseCardTypeList = [
        // '___HEADER',
        'Artifact',
        'Battle',
        'Creature',
        'Enchantment',
        'Instant',
        'Land',
        'Planeswalker',
        'Sorcery',
    ];

    let [categoryId, setCategoryId] = React.useState(null);
    let [reservedListSelected, setReservedListSelected] = React.useState(false);
    const [colorFilters, setColorFilters] = useState(deck?.colorIdentity?.toLowerCase() || null);
    let [selectedTypes, setSelectedTypes] = useState(
        new Set(
            baseCardTypeList.map((item) => {
                return item.toUpperCase();
            })
        )
    );

    const getCategoryList = () => {
        const sections = {};

        const retValue = Object.keys(CATEGORY_SECTION_CONSTANTS).map((key) => {
            const item = CATEGORY_SECTION_CONSTANTS[key];
            return {
                name: item.label,
                id: item.enum,
                children: (CATEGORY_SECTION_CONSTANTS[key].items || [])
                    ?.sort((a, b) => {
                        if (a < b) {
                            return -1;
                        }
                        if (a > b) {
                            return 1;
                        }
                    })
                    ?.map((id) => {
                        return {
                            id: id,
                            name: CATEGORY_CONTSTANTS[id]?.label || '',
                        };
                    }),
            };
        });

        return retValue;
    };

    const categoryIdList = getCategoryList();

    const cardTypeList = baseCardTypeList
        .map((item) => {
            return { name: item, id: item.toUpperCase() };
        })
        ?.sort((a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
        });

    const doLoad = (id, reservedList, colors, types, isReload) => {
        if (CATEGORY_CONTSTANTS?.hasOwnProperty(id) && CATEGORY_CONTSTANTS[id]?.hasOwnProperty('field')) {
            // setTimeout(() => {
            dispatch(
                fetchUpgradesListByCategory(
                    deck.id,
                    CATEGORY_CONTSTANTS[id].field,
                    reservedList,
                    colors,
                    [...types] || [],
                    isReload ? null : nextCursor,
                    isReload
                )
            );
            // }, 5000);
        }
    };
    const handleCategoryChange = (id, reservedList, colors, types) => {
        doLoad(id, reservedList, colors, types, true);
        setCategoryId(id);
        setReservedListSelected(reservedList);
        setSelectedTypes(types);
    };

    const handleColorFiltersChange = useDebouncedCallback((evn) => {
        handleCategoryChange(
            categoryId,
            reservedListSelected,
            evn?.colors?.toUpperCase(),
            // selectedTypes.filter((item) => {
            //     return item !== '___HEADER';
            // })
            selectedTypes
        );
        setColorFilters(evn.colors);
        // setChanged(true);
    }, 500);

    const handleTypesChange = (...params) => {
        console.log(`TYPES => ${JSON.stringify(params, null, 4)}`);
    };

    const handleLoadMore = (evn) => {
        // if (
        //     !isFetching &&
        //     loadingState === null //&& //) {
        //     // upgradesList?.length === 0
        // ) {
        if (nextCursor !== null) {
            doLoad(categoryId, reservedListSelected, colorFilters, selectedTypes, nextCursor === null);
        }
    };

    const getTableHeight = () => {
        return `${window.innerHeight - 215}px`;
    };

    const [tableHeight, setTableHeight] = useState(getTableHeight());

    const resizeHandler = () => {
        setTableHeight(getTableHeight());
    };

    // useEffect(() => {
    //     window.addEventListener('resize', resizeHandler, { passive: true });
    //
    //     return () => {
    //         window.removeEventListener('resize', resizeHandler);
    //     };
    // }, []);

    const disabledColorsList = [['W', 'U', 'B', 'R', 'G'], deck?.colors || []].reduce((p, c) =>
        p.filter((e) => !c.includes(e))
    );

    const fart = useRef(null);

    return (
        // <BaseDeckPane fieldName="name" content={<div />} showScore={true} categories={{}}>
        <Flex direction="column" minHeight={800}>
            <span className="panelText" style={{ fontSize: '15px', fontWeight: 'bolder' }}>
                <p />
                Feed your deck to the Upgradelizer!
            </span>
            <Text marginTop={10}>
                The only cards that we show here are ones that have been used in decks that other people have imported.
                In other words:
                <ul>
                    <li>These results do not include every card ever printed</li>
                    <li>The amount of cards available to search will grow over time as people use the site</li>
                </ul>
            </Text>
            <Divider width="100%" marginBottom={20} size={'S'} />
            <Flex direction="row" justifyContent="center">
                <Flex
                    direction="column"
                    justifyContent="space-around"
                    marginEnd="10px"
                    width={'100%'}
                    marginStart={10}
                    rowGap={10}
                >
                    <Flex direction={'row'} columnGap={20} rowGap={20} wrap>
                        <Flex direction={'column'} flexBasis={350} flexGrow={1} rowGap={10}>
                            <Flex direction={'row'}>
                                <Text minWidth={75} UNSAFE_className={'upgradeMenuBoxLabel'}>
                                    Category:
                                </Text>
                                <MenuTrigger>
                                    <ActionButton
                                        flexBasis={500}
                                        flexGrow={1}
                                        UNSAFE_className={'upgradeMenuBoxActionLabel'}
                                        isDisabled={isFetching}
                                    >
                                        {CATEGORY_CONTSTANTS[categoryId]?.label || 'Select a category...'}
                                    </ActionButton>
                                    <Menu
                                        items={categoryIdList}
                                        selectedKeys={categoryId}
                                        onSelectionChange={(evn) => {
                                            handleCategoryChange(
                                                evn.currentKey,
                                                reservedListSelected,
                                                colorFilters,
                                                selectedTypes
                                            );
                                        }}
                                        selectionMode="single"
                                    >
                                        {(item) => (
                                            <Section items={item.children} title={item.name}>
                                                {/*{(item) => getSymbols(item)}*/}
                                                {(item) => <Item>{item.name}</Item>}
                                            </Section>
                                        )}
                                    </Menu>
                                </MenuTrigger>
                            </Flex>
                            <Flex direction={'row'}>
                                <Text minWidth={75} UNSAFE_className={'upgradeMenuBoxLabel'}>
                                    Types:
                                </Text>
                                <MenuTrigger>
                                    <ActionButton
                                        flexBasis={500}
                                        flexGrow={1}
                                        UNSAFE_className={'upgradeMenuBoxActionLabel'}
                                        isDisabled={!hasDoneFirstLoad || isFetching}
                                    >
                                        Select
                                    </ActionButton>
                                    <Menu
                                        items={cardTypeList}
                                        selectedKeys={selectedTypes}
                                        onSelectionChange={(evn) => {
                                            handleCategoryChange(categoryId, reservedListSelected, colorFilters, evn);
                                        }}
                                        selectionMode={'multiple'}
                                        minWidth={400}
                                    >
                                        {(item) => {
                                            if (item.name !== '___HEADER') {
                                                return <Item>{item.name}</Item>;
                                            } else {
                                                return (
                                                    <Item>
                                                        <View minWidth={400} paddingBottom={20}>
                                                            <Checkbox>test</Checkbox>
                                                        </View>
                                                    </Item>
                                                );
                                            }
                                        }}
                                    </Menu>
                                </MenuTrigger>
                            </Flex>
                        </Flex>
                        <Flex direction={'row'} justifyContent={'center'} maxWidth={400} flexBasis={200} flexGrow={1}>
                            <Checkbox
                                isSelected={reservedListSelected}
                                onChange={(evn) => {
                                    handleCategoryChange(categoryId, evn, colorFilters, selectedTypes);
                                }}
                                isDisabled={!hasDoneFirstLoad || isFetching}
                            >
                                Include Reserved List
                            </Checkbox>
                        </Flex>
                        <Flex direction={'row'} justifyContent={'center'} maxWidth={400} flexBasis={200} flexGrow={1}>
                            <span>
                                <Flex direction="row" columnGap="10px">
                                    <Text UNSAFE_className="filtersSectionLabel">COLOR IDENTITY</Text>
                                </Flex>
                                <ManaSelector
                                    handleColorFilterChange={handleColorFiltersChange}
                                    colorFilters={hasDoneFirstLoad && !isFetching ? colorFilters : []}
                                    disabledColors={disabledColorsList}
                                />
                            </span>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Divider width="100%" marginTop="10px" size={'S'} />

            {hasDoneFirstLoad ? (
                <>
                    <Grid columns={repeat('auto-fit', '350px')} autoRows={450} justifyContent="center" gap="size-100">
                        {upgradesList.map((item) => (
                            <Content>
                                <Flex
                                    direction={'column'}
                                    justifyContent={'center'}
                                    justifySelf={'center'}
                                    alignItems={'center'}
                                    margin={30}
                                    height={450}
                                >
                                    <span className={'nameAndScoreLabel'} style={{ textAlign: 'center' }}>
                                        {item.name?.toUpperCase()}
                                    </span>
                                    <img src={item.imageUri} width={300} />
                                    {/*<span className={'nameAndScoreLabel'} style={{textAlign: 'center'}}>*/}
                                    {/*    SCORE: {item.score}*/}
                                    {/*</span>*/}
                                </Flex>
                            </Content>
                        ))}
                    </Grid>

                    <ActionButton
                        onPress={handleLoadMore}
                        isDisabled={!nextCursor || isFetching}
                        alignSelf={'center'}
                        width={300}
                        marginTop={50}
                        aria-label="Load more"
                        marginBottom={20}
                        UNSAFE_style={{ cursor: 'pointer' }}
                    >
                        {!isFetching ? (
                            <>
                                <DownloadFromCloud />
                                Load More
                            </>
                        ) : (
                            <ProgressCircle isIndeterminate="true" size="S" />
                        )}
                    </ActionButton>
                </>
            ) : (
                <>
                    {isFetching ? (
                        <View
                            minHeight="size-6000"
                            alignSelf="center"
                            height="size-6000"
                            UNSAFE_style={{ verticalAlign: 'middle' }}
                        >
                            <Flex
                                direction="column"
                                UNSAFE_style={{
                                    marginTop: 150,
                                    top: '50%',
                                    left: '50%',
                                }}
                                alignContent="center"
                                alignItems="center"
                            >
                                <ProgressCircle isIndeterminate="true" size="L" />
                                <Text UNSAFE_className="previewDeckTitleLabel">Loading...</Text>
                            </Flex>
                        </View>
                    ) : (
                        <div />
                    )}
                </>
            )}
        </Flex>
    );
}
