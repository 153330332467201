import { Content, Dialog, DialogContainer, Divider, Flex, Heading, ProgressCircle } from '@adobe/react-spectrum';

export function GenericLoadingDialog() {
    return (
        <DialogContainer type={'modal'} isDismissable={false} onDismiss={() => {}}>
            <Dialog width={'calc(100vw - 40px)'} maxWidth={400}>
                <Heading>Loading</Heading>
                <Divider />
                <Content>
                    <Flex direction={'row'} UNSAFE_style={{ paddingTop: 20 }} justifyContent={'space-around'}>
                        <ProgressCircle isIndeterminate="true" size="L" />
                    </Flex>
                </Content>
            </Dialog>
        </DialogContainer>
    );
}
