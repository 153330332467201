import { Cell, Content, Divider, Flex, View } from '@adobe/react-spectrum';
import { getGrades } from '../../../data/ScoreDisplayUtils';
import { SORT_MAP } from '../../../data/redux/slices/leaderboardSlice';
import store from '../../../data/redux/store/AppStore';
import React from 'react';
import styles from './ResultsCellRenderers.css';
// import { Card, CardBody, CardPreview } from '@adobe/react-spectrum';

export const tableResultsCellRenderers = {
    CARD_LIST: (item, columnKey, clickCallBack) => {
        if (!store || !store?.getState()) {
            return <div />;
        }

        switch (columnKey) {
            case 'names':
            case 'name':
                let i = -1;

                const handleClick = (index) => {
                    clickCallBack && clickCallBack(item?.imageUris?.[index] || null);
                };

                return (
                    <Cell>
                        <Content>
                            <Flex direction={'column'}>
                                {item?.names &&
                                    item.names.map((name) => {
                                        const index = ++i;

                                        return (
                                            <span
                                                onClick={() => handleClick(index)}
                                                className={
                                                    item?.imageUris
                                                        ? 'commanderTiersTileStatisticsGridCellContentLeftClickable'
                                                        : 'commanderTiersTileStatisticsGridCellContentLeft'
                                                }
                                            >
                                                {name}
                                            </span>
                                        );
                                    })}
                            </Flex>
                        </Content>
                    </Cell>
                );
            default:
                return (
                    <Cell>
                        <div className={'commanderTiersTileStatisticsGridCellContentLeft'}>{item[columnKey]}</div>
                    </Cell>
                );
        }
    },
    UPGRADES: (item, columnKey) => {
        if (!store || !store?.getState()) {
            return <div />;
        }

        switch (columnKey) {
            case 'name':
                return (
                    <Cell>
                        <Content height={450}>
                            <Flex
                                direction={'column'}
                                justifyContent={'center'}
                                justifySelf={'center'}
                                alignItems={'center'}
                                maxWidth={500}
                                margin={30}
                                height={450}
                            >
                                <span className={'nameAndScoreLabel'} style={{ textAlign: 'center' }}>
                                    {item.name?.toUpperCase()}
                                </span>
                                <img src={item.imageUri} width={300} />
                                <span className={'nameAndScoreLabel'} style={{ textAlign: 'center' }}>
                                    SCORE: {item.score}
                                </span>
                            </Flex>
                        </Content>
                    </Cell>
                );
            default:
                return (
                    <Cell>
                        <div className="TextCell">{item[columnKey]}</div>
                    </Cell>
                );
        }
    },
    LEADERBOARD: (item, columnKey) => {
        if (!store || !store?.getState()) {
            return <div />;
        }

        const sortBy = store.getState()?.[item?.storeKey]?.sortBy || store.getState()?.leaderboard?.sortBy;

        switch (columnKey) {
            case 'authorAvatarUrl':
                const avatarUrl = item?.author?.profileImageUrl || `/resources/blank-user-avatar.png`;
                const colors = item?.colorIdentity?.match(/./g) || [];

                return (
                    <Cell>
                        <Content height={90}>
                            <Flex direction={'column'} height={90}>
                                <div className="cellAvatar">
                                    <img src={avatarUrl} height="50px" alt={item.author?.username} />
                                </div>
                                <div className="cellAvatarManaSymbols">
                                    {colors && colors.length > 0 && (
                                        <div>
                                            {colors?.map((symbol) => {
                                                return (
                                                    <span
                                                        key={`${item?.id}__color${symbol?.toLowerCase()}`}
                                                        className={`mana xs s${symbol?.toLowerCase()}`}
                                                        style={{ opacity: '1' }}
                                                    ></span>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </Flex>
                        </Content>
                    </Cell>
                );
                break;
            case 'saltRating':
                const score = item.hasOwnProperty('score') ? item.score : item[sortBy?.field];
                const grade = getGrades(item);
                const prop = sortBy?.enum.toLowerCase();
                let floatValue = parseFloat(/[0-9]*\.[0-9]/.exec(score.toString())?.[0] || '0');
                if (!floatValue.toString().includes(`.`)) {
                    floatValue = `${floatValue}.0`;
                }

                return (
                    <Cell>
                        <Content height={90}>
                            <div
                                id={`${item?.indexPrefix}${item?.index}`}
                                className="cellScore"
                                style={{ color: grade?.[prop]?.color }}
                            >
                                {floatValue}
                            </div>
                        </Content>
                    </Cell>
                );
                break;
            case 'commanders':
                let commanderList = item?.commanders;
                if (!Array.isArray(commanderList)) {
                    commanderList = [item?.commanders];
                }
                let commanders = ``;
                commanderList.forEach((commander) => {
                    if (commanders === ``) {
                        commanders = commander;
                    } else {
                        commanders = `${commanders}\n${commander}`;
                    }
                });

                return (
                    <Cell>
                        <Content height={90}>
                            <Flex direction={'column'} height={90}>
                                <div className="cellTitleLabel">{item?.title}</div>
                                <Divider
                                    size="S"
                                    width="100%"
                                    marginTop={5}
                                    marginBottom={5}
                                    UNSAFE_style={{ backgroundColor: '#252525' }}
                                />
                                <div className="cellCommanderLabel">{commanders}</div>
                            </Flex>
                        </Content>
                    </Cell>
                );

                break;
            default:
                return (
                    <Cell>
                        <Content height={90}>
                            <div className="TextCell">{item[columnKey]}</div>
                        </Content>
                    </Cell>
                );
        }
    },
};
