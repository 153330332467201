import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';
import { SORT_MAP } from './leaderboardSlice';
import { fetchCommanderLeaderboardItems, setCommandersFilters } from './commandersSlice';

export const authorsSlice = createSlice({
    name: 'authors',
    initialState: {
        listItems: [],
        nextCursor: null,
        isFetching: false,
        loadingState: `idle`,
        filters: {
            query: '',
            queryType: '',
            range: {
                high: 10,
                low: 1,
            },
            colorFilters: '',
        },
        sortBy: SORT_MAP.POWERLEVEL,
        sortDirection: SORT_MAP.DIRECTION.DESC,
        selectedListKey: null,
        selectedListIndex: -1,
    },
    reducers: {
        setAuthorsItems: (state, action) => {
            state.listItems = action.payload.map((item) => {
                return {
                    ...item,
                    key: item?.id,
                    storeKey: 'authors',
                };
            });
        },
        setAuthorsNextCursor: (state, action) => {
            state.nextCursor = action.payload;
        },
        addNewAuthorsItems: (state, action) => {
            const { cursor, isReload } = action.payload;

            state.nextCursor = cursor || null;
            let i = -1;
            const newItems = action.payload.items.map((item) => {
                i += 1;

                return {
                    ...item,
                    key: item?.id,
                    storeKey: 'authors',
                    indexPrefix: 'authors_',
                    index: i + state?.listItems?.length || 0,
                };
            });

            if (!isReload) {
                state.listItems = state.listItems.concat(newItems);
            } else {
                state.listItems = newItems;
            }

            state.isFetching = false;
            state.loadingState = `idle`;
        },
        setAuthorsIsFetching: (state, action) => {
            state.isFetching = action.payload.isFetching;
            state.loadingState = action.payload.loadingState;
        },
        setAuthorsFilters: (state, action) => {
            state.filters = action.payload;
        },
        setAuthorsSortBy: (state, action) => {
            state.sortBy = action?.payload || SORT_MAP.POWERLEVEL;
        },
        setAuthorSortDirection: (state, action) => {
            state.sortDirection = action?.payload || SORT_MAP.DIRECTION.DESC;
        },
        setSelectedListKey: (state, action) => {
            state.selectedListKey = action?.payload;
        },
        setSelectedListIndex: (state, action) => {
            state.selectedListIndex = action?.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setAuthorsItems,
    addNewAuthorsItems,
    setAuthorsIsFetching,
    setAuthorsFilters,
    setAuthorsSortBy,
    setAuthorSortDirection,
    setSelectedListKey,
    setSelectedListIndex,
} = authorsSlice.actions;

export const authorsFetchFiltered = (filters, sortBy, sortDirection) => (dispatch) => {
    dispatch(setAuthorsFilters(filters));
    dispatch(fetchAuthorsItems(null, true, sortBy, sortDirection, filters));
};

export const fetchAuthorsItems =
    (cursor, isReload = false, sortBy, sortDirection, filters = { queryType: 'author' }) =>
    (dispatch) => {
        cursor = cursor !== -1 ? cursor : null;

        if (isReload) {
            dispatch(setAuthorsItems([]));
        }

        dispatch(
            setAuthorsIsFetching({
                isFetching: true,
                loadingState: cursor ? `loadingMore` : `loading`,
            })
        );

        DynamoConnector.getList(cursor, 'authors', sortBy?.index || null, filters, sortDirection, (results) => {
            // DynamoConnector.getLeaderboard(cursor, filters, sortBy, (results) => {
            dispatch(
                addNewAuthorsItems({
                    items: results?.Items || [],
                    cursor: results?.LastEvaluatedKey ? results.LastEvaluatedKey : null,
                    type: null,
                    isReload,
                })
            );
        });
    };

export default authorsSlice.reducer;
