import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';

export const metaSlice = createSlice({
    name: 'upgrades',
    initialState: {
        metaBlob: {},
        categories: [],
        list: [],
        isFetching: false,
        loadingState: 'idle',
        nextCursor: null,
        selectedListKey: null,
        selectedListIndex: -1,
        forceSkipAutoLoad: false,
    },
    reducers: {
        setMetaList: (state, action) => {
            state.metaBlob = action.payload;

            state.isFetching = false;
            state.loadingState = 'idle';
        },
        setMetaFetchingTrue: (state, action) => {
            state.isFetching = true;
            if (action?.payload?.isReload) {
                state.metaBlob = {};
                state.list = [];
                state.nextCursor = null;
            }
        },
        setMetaFetchingFalse: (state, action) => {
            state.isFetching = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setMetaFetchingFalse, setMetaList, setMetaFetchingTrue } = metaSlice.actions;

export const fetchMetaList =
    (isReload = false) =>
    (dispatch) => {
        dispatch(setMetaFetchingTrue({ isReload }));

        DynamoConnector.getCEDHMeta((results) => {
            dispatch(setMetaList({ ...results }));
            dispatch(setMetaFetchingFalse);
        });
    };

export default metaSlice.reducer;
