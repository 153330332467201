import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';
import { fetchUpgradesListByCategory } from './upgradesSlice';
import { setAppRoute } from './appSlice';

export const deckSlice = createSlice({
    name: 'deck',
    initialState: {
        deck: null,
        isFetching: false,
        cards: {},
        cache: {},
        isFailedFetch: false,
    },
    reducers: {
        setDeck: (state, action) => {
            state.deck = action.payload;
            state.isShowing = true;
            state.isFetching = false;
            state.isFailedFetch = false;

            state.cards = {};

            if (Object.keys(state?.deck?.cards || {}).length > 0) {
                state.cards = state.deck.cards;
            }
            // state?.deck?.cards?.map((card) => {
            //     state.cards[card?.id] = card;
            // });

            if (!state.cache.hasOwnProperty(action.payload.id)) {
                // console.log(`CACHING DECK ID: ${action.payload.id}`);
                state.cache[action.payload.id] = action.payload;
            }
        },
        setDeckLoadFailed: (state) => {
            state.deck = null;
            state.isFetching = false;
            state.isShowing = true;
            state.isFailedFetch = true;
        },
        setFetchingTrue: (state) => {
            state.isFetching = true;
            state.deck = null;
        },
    },
});

// Actions
export const { setDeck, setDeckLoadFailed, setFetchingTrue } = deckSlice.actions;

export const fetchDeck = (id) => (dispatch) => {
    dispatch(setFetchingTrue());

    DynamoConnector.getDeck(
        id,
        (results) => {
            if (results) {
                dispatch(setDeck(results));
            } else {
                dispatch(setAppRoute({ route: '/' }));
            }
            //
        },
        () => {
            dispatch(setDeckLoadFailed());
        }
    );
};

export default deckSlice.reducer;
