import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';
import { SORT_MAP } from './leaderboardSlice';

export const authorDetailsSlice = createSlice({
    name: 'authorDetails',
    initialState: {
        listItems: [],
        nextCursor: null,
        isFetching: false,
        loadingState: `idle`,
        details: null,
        filters: {
            query: '',
            queryType: '',
            range: {
                high: 10,
                low: 1,
            },
            colorFilters: '',
        },
        sortBy: SORT_MAP.POWERLEVEL,
        sortDirection: SORT_MAP.DIRECTION.DESC,
        isRefreshing: false,
        selectedListKey: null,
        selectedListIndex: -1,
    },
    reducers: {
        setIsRefreshing: (state, action) => {
            state.isRefreshing = action.payload;
        },
        setAuthorDetailsItems: (state, action) => {
            state.listItems = action.payload.map((item) => {
                return {
                    ...item,
                    key: item?.id,
                    storeKey: 'authorDetails',
                };
            });
        },
        setAuthorDetailsNextCursor: (state, action) => {
            state.nextCursor = action.payload;
        },
        addNewAuthorDetailsItems: (state, action) => {
            const { cursor, isReload } = action.payload;

            state.nextCursor = cursor || null;
            let i = -1;
            const newItems = action.payload.items
                .map((item) => {
                    return {
                        ...item,
                        key: item?.id,
                        storeKey: 'authorDetails',
                    };
                })
                .map((item) => {
                    i += 1;

                    return {
                        ...item,
                        key: item?.id,
                        storeKey: 'authorDetails',
                        indexPrefix: 'authorDetails_',
                        index: i + state?.listItems?.length || 0,
                    };
                });

            if (!isReload) {
                state.listItems = state.listItems.concat(newItems);
            } else {
                state.listItems = newItems;
            }

            state.isFetching = false;
            state.loadingState = `idle`;
        },
        setAuthorDetailsIsFetching: (state, action) => {
            state.isFetching = action.payload.isFetching;
            state.loadingState = action.payload.loadingState;
        },
        setAuthorDetailsFilters: (state, action) => {
            state.filters = action.payload;
        },
        setAuthorDetailsSortBy: (state, action) => {
            state.sortBy = action?.payload || SORT_MAP.POWERLEVEL;
        },
        setAuthorDetailsSortDirection: (state, action) => {
            state.sortDirection = action?.payload || SORT_MAP.DIRECTION.DESC;
        },
        setAuthorDetails: (state, action) => {
            state.details = action.payload;
        },
        setSelectedListKey: (state, action) => {
            state.selectedListKey = action?.payload;
        },
        setSelectedListIndex: (state, action) => {
            state.selectedListIndex = action?.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setIsRefreshing,
    setAuthorDetailsItems,
    setAuthorDetails,
    addNewAuthorDetailsItems,
    setAuthorDetailsIsFetching,
    setAuthorDetailsFilters,
    setAuthorDetailsSortBy,
    setAuthorDetailsSortDirection,
    setSelectedListKey,
    setSelectedListIndex,
} = authorDetailsSlice.actions;

export const fetchAuthorDetails = (id) => (dispatch) => {
    dispatch(setAuthorDetails(null));
    dispatch(fetchAuthorDetailsItems(null, null, true, SORT_MAP.POWERLEVEL, SORT_MAP.DIRECTION.DESC, id));

    DynamoConnector.getAuthorDetails(id, (results) => {
        dispatch(setAuthorDetails(results || null));
    });
};

export const authorDetailsFetchFiltered = (filters, sortBy, sortDirection) => (dispatch) => {
    dispatch(setAuthorDetailsFilters(filters));
    dispatch(fetchAuthorDetailsItems(null, filters, sortBy, sortDirection, filters));
};

export const batchRefreshAuthor =
    (list = []) =>
    (dispatch) => {
        dispatch(setIsRefreshing(true));
        DynamoConnector.getBatchRefreshAuthorDeckList(list, () => {
            console.log(`done => author refresh`);
            dispatch(fetchAuthorDetailsItems(null, null, true));
            dispatch(setIsRefreshing(false));
        });
    };

export const fetchAuthorDetailsItems =
    (cursor, filters = {}, isReload = false, sortBy, sortDirection, _id) =>
    (dispatch) => {
        let id = !_id ? null : _id;

        if (!id) {
            if (window.location.pathname.includes(`/id/`)) {
                id = window.location.pathname.substring(window.location.pathname.indexOf(`/id/`) + 4);
            }
        }

        if (id) {
            filters = {
                ...filters,
                id,
            };
        }

        cursor = cursor !== -1 ? cursor : null;

        if (isReload) {
            dispatch(setAuthorDetailsItems([]));
        }

        dispatch(
            setAuthorDetailsIsFetching({
                isFetching: true,
                loadingState: cursor ? `loadingMore` : `loading`,
            })
        );

        DynamoConnector.getList(cursor, 'authors', sortBy?.index || null, filters, sortDirection, (results) => {
            // DynamoConnector.getLeaderboard(cursor, filters, sortBy, (results) => {
            dispatch(
                addNewAuthorDetailsItems({
                    items: results?.Items || [],
                    cursor: results?.LastEvaluatedKey ? results.LastEvaluatedKey : null,
                    type: null,
                    isReload,
                })
            );
        });
    };

export default authorDetailsSlice.reducer;
