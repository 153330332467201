import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Flex,
    MenuTrigger,
    ActionButton,
    Menu,
    Item,
    Grid,
    Content,
    ProgressCircle,
    View,
    Heading,
    Divider,
    Dialog,
    DialogTrigger,
    DialogContainer,
    Text,
} from '@adobe/react-spectrum';
import { SORT_MAP } from '../../data/redux/slices/leaderboardSlice';
import './ResultsListing.css';
import '../filterpanel/mana-cost.css';
import { setAppRoute, setRouteChangeRefreshPending } from '../../data/redux/slices/appSlice';
import { getAnalytics, logEvent } from 'firebase/analytics';
import isMobile from 'ismobilejs';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import ChevronUp from '@spectrum-icons/workflow/ChevronUp';
import DownloadFromCloud from '@spectrum-icons/workflow/DownloadFromCloud';
import { FilterPanel } from '../filterpanel/FilterPanel';
import Filter from '@spectrum-icons/workflow/Filter';
import { ImportStatusBar } from '../importStatusBar/ImportStatusBar';

export function ResultsListing(props) {
    const dispatch = useDispatch();
    const sortBy = props?.sortBy;
    const selectedListIndex = props?.selectedListIndex;

    const isImporting = useSelector((state) => state.import.isImporting);
    const routeChangeRefreshPending = useSelector((state) => state.app.routeChangeRefreshPending);
    const mobile = isMobile(window.navigator).phone;

    const handleSortChange = (sortIndex) => {
        try {
            logEvent(getAnalytics(), `setSort_${sortIndex.toUpperCase()}`);
        } catch (error) {}

        props?.handleSortChange(sortIndex);
    };

    const handleSortDirectionChange = () => {
        const direction =
            props.sortDirection === SORT_MAP.DIRECTION.DESC ? SORT_MAP.DIRECTION.ASC : SORT_MAP.DIRECTION.DESC;

        try {
            logEvent(getAnalytics(), `setSort_direction_${direction.toUpperCase()}`);
        } catch (error) {}

        props?.handleSortDirectionChange && props?.handleSortDirectionChange(direction);
    };

    if (routeChangeRefreshPending === props?.routePath) {
        if (selectedListIndex && selectedListIndex > 0) {
            dispatch(setRouteChangeRefreshPending(null));
        }
    }

    const handler = () => {
        if (document.getElementById('resultsContainer')?.getBoundingClientRect()?.y <= 75) {
            document.getElementById('defaultImportButton').style.display = 'block';
        } else {
            document.getElementById('defaultImportButton').style.display = 'none';
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handler, { passive: true });

        return () => {
            window.removeEventListener('scroll', handler);
        };
    }, []);

    const handleSelectionChange = (evn) => {
        try {
            const currentKey = evn?.currentKey || props?.selectedListKey;
            const item = props?.listItems?.filter((value) => {
                return value?.id === currentKey;
            })?.[0];

            dispatch(setAppRoute({ route: `/details/deck/${currentKey}` }));

            props?.handleIndexSelection && props.handleIndexSelection(item?.index);
            props?.handleSelectionChange && props.handleSelectionChange(currentKey || null);
        } catch (error) {}
    };

    const isFetching = props?.loadingState === 'loading' || props?.loadingState === 'loadingMore';

    const displayProgressBar = isImporting;
    const progressDialogHeader = 'Importing deck...';

    const minHeight = window.innerHeight - 70;

    const getPerksStyling = (perks) => {
        const deckRowHighlighting = perks?.deckRowHighlighting; //.replace('%, 1)', '%, 0.5)');
        if (perks) {
            let retValue = {};

            if (perks?.useDeckRowHighlighting) {
                retValue = {
                    ...retValue,
                    background: `linear-gradient(to right, ${deckRowHighlighting} 0%, rgba(0, 0, 0, 0) 65%)`, //, rgba(0, 0, 0, 0) 75%, ${deckRowHighlighting} 100%)`,
                };
            }
            return retValue;
        }

        return {};
    };
    return (
        <Flex
            direction="column"
            id={'resultsContainer'}
            justifyContent={'space-around'}
            alignItems={'center'}
            width={'100vw'}
            marginTop={50}
        >
            {displayProgressBar && (
                <DialogContainer type={'modal'}>
                    <Dialog width={'calc(100vw - 40px)'} maxWidth={800}>
                        <Heading>{progressDialogHeader}</Heading>
                        <Divider />
                        <Content>
                            <Flex direction={'row'} UNSAFE_style={{ paddingTop: 20 }}>
                                {<ImportStatusBar />}
                            </Flex>
                        </Content>
                    </Dialog>
                </DialogContainer>
            )}

            <View
                flex={1}
                borderWidth="thin"
                borderColor="light"
                borderRadius="medium"
                borderBottomWidth={0}
                paddingTop="size-0"
                UNSAFE_style={{ backgroundColor: '#0E0E0EFF' }}
                minHeight={minHeight}
                maxWidth={`calc(100vw - 20px)`}
                width={1200}
            >
                <div className={'stickyResultsToolBar'}>
                    <Flex
                        direction={'row'}
                        // justifyContent={'space-between'}
                        columnGap={30}
                        UNSAFE_style={{
                            backgroundColor: '#0E0E0EFF',
                            paddingTop: 10,
                            paddingBottom: 10,
                        }}
                    >
                        {props?.showFilterPanel ? (
                            <FilterPanel
                                onFiltersChange={(evn) => {
                                    props.onFiltersChange(evn);
                                }}
                                filters={props.filters}
                                restrictToCommander={props?.restrictToCommander}
                                hideAuthorFilter={props?.hideAuthorFilter}
                                hideQueryInput={props?.hideQueryInput}
                            />
                        ) : (
                            <div>&nbsp;</div>
                        )}
                        {props?.showFilterPanel && <Divider orientation={'vertical'} height={25} size={'S'} />}
                        <Flex direction="row" alignSelf="flex-end">
                            <Text UNSAFE_className="printDialogButton" marginEnd={15} marginTop={3}>
                                SORT:
                            </Text>
                            <MenuTrigger>
                                <ActionButton UNSAFE_style={{ height: 25 }}>{sortBy.label}</ActionButton>
                                <Menu onAction={(key) => handleSortChange(key)}>
                                    <Item key={SORT_MAP.POWERLEVEL.enum}>{SORT_MAP.POWERLEVEL.label}</Item>
                                    <Item key={SORT_MAP.SALT.enum}>{SORT_MAP.SALT.label}</Item>
                                    {props?.includeSortByCount && (
                                        <Item key={SORT_MAP.COUNT.enum}>{SORT_MAP.COUNT.label}</Item>
                                    )}
                                </Menu>
                            </MenuTrigger>
                            <ActionButton UNSAFE_style={{ height: 25 }} onPress={handleSortDirectionChange}>
                                {props?.sortDirection === SORT_MAP.DIRECTION.DESC ? <ChevronDown /> : <ChevronUp />}
                            </ActionButton>
                        </Flex>
                    </Flex>
                    <div className={'fadedResultsToolBarBottom'} />
                </div>
                <Grid flex={1} justifyContent="center" rowGap={0} maxWidth={`calc(100vw - 20px)`} width={1200}>
                    {props?.listItems.map((item) => (
                        <div
                            key={item.id}
                            // id={item.id}
                            onClick={() => {
                                handleSelectionChange({ anchorKey: item.id, currentKey: item.id });
                            }}
                            style={{
                                flex: '3 1 100vw',
                                width: 1200,
                                maxWidth: `calc(100vw - 20px)`,
                            }}
                        >
                            <View
                                flex={1}
                                borderWidth="thin"
                                // borderColor="light"
                                borderRadius="medium"
                                padding="size-200"
                                // backgroundColor="gray-75"
                                paddingTop={1}
                                paddingBottom={1}
                                paddingStart={5}
                                paddingEnd={5}
                                minHeight="0px"
                                marginStart={10}
                                marginEnd={10}
                                marginBottom={5}
                                UNSAFE_className={props?.selectedListIndex === item.index ? 'rowselected' : 'row'}
                                UNSAFE_style={{ ...getPerksStyling(item?.perks || null) }}
                            >
                                {/*<span className={"row"}>*/}
                                <Flex
                                    flex={1}
                                    direction={'row'}
                                    columnGap={15}
                                    // justifyContent={'center'}
                                    // justifySelf={'center'}
                                    // alignItems={'center'}
                                    // margin={30}
                                    // height={450}
                                    UNSAFE_style={{ backgroundColor: '#000000', borderRadius: '10px' }}
                                >
                                    {props?.columns.map((col) => (
                                        <span key={`${item.id}_${col.uid}`} style={{ ...col }}>
                                            {props?.cellRenderer(item, col.uid)}
                                        </span>
                                    ))}
                                </Flex>
                            </View>
                        </div>
                    ))}
                </Grid>
                <Flex flex={1} direction={'row'} justifyContent={'center'}>
                    <ActionButton
                        onPress={props?.handleLoadMore}
                        isDisabled={isFetching || !props.hasMore}
                        alignSelf={'center'}
                        width={300}
                        marginTop={20}
                        aria-label="Load more"
                        marginBottom={20}
                        UNSAFE_style={{ cursor: 'pointer' }}
                    >
                        {!isFetching ? (
                            <>
                                <DownloadFromCloud />
                                Load More
                            </>
                        ) : (
                            <ProgressCircle isIndeterminate="true" size="S" />
                        )}
                    </ActionButton>
                </Flex>
            </View>
        </Flex>
    );
}
