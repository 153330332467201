import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector, getClientDomainPrefix, getDomainPrefix } from '../../DynamoConnector';

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        metadata: {
            avatarUri: '',
            bio: '',
            id: null,
            profileName: '',
            isDiscordMod: false,
        },
        preferences: {
            importPreferences: {
                allowPublicImport: false,
                hideAuthorInfo: false,
                markDecksAsPrivate: false,
            },
            profilePreferences: {
                isPrivate: false,
                hideLinkedAccounts: false,
            },
            subscriberFeatures: {
                patreonLink: {
                    isLinked: false,
                },
                perks: {
                    deckRowHighlighting: '',
                    useDeckRowHighlighting: false,
                    showSubscriberLevelOnDeckRows: true,
                    showSubscriberLevelOnProfile: true,
                },
                isMember: false,
                subscriberTierName: null,
                isSubscribed: false,
                subscriberTier: 0,
            },
        },
        accountLink: {
            isFetchingIdentifierString: false,
            identifierString: '',
            isValidating: false,
            validationSuccessful: false,
            isDeleting: false,
        },
        manageDecks: {
            decks: [],
        },
        isFetching: false,
        profiles: {},
    },
    reducers: {
        setProfileData: (state, action) => {
            state.metadata = action.payload.metadata;
            state.profiles = action.payload.profiles;
            state.preferences.importPreferences = { ...action.payload.importPreferences };
            state.preferences.profilePreferences = { ...action.payload.profilePreferences };
            state.preferences.subscriberFeatures = { ...action.payload.subscriberFeatures };

            // console.log(JSON.stringify(state, null, 4));
        },
        setIsFetchingIdentifierString: (state, action) => {
            state.accountLink.isFetchingIdentifierString = action.payload;
        },
        setIdentifierString: (state, action) => {
            state.accountLink.identifierString = action.payload;
        },
        setIsValidating: (state, action) => {
            state.accountLink.isValidating = action.payload;
        },
        setIsDeleting: (state, action) => {
            state.accountLink.isDeleting = action.payload;
        },
        setValidationSuccessful: (state, action) => {
            state.accountLink.validationSuccessful = action.payload;
        },
        resetAddProfileDialog: (state, action) => {
            state.accountLink = {
                accountLink: {
                    isFetchingIdentifierString: false,
                    identifierString: '',
                    isValidating: false,
                    validationSuccessful: false,
                    isDeleting: false,
                },
            };
        },
        setIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },
        setManageDecksList: (state, action) => {
            state.manageDecks.decks = action.payload.sort((a, b) => {
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1;
                } else {
                    return 1;
                }
            });
        },
        resetProfile: (state, action) => {
            state.metadata = {
                avatarUri: '',
                bio: '',
                id: null,
                profileName: '',
                isDiscordMod: false,
            };
            state.preferences = {
                importPreferences: {
                    allowPublicImport: false,
                    hideAuthorInfo: false,
                    markDecksAsPrivate: false,
                },
                profilePreferences: {
                    isPrivate: false,
                    hideLinkedAccounts: false,
                },
                subscriberFeatures: {
                    patreonLink: {
                        isLinked: false,
                    },
                    perks: {
                        deckRowHighlighting: '',
                        useDeckRowHighlighting: false,
                        showSubscriberLevelOnDeckRows: true,
                        showSubscriberLevelOnProfile: true,
                    },
                    isMember: false,
                    subscriberTierName: null,
                    isSubscribed: false,
                    subscriberTier: 0,
                },
            };
            // state.accountLink = {
            //     isFetchingIdentifierString: false,
            //     identifierString: '',
            //     isValidating: false,
            //     validationSuccessful: false,
            //     isDeleting: false,
            // };
            state.manageDecks = {
                decks: [],
            };
            state.profiles = {};
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setProfileData,
    setIsFetchingIdentifierString,
    setIdentifierString,
    setIsValidating,
    setValidationSuccessful,
    setIsDeleting,
    resetAddProfileDialog,
    setManageDecksIsFetching,
    setManageDecksList,
    setIsFetching,
    resetProfile,
} = profileSlice.actions;

export const updateUserSettings = (settings) => (dispatch) => {
    dispatch(setIsFetching(true));
    DynamoConnector.postUpdateUserPreferences(settings, (results) => {
        dispatch(getUserProfile());
    });
};

export const uploadAvatar = (file, size) => (dispatch) => {
    dispatch(setIsFetching(true));
    DynamoConnector.uploadAvatar(file, size, (results) => {
        //
        // dispatch(setIsFetching(false));
        dispatch(getUserProfile());
    });
};

export const disconnectPatreon = () => (dispatch) => {
    dispatch(setIsFetching(true));
    DynamoConnector.deletePatreonLink((results) => {
        dispatch(getUserProfile());
    });
};

export const refreshPatreon = () => (dispatch) => {
    dispatch(setIsFetching(true));
    DynamoConnector.getRefreshPatreonMemberships((results) => {
        dispatch(getUserProfile());
    });
};

export const finalizePatreonCode = (code) => (dispatch) => {
    DynamoConnector.validatePatreonCode(code, (results) => {
        window.location.href = `${getClientDomainPrefix()}/settings`;
    });
};

export const getUserProfile = () => (dispatch) => {
    dispatch(setIsFetching(true));
    dispatch(resetProfile());
    DynamoConnector.getUserProfile((results) => {
        if (results) {
            dispatch(setProfileData(results));
            dispatch(setIsFetching(false));
        }
    });
};

export const postManageDecksListOperation =
    (operation, deckIds = []) =>
    (dispatch) => {
        dispatch(setIsFetching(true));
        DynamoConnector.postManagedDecksOp(operation, deckIds, (results) => {
            if (results) {
                dispatch(setManageDecksList(results));
            }

            dispatch(getManageDecksList());
        });
    };

export const getManageDecksList = () => (dispatch) => {
    dispatch(setIsFetching(true));
    DynamoConnector.getManagedDecks((results) => {
        if (results) {
            dispatch(setManageDecksList(results));
        }

        dispatch(setIsFetching(false));
    });
};

export const getAccountLinkCode = (provider) => (dispatch) => {
    dispatch(setIsFetchingIdentifierString(true));
    dispatch(setIdentifierString(null));

    DynamoConnector.getAccountLinkCode(provider, (results) => {
        if (results) {
            dispatch(setIdentifierString(results));
        }

        dispatch(setIsFetchingIdentifierString(false));
    });
};

export const postValidateAccountLink = (provider, username) => (dispatch) => {
    dispatch(setIsValidating(true));
    DynamoConnector.postValidateLinkCode(provider, username, (results) => {
        dispatch(setValidationSuccessful(results));
        dispatch(setIsValidating(false));
        dispatch(getUserProfile());
    });
};

export const deleteAccountLink = (list) => (dispatch) => {
    dispatch(setIsDeleting(true));
    dispatch(setIsFetching(true));
    DynamoConnector.deleteAccountLink(list, (results) => {
        dispatch(setIsDeleting(false));
        dispatch(getUserProfile());
    });
};

export const fetchProfileData = () => (dispatch) => {
    // cursor = cursor !== -1 ? cursor : null;
    // dispatch(
    //     setAuthorsIsFetching({
    //         isFetching: true,
    //         loadingState: cursor ? `loadingMore` : `loading`,
    //     })
    // );
    // DynamoConnector.getList(cursor, 'authors', sortBy?.index || null, filters, sortDirection, (results) => {
    //     // DynamoConnector.getLeaderboard(cursor, filters, sortBy, (results) => {
    //     dispatch(
    //         addNewAuthorsItems({
    //             items: results?.Items || [],
    //             cursor: results?.LastEvaluatedKey ? results.LastEvaluatedKey : null,
    //             type: null,
    //             isReload,
    //         })
    //     );
    // });
};

export default profileSlice.reducer;
