import React from 'react';
import { withAuthenticationRequired } from 'react-oidc-context';
import { LinkedAccounts } from './LinkedAccounts';

class LinkedAccountsContainer extends React.Component {
    render() {
        return <LinkedAccounts />;
    }
}
export default withAuthenticationRequired(LinkedAccountsContainer);
