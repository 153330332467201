import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';
import { SORT_MAP } from './leaderboardSlice';

export const commanderDetailsSlice = createSlice({
    name: 'commanderDetails',
    initialState: {
        listItems: [],
        nextCursor: null,
        isFetching: false,
        loadingState: `idle`,
        details: null,
        filters: {
            query: '',
            queryType: '',
            range: {
                high: 10,
                low: 1,
            },
            colorFilters: '',
        },
        sortBy: SORT_MAP.POWERLEVEL,
        sortDirection: SORT_MAP.DIRECTION.DESC,
        selectedListKey: null,
        selectedListIndex: -1,
    },
    reducers: {
        setCommanderDetailsItems: (state, action) => {
            state.listItems = action.payload.map((item) => {
                return {
                    ...item,
                    key: item?.id,
                    storeKey: 'commanderDetails',
                };
            });
        },
        setCommanderDetailsNextCursor: (state, action) => {
            state.nextCursor = action.payload;
        },
        addNewCommanderDetailsItems: (state, action) => {
            const { cursor, isReload } = action.payload;

            state.nextCursor = cursor || null;
            let i = -1;
            const newItems = action.payload.items
                .filter((item) => {
                    return item !== null;
                })
                .filter((item) => {
                    if (item?.hasOwnProperty('isIllegal')) {
                        return !item.isIllegal;
                    }

                    return true;
                })
                .map((item) => {
                    return {
                        ...item,
                        key: item?.id,
                        storeKey: 'commanderDetails',
                    };
                })
                .map((item) => {
                    i += 1;

                    return {
                        ...item,
                        key: item?.id,
                        storeKey: 'commanderDetails',
                        indexPrefix: 'commanderDetails_',
                        index: i + state?.listItems?.length || 0,
                    };
                });

            if (!isReload) {
                state.listItems = state.listItems.concat(newItems);
            } else {
                state.listItems = newItems;
            }

            state.isFetching = false;
            state.loadingState = `idle`;
        },
        setCommanderDetailsIsFetching: (state, action) => {
            state.isFetching = action.payload.isFetching;
            state.loadingState = action.payload.loadingState;
        },
        setCommanderDetailsFilters: (state, action) => {
            state.filters = action.payload;
        },
        setCommanderDetailsSortBy: (state, action) => {
            state.sortBy = action?.payload || SORT_MAP.POWERLEVEL;
        },
        setCommanderDetailsSortDirection: (state, action) => {
            state.sortDirection = action?.payload || SORT_MAP.DIRECTION.DESC;
        },
        setCommanderDetails: (state, action) => {
            state.details = action.payload;
        },
        setSelectedListKey: (state, action) => {
            state.selectedListKey = action?.payload;
        },
        setSelectedListIndex: (state, action) => {
            state.selectedListIndex = action?.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setCommanderDetailsItems,
    setCommanderDetails,
    addNewCommanderDetailsItems,
    setCommanderDetailsIsFetching,
    setCommanderDetailsFilters,
    setCommanderDetailsSortBy,
    setCommanderDetailsSortDirection,
    setSelectedListKey,
    setSelectedListIndex,
} = commanderDetailsSlice.actions;

export const fetchCommanderDetails = (id) => (dispatch) => {
    dispatch(setCommanderDetails(null));
    dispatch(fetchCommanderDetailItems(null, true, SORT_MAP.POWERLEVEL, SORT_MAP.DIRECTION.DESC, {}, id));

    DynamoConnector.getCommanderDetails(id, (results) => {
        dispatch(setCommanderDetails(results || null));
    });
};

export const commandersFetchFiltered = (filters, sortBy, sortDirection) => (dispatch) => {
    dispatch(setCommanderDetailsFilters(filters));
    dispatch(fetchCommanderDetailItems(null, true, sortBy, sortDirection, filters));
};

export const fetchCommanderDetailItems =
    (cursor, isReload = false, sortBy, sortDirection, filters = { queryType: 'commander' }, _id) =>
    (dispatch) => {
        let id = !_id ? null : _id;

        if (!id) {
            if (window.location.pathname.includes(`/id/`)) {
                id = window.location.pathname.substring(window.location.pathname.indexOf(`/id/`) + 4);
            }
        }

        if (id) {
            filters = {
                ...filters,
                id,
            };
        }

        cursor = cursor !== -1 ? cursor : null;

        if (isReload) {
            dispatch(setCommanderDetailsItems([]));
        }

        dispatch(
            setCommanderDetailsIsFetching({
                isFetching: true,
                loadingState: cursor ? `loadingMore` : `loading`,
            })
        );

        DynamoConnector.getList(cursor, 'commanders', sortBy?.index || null, filters, sortDirection, (results) => {
            // DynamoConnector.getLeaderboard(cursor, filters, sortBy, (results) => {
            dispatch(
                addNewCommanderDetailsItems({
                    items: results?.Items || [],
                    cursor: results?.LastEvaluatedKey ? results.LastEvaluatedKey : null,
                    type: null,
                    isReload,
                })
            );
        });
    };

export default commanderDetailsSlice.reducer;
