import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    ActionButton,
    AlertDialog,
    Checkbox,
    DialogTrigger,
    Flex,
    Header,
    TextArea,
    TextField,
    ColorPicker,
    View,
    ColorEditor,
    parseColor,
} from '@adobe/react-spectrum';
import './Settings.css';
import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import {
    disconnectPatreon,
    refreshPatreon,
    updateUserSettings,
    uploadAvatar,
} from '../../data/redux/slices/profileSlice';
import { getClientDomainPrefix } from '../../data/DynamoConnector';
import { GenericLoadingDialog } from '../genericLoadingDialog/GenericLoadingDialog';
import { UploadAvatar } from './crop/UploadAvatar';
import RealTimeCustomerProfile from '@spectrum-icons/workflow/RealTimeCustomerProfile';
import { AvatarImage } from '../avatarImage/AvatarImage';

export function Settings() {
    const dispatch = useDispatch();

    /** selector **/
    const metadata = useSelector((state) => state?.profile?.metadata);
    const profilePreferences = useSelector((state) => state?.profile?.preferences?.profilePreferences);
    const importPreferences = useSelector((state) => state?.profile?.preferences?.importPreferences);
    const subscriberFeatures = useSelector((state) => state?.profile?.preferences?.subscriberFeatures);
    const isDiscordMod = useSelector((state) => state?.profile?.metadata?.isDiscordMod);
    const isFetching = useSelector((state) => state?.profile?.isFetching);
    const avatarUrl = useSelector((state) => state?.profile?.metadata?.avatarUri);

    /** state **/
    const [profileName, setProfileName] = useState(metadata?.profileName || '');
    const [bio, setBio] = useState(metadata?.bio || '');
    const [isPrivate, setIsPrivate] = useState(profilePreferences?.isPrivate || false);
    const [hideLinkedAccounts, setHideLinkedAccounts] = useState(profilePreferences?.hideLinkedAccounts || false);
    const [allowPublicImport, setAllowPublicImport] = useState(importPreferences?.allowPublicImport || false);
    const [hideAuthorInfo, setHideAuthorInfo] = useState(importPreferences?.hideAuthorInfo || false);
    const [markDecksAsPrivate, setMarkDecksAsPrivate] = useState(importPreferences?.markDecksAsPrivate || false);

    const [showSubscriberLevelOnDeckRows, setShowSubscriberLevelOnDeckRows] = useState(
        !subscriberFeatures?.isSubscribed && !isDiscordMod
            ? false
            : subscriberFeatures?.perks?.showSubscriberLevelOnDeckRows
    );
    const [showSubscriberLevelOnProfile, setShowSubscriberLevelOnProfile] = useState(
        !subscriberFeatures?.isSubscribed && !isDiscordMod
            ? false
            : subscriberFeatures?.perks?.showSubscriberLevelOnProfile
    );
    const [useDeckRowHighlighting, setUseDeckRowHighlighting] = useState(
        !subscriberFeatures?.isSubscribed && !isDiscordMod ? false : subscriberFeatures?.perks?.useDeckRowHighlighting
    );
    let [deckRowHighlighting, setDeckRowHighlighting] = useState(
        subscriberFeatures?.perks?.deckRowHighlighting ? parseColor(subscriberFeatures?.perks?.deckRowHighlighting) : ''
    );

    console.log(`subscriberFeatures?.perks?.deckRowHighlighting => ${subscriberFeatures?.perks?.deckRowHighlighting}`);
    try {
        console.log(
            `parseColor(subscriberFeatures?.perks?.deckRowHighlighting) => ${parseColor(
                subscriberFeatures?.perks?.deckRowHighlighting
            )}`
        );

        console.log(`deckRowHighlighting => ${deckRowHighlighting.toString()}`);
    } catch (error) {}

    const handleUpload = async (file) => {
        dispatch(uploadAvatar(file, file.size));
    };

    const handleSavePress = async () => {
        if (profileName && profileName !== '') {
            const hslValue = useDeckRowHighlighting ? deckRowHighlighting.toString() : null;

            dispatch(
                updateUserSettings({
                    profileName,
                    bio,
                    isPrivate,
                    hideLinkedAccounts,
                    allowPublicImport,
                    hideAuthorInfo,
                    markDecksAsPrivate,
                    showSubscriberLevelOnDeckRows,
                    showSubscriberLevelOnProfile,
                    useDeckRowHighlighting,
                    deckRowHighlighting: hslValue,
                })
            );
        }
    };

    console.log(`isFetching => ${isFetching}`);

    /** helmet **/
    const helmetName = `Settings`;
    const helmetCanonical = `https://www.commandersalt.com/profile`;
    const helmetDescription = `The saltiest MtG deck builders on the planet!`;
    const helmetTitle = `Commandersalt.com - The saltiest MtG deck builders on the planet!`;
    const [imgSrc, setImgSrc] = useState('');
    const [showUploadDialog, setShowUploadDialog] = useState(false);

    const onSelectFile = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            // setCrop(undefined); // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgSrc(reader.result?.toString());
                setShowUploadDialog(true);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onCloseUploadDialog = () => {
        setShowUploadDialog(false);
    };

    const loginToPatreon = async () => {
        window.location.href = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=0DRv84QK_AgskGxIT8ijnwHogq-RCCe-WIgpF73GyMD0gS3LZswFXEtkc9ndsMO6&redirect_uri=${getClientDomainPrefix()}/settings&scopes=identity`;
    };

    const renderAvatarSelectorAndPatreonLinks = () => {
        return (
            <Flex direction={'column'} marginBottom={30}>
                <Flex direction={'column'} rowGap={30} marginBottom={50}>
                    <Flex
                        direction={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        alignContent={'center'}
                        UNSAFE_style={{ cursor: 'pointer' }}
                    >
                        <div
                            onClick={() => {
                                document.getElementById('input').click();
                            }}
                        >
                            <AvatarImage size={200} avatarUrl={avatarUrl} showDefaultIcon={true} />
                        </div>
                        <span>Click to change</span>
                        <input id={'input'} type="file" accept="image/*" hidden={true} onChange={onSelectFile} />
                    </Flex>
                </Flex>
                {showUploadDialog && <UploadAvatar imgSrc={imgSrc} onClose={onCloseUploadDialog} />}
                <View
                    maxWidth={`calc(100vw - 20px)`}
                    width={400}
                    borderWidth="thin"
                    borderColor="light"
                    borderRadius="medium"
                    padding="size-50"
                    backgroundColor="gray-75"
                    paddingStart={20}
                    paddingBottom={20}
                    paddingTop={20}
                    paddingEnd={20}
                    maxHeight={300}
                >
                    <Flex direction={'column'}>
                        <span style={{ marginBottom: 30 }}>
                            Account level perks are currently unlocked by any paid tier subscription on Patreon.
                        </span>
                        {!subscriberFeatures?.patreonLink?.isLinked ? (
                            <ActionButton onPress={loginToPatreon} isDisabled={isFetching}>
                                Connect Patreon
                            </ActionButton>
                        ) : (
                            <Flex direction={'column'} rowGap={30}>
                                <Flex direction={'row'} columnGap={50}>
                                    <span>Patreon Connected!</span>
                                    <DialogTrigger>
                                        <ActionButton
                                            aria-label="Disconnect"
                                            type="reset"
                                            UNSAFE_style={{
                                                cursor: 'pointer',
                                                backgroundColor: '#cb3729',
                                                fontWeight: 'bolder',
                                            }}
                                            isDisabled={isFetching}
                                        >
                                            Disconnect
                                        </ActionButton>
                                        <AlertDialog
                                            variant={'destructive'}
                                            title="Confirm Disconnect"
                                            primaryActionLabel="Continue"
                                            cancelLabel="Cancel"
                                            onPrimaryAction={() => dispatch(disconnectPatreon())}
                                        >
                                            Are you sure you wish to disconnect your Patreon account?
                                        </AlertDialog>
                                    </DialogTrigger>
                                </Flex>
                                {subscriberFeatures?.isMember ? (
                                    <Flex direction={'row'}>
                                        <span>Tier</span>
                                        <span>{subscriberFeatures?.subscriberTierName}</span>
                                    </Flex>
                                ) : (
                                    <Flex direction={'column'} rowGap={10}>
                                        You are not currently a member...
                                        <Flex direction={'row'} columnGap={10}>
                                            <ActionButton
                                                isDisabled={isFetching}
                                                width={200}
                                                onPress={() => {
                                                    window.open(`https://www.patreon.com/c/user?u=4796225`, `_blank`);
                                                }}
                                            >
                                                Join our Patreon!
                                            </ActionButton>
                                            <ActionButton
                                                isDisabled={isFetching}
                                                onPress={() => {
                                                    dispatch(refreshPatreon());
                                                }}
                                            >
                                                Refresh
                                            </ActionButton>
                                        </Flex>
                                    </Flex>
                                )}
                            </Flex>
                        )}
                    </Flex>
                </View>
            </Flex>
        );
    };

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs />
            {isFetching && <GenericLoadingDialog />}
            <Flex direction={'column'} alignItems={'center'}>
                <Flex direction={'row'} justifyContent={'space-around'} width={1200} marginBottom={30}>
                    <Flex direction={'column'} maxWidth={`calc(100vw - 20px)`} width={600} rowGap={30}>
                        {isDiscordMod && (
                            <Flex direction={'column'} marginTop={30} marginBottom={30}>
                                <span style={{ color: '#fff540' }}>
                                    Looks like you are a discord mod or a friend of the silo!
                                </span>
                                <span style={{ color: '#ff5050' }}>Subscriber features unlocked {'<3'}</span>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                <Flex direction={'row'} justifyContent={'space-around'} columnGap={60} wrap>
                    {window.innerWidth <= 600 && <>{renderAvatarSelectorAndPatreonLinks()}</>}
                    <Flex
                        direction={'column'}
                        maxWidth={`calc(100vw - 20px)`}
                        width={600}
                        rowGap={30}
                        marginBottom={30}
                    >
                        <Flex direction={'column'} rowGap={40}>
                            <Flex direction={'column'} maxWidth={`calc(100vw - 20px)`} width={600}>
                                <Header>General Options</Header>
                                <Flex direction={'row'} wrap>
                                    <TextField
                                        label={`Profile Name`}
                                        value={profileName}
                                        onChange={setProfileName}
                                        maxWidth={`calc(100vw - 20px)`}
                                        width={600}
                                        maxLength={30}
                                        isDisabled={isFetching}
                                    />
                                    <Flex direction={'row-reverse'} maxWidth={`calc(100vw - 20px)`} width={600}>
                                        <span style={{ fontSize: '.6rem', float: 'right' }}>
                                            ({profileName.length}/30)
                                        </span>
                                    </Flex>
                                    <TextArea
                                        label={'Bio'}
                                        value={bio}
                                        onChange={setBio}
                                        maxWidth={`calc(100vw - 20px)`}
                                        width={600}
                                        height={200}
                                        maxLength={700}
                                        isDisabled={isFetching}
                                    />
                                    <Flex direction={'row-reverse'} maxWidth={`calc(100vw - 20px)`} width={600}>
                                        <span style={{ fontSize: '.6rem', float: 'right' }}>({bio.length}/700)</span>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex direction={'column'} maxWidth={`calc(100vw - 20px)`} width={400}>
                                <Header>Profile and deck display options</Header>
                                <Checkbox isSelected={isPrivate} onChange={setIsPrivate} isDisabled={isFetching}>
                                    Profile is private
                                </Checkbox>
                                <Checkbox
                                    isSelected={hideLinkedAccounts}
                                    onChange={setHideLinkedAccounts}
                                    isDisabled={isFetching}
                                >
                                    Hide linked accounts
                                </Checkbox>
                                <Checkbox
                                    isDisabled={(!subscriberFeatures.isSubscribed && !isDiscordMod) || isFetching}
                                    isSelected={showSubscriberLevelOnProfile}
                                    onChange={setShowSubscriberLevelOnProfile}
                                >
                                    Display subscriber on your profile
                                </Checkbox>
                                <Checkbox
                                    isDisabled={(!subscriberFeatures.isSubscribed && !isDiscordMod) || isFetching}
                                    isSelected={showSubscriberLevelOnDeckRows}
                                    onChange={setShowSubscriberLevelOnDeckRows}
                                >
                                    Display subscriber level for your decks when they show up in search or leaderboard
                                    results
                                </Checkbox>
                            </Flex>
                            <Flex direction={'column'}>
                                <Header>Import options</Header>
                                <Checkbox
                                    isSelected={allowPublicImport}
                                    onChange={setAllowPublicImport}
                                    isDisabled={isFetching}
                                >
                                    Allow anyone to import your decks
                                </Checkbox>
                                <Checkbox
                                    isSelected={hideAuthorInfo}
                                    onChange={setHideAuthorInfo}
                                    isDisabled={isFetching}
                                >
                                    Hide author info for imported decks by default
                                </Checkbox>
                                <Checkbox
                                    isSelected={markDecksAsPrivate}
                                    onChange={setMarkDecksAsPrivate}
                                    isDisabled={isFetching}
                                >
                                    Make imported decks private by default
                                </Checkbox>
                            </Flex>
                            <Flex direction={'column'}>
                                <Header>Account Perks</Header>
                                <Flex direction={'row'}>
                                    <Checkbox
                                        isDisabled={(!subscriberFeatures.isSubscribed && !isDiscordMod) || isFetching}
                                        isSelected={useDeckRowHighlighting}
                                        onChange={setUseDeckRowHighlighting}
                                    >
                                        Highlight your decks in list views
                                    </Checkbox>
                                    <View isHidden={!useDeckRowHighlighting}>
                                        <ColorPicker
                                            label=""
                                            value={deckRowHighlighting}
                                            onChange={setDeckRowHighlighting}
                                            isDisabled={isFetching}
                                        >
                                            <ColorEditor />
                                        </ColorPicker>
                                    </View>
                                </Flex>
                                <Flex direction={'column'} marginTop={30}>
                                    <ActionButton isDisabled={isFetching} width={200} onPress={handleSavePress}>
                                        Save Settings
                                    </ActionButton>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    {window.innerWidth > 600 && <>{renderAvatarSelectorAndPatreonLinks()}</>}
                </Flex>
            </Flex>
        </Flex>
    );
}
