import React, { useState, useRef } from 'react';

import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, convertToPixelCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

import 'react-image-crop/dist/ReactCrop.css';
import { uploadAvatar } from '../../../data/redux/slices/profileSlice';
import { useDispatch } from 'react-redux';
import {
    ActionButton,
    Button,
    ButtonGroup,
    Content,
    Dialog,
    DialogContainer,
    DialogTrigger,
    Divider,
    Flex,
    Heading,
} from '@adobe/react-spectrum';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

export function UploadAvatar(props) {
    const dispatch = useDispatch();

    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [aspect, setAspect] = useState(1 / 1);

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    async function onDownloadCropClick() {
        const image = imgRef.current;
        const previewCanvas = previewCanvasRef.current;
        if (!image || !completedCrop) {
            throw new Error('Crop canvas does not exist');
        }

        const offscreen = new OffscreenCanvas(400, 400);
        const ctx = offscreen.getContext('2d');
        if (!ctx) {
            throw new Error('No 2d context');
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height
        );

        let file = await offscreen.convertToBlob();

        dispatch(uploadAvatar(file, file.size));
    }

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
            }
        },
        100,
        [completedCrop]
    );

    return (
        <DialogContainer type={'modal'} isDismissable={true} onDismiss={props.onClose}>
            <Dialog>
                <Heading>Upload</Heading>
                <Divider />
                <Content>
                    {!!props.imgSrc && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                            // maxWidth={'calc(100vw - 40px)'}
                            // maxHeight={600}
                        >
                            <img ref={imgRef} src={props.imgSrc} onLoad={onImageLoad} />
                        </ReactCrop>
                    )}
                    <Flex direction={'column'} UNSAFE_style={{ paddingTop: 20 }} justifyContent={'space-around'}>
                        {!!completedCrop && (
                            <>
                                <div>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                            display: 'none',
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </Flex>
                    <Flex direction={'row-reverse'}>
                        <ButtonGroup>
                            <Button variant="secondary" onPress={props.onClose}>
                                Cancel
                            </Button>
                            <Button
                                variant="accent"
                                onPress={async () => {
                                    await onDownloadCropClick();
                                    props.onClose();
                                }}
                            >
                                Confirm
                            </Button>
                        </ButtonGroup>
                    </Flex>
                </Content>
            </Dialog>
        </DialogContainer>
    );
}
