import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    ActionButton,
    Button,
    ButtonGroup,
    Cell,
    Content,
    Dialog,
    DialogTrigger,
    Divider,
    Flex,
    Heading,
    Text,
    View,
} from '@adobe/react-spectrum';
import { SORT_MAP } from '../../data/redux/slices/leaderboardSlice';
import './UserDetails.css';
import { getGrades } from '../../data/ScoreDisplayUtils';
import { Helmet } from 'react-helmet';
import { ResultsListing } from '../resultsListing/ResultsListing';
import { setAppRoute } from '../../data/redux/slices/appSlice';
import { FilterPanel } from '../filterpanel/FilterPanel';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import User from '@spectrum-icons/workflow/User';
import GearsEdit from '@spectrum-icons/workflow/GearsEdit';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { doRefresh } from '../../data/redux/slices/importSlice';
import Refresh from '@spectrum-icons/workflow/Refresh';
import AddCircle from '@spectrum-icons/workflow/AddCircle';
import { SubmitForm } from '../submitform/SubmitForm';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import RealTimeCustomerProfile from '@spectrum-icons/workflow/RealTimeCustomerProfile';
import { getUser } from '../../data/DynamoConnector';
import { resultsColumns } from '../resultsListing/columns/ResultsColumns';
import { resultsColumnRenderers } from '../resultsListing/renderers/ResultsColumnRenderers';
import { resultsCellRenderers } from '../resultsListing/renderers/ResultsCellRenderers';
import {
    fetchUserDetails,
    fetchUserDetailsItems,
    setUserDetailsSortBy,
    setUserDetailsSortDirection,
    userDetailsFetchFiltered,
} from '../../data/redux/slices/userDetailsSlice';
import { GenericLoadingDialog } from '../genericLoadingDialog/GenericLoadingDialog';
import { AvatarImage } from '../avatarImage/AvatarImage';
import { BreadCrumbShareButton } from '../breadcrumbs/buttons/BreadCrumbShareButton';

export function UserDetails() {
    const dispatch = useDispatch();
    let listItems = useSelector((state) => state?.userDetails?.listItems);
    listItems = (listItems || []).filter((item) => {
        return item && item?.id;
    });
    const userId = useSelector((state) => state?.userDetails?.profile?.id);
    const nextCursor = useSelector((state) => state?.userDetails?.nextCursor);
    const isFetching = useSelector((state) => state?.userDetails?.isFetching);
    const loadingState = useSelector((state) => state?.userDetails?.loadingState);
    const searchFilters = useSelector((state) => state?.userDetails?.filters);
    const details = useSelector((state) => state.userDetails?.details);
    const sortBy = useSelector((state) => state?.userDetails?.sortBy);
    const selectedListKey = useSelector((state) => state?.userDetails?.selectedListKey);
    const selectedListIndex = useSelector((state) => state?.userDetails?.selectedListIndex);
    const isRefreshing = useSelector((state) => state?.userDetails?.isRefreshing);
    const isImporting = useSelector((state) => state.import.isImporting);
    const isDiscordMod = useSelector((state) => state?.profile?.metadata?.isDiscordMod);
    const saltRating = useSelector((state) => state?.userDetails?.profile?.saltRating);
    const powerLevelRating = useSelector((state) => state?.userDetails?.profile?.powerLevelRating);
    const synergyRating = useSelector((state) => state?.userDetails?.profile?.synergyRating);
    const bracketRating = useSelector((state) => state?.userDetails?.profile?.bracketRating);
    const count = useSelector((state) => state?.userDetails?.profile?.count);

    const username = useSelector((state) => state?.userDetails?.profile?.metadata?.profileName);
    const bio = useSelector((state) => state?.userDetails.profile?.metadata?.bio);
    const linkedProfiles = useSelector((state) => state?.userDetails.profile?.profiles);
    const avatarUrl = useSelector((state) => state?.userDetails?.profile?.metadata?.avatarUri);
    const subscriberTier = useSelector(
        (state) => state?.userDetails?.profile?.preferences?.subscriberFeatures?.subscriberTier
    );
    const hideLinkedAccounts = useSelector(
        (state) => state?.userDetails?.preferences?.userDetails?.profile?.userPreferences?.hideLinkedAccounts
    );
    const showSubscriberLevelOnProfile = useSelector(
        (state) =>
            state?.userDetails.preferences?.userDetails?.profile?.subscriberFeatures?.perks
                ?.showSubscriberLevelOnProfile
    );

    const handleSortChange = (sortIndex) => {
        dispatch(setUserDetailsSortBy(SORT_MAP[sortIndex], searchFilters));
        dispatch(fetchUserDetailsItems(null, searchFilters, true, SORT_MAP[sortIndex], sortDirection, userId));
    };

    const sortDirection = useSelector((state) => state.userDetails.sortDirection);
    const handleSortDirectionChange = (direction) => {
        dispatch(setUserDetailsSortDirection(direction));
        dispatch(fetchUserDetailsItems(null, searchFilters, true, sortBy, direction, userId));
    };

    const handleFiltersChange = (filters) => {
        dispatch(userDetailsFetchFiltered(filters, sortBy, sortDirection, userId));
    };

    const handleIndexSelection = (evn) => {
        dispatch(setSelectedListIndex(evn));
    };

    const handleLoadMore = () => {
        if (!isFetching && nextCursor !== null) {
            dispatch(fetchUserDetailsItems(nextCursor, searchFilters, false, sortBy, sortDirection, userId));
        }
    };

    const max = window.innerWidth - 180;
    const helmetName = `User Details`;
    const helmetCanonical = `https://www.commandersalt.com/user`;
    const helmetDescription = `The saltiest MtG deck builders on the planet!`;
    const helmetTitle = `Commandersalt.com - The saltiest MtG deck builders on the planet!`;

    const getFavIcon = (source) => {
        switch (source) {
            case 'moxfield':
                return `https://moxfield.com/favicon.png`;
            case 'archidekt':
                return `https://archidekt.com/favicon.ico`;
            case 'tappedout':
                return `https://tappedout.net/favicon.ico`;
        }

        return ``;
    };

    let __subscriberTier = parseInt(subscriberTier) ? `L${subscriberTier}` : 'MAX';

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs
                content={
                    <Flex direction="row" gap="size-100" justifyContent="right">
                        {getUser() && getUser()?.profile?.sub === userId && (
                            <View
                                borderWidth="thin"
                                borderColor="light"
                                borderRadius="medium"
                                padding="size-50"
                                backgroundColor="gray-75"
                                minHeight="0px"
                            >
                                <Flex direction="row" gap="size-100">
                                    <ActionButton
                                        aria-label="View user"
                                        // isDisabled={userUrlButtonDisabled || !deck}
                                        onPress={() => dispatch(setAppRoute({ route: '/settings' }))}
                                        isQuiet="true"
                                        UNSAFE_style={{ cursor: 'pointer' }}
                                    >
                                        <Text
                                            UNSAFE_className="reportCardSectionTitles"
                                            marginStart={10}
                                            position={'relative'}
                                        >
                                            EDIT PROFILE
                                        </Text>
                                        <GearsEdit />
                                    </ActionButton>
                                </Flex>
                            </View>
                        )}
                        <BreadCrumbShareButton label={'Share profile'} isDisabled={isImporting || isRefreshing} />
                        <View
                            borderWidth="thin"
                            borderColor="light"
                            borderRadius="medium"
                            padding="size-50"
                            backgroundColor="red-400"
                            minHeight="0px"
                        >
                            <Flex direction="row" gap="size-100">
                                <DialogTrigger type={'modal'}>
                                    <ActionButton
                                        type="reset"
                                        alignSelf="flex-end"
                                        isDisabled={isImporting || isRefreshing}
                                        isQuiet="true"
                                        UNSAFE_style={{ cursor: 'pointer' }}
                                        aria-label="Import new deck"
                                        // onPress={handleAddNewDeckPress}
                                    >
                                        <AddCircle />
                                    </ActionButton>
                                    {(close) => (
                                        <Dialog width={'calc(100vw - 40px)'} maxWidth={800}>
                                            <Heading>Import Deck</Heading>
                                            {/*<Header>Connection status: Connected</Header>*/}
                                            <Divider />
                                            <Content>
                                                {/*<div style={{ maxWidth: 800, width: '100%' }}>*/}
                                                {/*<Text>Start speed test?</Text>*/}
                                                <Flex direction={'row'}>
                                                    {
                                                        // isImporting ? <ImportStatusBar /> : <SubmitForm /> // initialListUrl={param} />
                                                        <SubmitForm onStartCallback={close} />
                                                    }
                                                </Flex>
                                            </Content>
                                            <ButtonGroup>
                                                <Button variant="secondary" onPress={close}>
                                                    Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Dialog>
                                    )}
                                </DialogTrigger>
                            </Flex>
                        </View>
                    </Flex>
                }
            />
            {isFetching && <GenericLoadingDialog />}
            <Flex direction={'row'} justifyContent={'space-around'} width={'100vw'} maxWidth={`calc(100vw - 20px)`}>
                <Flex
                    direction={'row'}
                    marginStart={10}
                    marginEnd={10}
                    // justifyContent={'space-around'}
                    wrap={'wrap'}
                    columnGap={100}
                    rowGap={50}
                >
                    <View maxWidth={window.innerWidth < 1000 ? `calc(100vw - 20px)` : 300} width={'100vw'}>
                        <Flex direction={'column'} rowGap={20}>
                            <Flex direction={'row'} justifyContent={'space-around'}>
                                <AvatarImage size={window.innerWidth > 1200 ? 300 : 200} avatarUrl={avatarUrl} />
                            </Flex>
                            {showSubscriberLevelOnProfile && (
                                <Flex direction={'row'} justifyContent={'space-around'}>
                                    <Flex
                                        direction={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        alignContent={'center'}
                                        maxWidth={100}
                                    >
                                        <span className={'subscriberLevelLabel'} style={{ fontSize: '1.2rem' }}>
                                            {__subscriberTier}
                                        </span>
                                        <span className={'subscriberLevelLabel'}>SUPPORTER</span>
                                    </Flex>
                                </Flex>
                            )}
                            <Flex direction={'row'} justifyContent={'space-around'}>
                                <span className={'usernameText'}>{username}</span>
                            </Flex>
                        </Flex>
                    </View>
                    <View
                        maxWidth={window.innerWidth < 1000 ? `calc(100vw - 20px)` : 200}
                        width={'100vw'}
                        marginStart={20}
                    >
                        <Flex direction={'column'} rowGap={30}>
                            <Flex direction={'column'}>
                                <span className={'sectionHeader'}>AVERAGED STATISTICS</span>
                                <Flex direction={'row'} marginTop={10}>
                                    <span className={'sectionTextSubHeader'}>POWER</span>
                                    <div style={{ width: 20 }} />
                                    <span hidden={isFetching || !powerLevelRating}>
                                        {parseFloat(powerLevelRating).toFixed(1)}
                                    </span>
                                    <div style={{ width: 20 }} />
                                </Flex>
                                <Flex direction={'row'} marginTop={10}>
                                    <span className={'sectionTextSubHeader'}>BRACKET</span>
                                    <div style={{ width: 20 }} />
                                    <span hidden={isFetching || !bracketRating}>
                                        {parseFloat(bracketRating).toFixed(1)}
                                    </span>{' '}
                                    <div style={{ width: 20 }} />
                                </Flex>
                                <Flex direction={'row'} marginTop={10}>
                                    <span className={'sectionTextSubHeader'}>SALINITY</span>
                                    <div style={{ width: 20 }} />
                                    <span hidden={isFetching || !saltRating}>
                                        {parseFloat(saltRating).toFixed(1)}
                                    </span>{' '}
                                    <div style={{ width: 20 }} />
                                </Flex>
                                <Flex direction={'row'} marginTop={10}>
                                    <span className={'sectionTextSubHeader'}>SYNERGY</span>
                                    <div style={{ width: 20 }} />
                                    <span hidden={isFetching || !synergyRating}>
                                        {parseFloat(synergyRating).toFixed(1)}
                                    </span>{' '}
                                    <div style={{ width: 20 }} />
                                </Flex>
                            </Flex>
                            <Flex direction={'column'}>
                                <span className={'sectionHeader'}>DECK COUNT</span>
                                <span className={'sectionText'} style={{ whiteSpace: 'pre-line', wordWrap: 'normal' }}>
                                    {count}
                                </span>
                            </Flex>
                        </Flex>
                    </View>
                    <View
                        maxWidth={window.innerWidth < 1000 ? `calc(100vw - 20px)` : 400}
                        width={'100vw'}
                        marginStart={20}
                    >
                        <Flex direction={'column'} rowGap={30}>
                            <Flex direction={'column'}>
                                <span className={'sectionHeader'}>BIO</span>
                                <span className={'sectionText'} style={{ whiteSpace: 'pre-line', wordWrap: 'normal' }}>
                                    {bio}
                                </span>
                            </Flex>
                            {!hideLinkedAccounts && (
                                <Flex direction={'column'}>
                                    <span className={'sectionHeader'}>LINKED PROFILES</span>
                                    {Object.keys(linkedProfiles || {}).map((userProvider) => {
                                        return (
                                            <Flex direction={'column'} key={`link_container_${userProvider}`}>
                                                {/*<span className={'sectionText'}>{userProvider.toUpperCase()}</span>*/}
                                                {Object.keys(linkedProfiles[userProvider] || {}).map((userName) => {
                                                    return (
                                                        <View
                                                            key={`link_${userProvider}_${userName}`}
                                                            marginEnd={20}
                                                            // marginTop={15}
                                                            // marginBottom={15}
                                                        >
                                                            <Flex direction={'row'}>
                                                                <Flex direction={'row'} marginTop={10}>
                                                                    <img
                                                                        src={getFavIcon(userProvider)}
                                                                        height={20}
                                                                        width={20}
                                                                    />
                                                                    <span
                                                                        className={'sectionTextSubHeader'}
                                                                        style={{ marginLeft: 10 }}
                                                                    >
                                                                        {userProvider.toUpperCase()}
                                                                    </span>
                                                                    <div style={{ width: 20 }} />
                                                                    <span>{userName}</span>{' '}
                                                                    <div style={{ width: 20 }} />
                                                                </Flex>
                                                                <ActionButton
                                                                    onPress={() =>
                                                                        window.open(
                                                                            linkedProfiles[userProvider][userName]
                                                                                .profileUrl,
                                                                            `_blank`
                                                                        )
                                                                    }
                                                                    UNSAFE_style={{ cursor: 'pointer' }}
                                                                    maxWidth={300}
                                                                    marginTop={6}
                                                                >
                                                                    <LinkOut size={'S'} />
                                                                </ActionButton>
                                                            </Flex>
                                                        </View>
                                                    );
                                                })}
                                            </Flex>
                                        );
                                    })}
                                </Flex>
                            )}
                        </Flex>
                    </View>
                </Flex>
            </Flex>
            <ResultsListing
                includeSortByCount={false}
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSortChange={handleSortChange}
                handleSortDirectionChange={handleSortDirectionChange}
                disableNavChanges={true}
                allowDeckPreview={false}
                // handleSelectionChange={handleLeaderboardSelectionChange}
                handleIndexSelection={handleIndexSelection}
                hasMore={nextCursor !== null}
                selectedListIndex={selectedListIndex}
                selectedListIndexPrefix={'users_'}
                selectedListKey={selectedListKey}
                columns={resultsColumns.LEADERBOARD()}
                columnRenderer={resultsColumnRenderers.LEADERBOARD}
                listItems={listItems}
                cellRenderer={resultsCellRenderers.LEADERBOARD}
                handleLoadMore={handleLoadMore}
                loadingState={loadingState}
                showFilterPanel={true}
                onFiltersChange={handleFiltersChange}
                hideAuthorFilter={true}
                filters={searchFilters}
            />
        </Flex>
    );
}
