import { ActionButton, Flex, Text, View } from '@adobe/react-spectrum';
import React from 'react';
import isMobile from 'ismobilejs';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useDispatch } from 'react-redux';
import LinkOut from '@spectrum-icons/workflow/LinkOut';

export function BreadCrumbDeckLinkButton(props) {
    const mobile = isMobile(window.navigator).phone || window.innerWidth <= 600;
    const dispatch = useDispatch();

    return (
        <View borderWidth="thin" borderColor="light" borderRadius="medium" padding="size-50" minHeight="0px">
            <Flex direction="row" gap="size-100">
                <ActionButton
                    alignSelf="flex-end"
                    isDisabled={props?.isDisabled}
                    aria-label="View deck"
                    UNSAFE_className={'breadCrumbButton'}
                    isQuiet={true}
                    onPress={() => {
                        const url = props.url?.replace(/\/\?cb\=.*/, '') || '';
                        logEvent(getAnalytics(), 'preview_open_deck', { url });
                        window.open(url, `_blank`);
                    }}
                >
                    {!mobile ? (
                        <Text
                            marginTop={3}
                            UNSAFE_style={{
                                paddingLeft: '10px',
                                paddingRight: '0px',
                                paddingTop: '0px',
                                paddingBottom: '0px',
                            }}
                            UNSAFE_className={
                                props?.isDisabled ? 'reportCardSectionTitlesDisabled' : 'reportCardSectionTitles'
                            }
                        >
                            <Flex direction={'row'} justifyContent={'space-between'} columnGap={5}>
                                VIEW DECK
                                <LinkOut />
                            </Flex>
                        </Text>
                    ) : (
                        <LinkOut />
                    )}
                </ActionButton>
            </Flex>
        </View>
    );
}
