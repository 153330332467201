import {
    ActionButton,
    Button,
    ButtonGroup,
    Content,
    Dialog,
    DialogTrigger,
    Divider,
    Flex,
    Heading,
    Text,
    View,
} from '@adobe/react-spectrum';
import ShareAndroid from '@spectrum-icons/workflow/ShareAndroid';
import QRCode from 'react-qr-code';
import Copy from '@spectrum-icons/workflow/Copy';
import React from 'react';
import isMobile from 'ismobilejs';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { toast } from 'react-toastify';

export function BreadCrumbShareButton(props) {
    const mobile = isMobile(window.navigator).phone || window.innerWidth <= 600;

    return (
        <View borderWidth="thin" borderColor="light" borderRadius="medium" padding="size-50" minHeight="0px">
            <Flex direction="row" gap="size-100">
                <DialogTrigger type={'modal'}>
                    <ActionButton
                        alignSelf="flex-end"
                        isDisabled={props.isDisabled}
                        isQuiet="true"
                        UNSAFE_style={{ cursor: 'pointer' }}
                        aria-label="Share"
                        // onPress={handleAddNewDeckPress}
                    >
                        {!mobile ? (
                            <Text
                                marginTop={3}
                                UNSAFE_style={{
                                    paddingLeft: '10px',
                                    paddingRight: '0px',
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                }}
                                UNSAFE_className={
                                    props?.isDisabled ? 'reportCardSectionTitlesDisabled' : 'reportCardSectionTitles'
                                }
                            >
                                <Flex direction={'row'} justifyContent={'space-between'} columnGap={5}>
                                    SHARE
                                    <ShareAndroid />
                                </Flex>
                            </Text>
                        ) : (
                            <ShareAndroid />
                        )}
                    </ActionButton>
                    {(close) => (
                        <Dialog width={'calc(100vw - 40px)'} maxWidth={500}>
                            <Heading>{props?.label || 'Share'}</Heading>
                            <Divider />
                            <Content>
                                <Flex direction={'column'}>
                                    <Flex direction={'row'} justifyContent={'center'}>
                                        <QRCode
                                            value={window.location.href}
                                            bgColor={'#000000'}
                                            fgColor={'#FF0000'}
                                            size={200}
                                        />
                                    </Flex>
                                    <ActionButton
                                        marginTop={25}
                                        alignSelf="center"
                                        isDisabled={props?.isDisabled}
                                        UNSAFE_style={{ cursor: 'pointer', padding: 10 }}
                                        aria-label={props?.label || 'Share'}
                                        onPress={() => {
                                            navigator.clipboard.writeText(window.location.href);
                                            logEvent(getAnalytics(), `share_ ${props?.label}`);
                                            toast(`Link copied!`);
                                        }}
                                    >
                                        <Flex direction={'row'}>
                                            <span
                                                style={{
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    maxWidth: mobile ? 'calc(100vw - 150px)' : 300,
                                                    width: 300,
                                                }}
                                            >
                                                {window.location.href}
                                            </span>
                                            <Copy />
                                        </Flex>
                                    </ActionButton>
                                </Flex>
                            </Content>
                            <ButtonGroup>
                                <Button variant="secondary" onPress={close}>
                                    Close
                                </Button>
                            </ButtonGroup>
                        </Dialog>
                    )}
                </DialogTrigger>
            </Flex>
        </View>
    );
}
