import React, { useState } from 'react';
import {
    Flex,
    Breadcrumbs,
    Item,
    View,
    DialogTrigger,
    ActionButton,
    Dialog,
    Heading,
    Divider,
    Content,
    ButtonGroup,
    Button,
} from '@adobe/react-spectrum';
import './BreadCrumbs.css';
import { useDispatch, useSelector } from 'react-redux';
import { setAppRoute } from '../../data/redux/slices/appSlice';
import BreadcrumbNavigation from '@spectrum-icons/workflow/BreadcrumbNavigation';
import * as path from 'path';
import isMobile from 'ismobilejs';
import AddCircle from '@spectrum-icons/workflow/AddCircle';
import { SubmitForm } from '../submitform/SubmitForm';

export function BreadCrumbs(props) {
    const dispatch = useDispatch();
    const appRoute = useSelector((state) => state?.app.route);
    const deck = useSelector((state) => state?.deck.deck);
    const commander = useSelector((state) => state.commanderDetails?.details);
    const author = useSelector((state) => state.authorDetails?.details);
    const mobile = isMobile(window.navigator).phone;
    const isRefreshing = useSelector((state) => state.import.isRefreshing);
    const isImporting = useSelector((state) => state.import.isImporting);
    const isFailedFetch = useSelector((state) => state.deck.isFailedFetch);

    const pathList = [{ path: '/', label: 'Home' }];
    const currentPath = appRoute.path === '' ? window.location.pathname : appRoute.path;

    if (currentPath.includes(`/details/deck`) && deck !== null)
        appRoute.previousRoute.split(`/`).forEach((path) => {
            switch (path) {
                case 'commanders':
                    pathList.push({ path: '/commanders', label: 'Commanders' });
                    break;
                case 'authors':
                    pathList.push({ path: '/authors', label: 'Authors' });
                    break;
                case 'id':
                    if (appRoute.previousRoute.includes('/commanders/id')) {
                        let commanderNames = '';
                        let commanderNamesOneLine = '';

                        for (let i = 0; i < commander?.commanders?.length; i++) {
                            if (i > 0) {
                                commanderNames = `${commanderNames}\n`;
                                commanderNamesOneLine = `${commanderNamesOneLine} + `;
                            }

                            commanderNames = `${commanderNames}${commander?.commanders?.[i]?.name}`;
                            commanderNamesOneLine = `${commanderNamesOneLine}${commander?.commanders?.[i]?.name}`;
                        }

                        pathList.push({ path: appRoute.previousRoute, label: commanderNamesOneLine || '' });
                    } else if (appRoute.previousRoute.includes('/authors/id')) {
                        pathList.push({ path: appRoute.previousRoute, label: author?.id || '' });
                    }
                    break;
            }
        });

    currentPath.split(`/`).forEach((path) => {
        switch (path) {
            case '/':
                break;
            case 'details':
                if (deck) {
                    pathList.push({ path: currentPath, label: 'Details' }); //deck?.title || 'Unnamed Deck' });
                }

                break;
            case 'commanders':
                pathList.push({ path: '/commanders', label: 'Commanders' });
                break;
            case 'authors':
                pathList.push({ path: '/authors', label: 'Authors' });
                break;
            case 'bot':
                pathList.push({ path: '/bot', label: 'Discord Bot' });
                break;
            case 'changelog':
                pathList.push({ path: '/changelog', label: 'Changelog' });
                break;
            case 'contact':
                pathList.push({ path: '/contact', label: 'Contact' });
                break;
            case 'faq':
                pathList.push({ path: '/faq', label: 'FAQ' });
                break;
            case 'meta':
                pathList.push({ path: '/meta', label: 'cEDH Meta' });
                break;
            case 'archetypes':
                pathList.push({ path: '/archetypes', label: 'Archetypes' });
                break;
            case 'algorithm':
                pathList.push({ path: '/algorithm', label: 'Algorithm' });
                break;
            case 'id':
                if (author || commander) {
                    if (window.location.pathname.includes('/commanders/id')) {
                        let commanderNames = '';
                        let commanderNamesOneLine = '';

                        for (let i = 0; i < commander?.commanders?.length; i++) {
                            if (i > 0) {
                                commanderNames = `${commanderNames}\n`;
                                commanderNamesOneLine = `${commanderNamesOneLine} + `;
                            }

                            commanderNames = `${commanderNames}${commander?.commanders?.[i]?.name}`;
                            commanderNamesOneLine = `${commanderNamesOneLine}${commander?.commanders?.[i]?.name}`;
                        }
                        pathList.push({ path: currentPath, label: commanderNamesOneLine || '' });
                    } else if (window.location.pathname.includes('/authors/id')) {
                        pathList.push({ path: currentPath, label: author?.id || '' });
                    }
                }
                break;
        }
    });

    const maxLength = mobile ? 15 : 150;
    const index = pathList.length - 1;
    // pathList[index].label =
    //     pathList[index].label.length > maxLength
    //         ? `${pathList[index].label.substring(0, maxLength)}...`
    //         : pathList[index].label;

    // console.log(JSON.stringify(pathList, null, 4));

    // pathList.push({
    //     path: '/commanders',
    //     label: 'Commandsaders sadlfj adsd dasdac asc asjc askjcnj32 casca cwkj sc calks  skclas cas cklsa ck askc sak csakl ckasl ckas cksa cksal clkas ckl sakc sak cksa cask caskclk wlc wk cvka k wk cvwa cvwk cvwaklcv awk cvkaw cwk cvwakc awka wkc wka cvwka cawcawk cwka cwakc akw cawc akwc kwa ckwc wakc',
    // });

    const handleRouteClick = (path) => {
        console.log(`GOT ==> ${path}`);
        dispatch(setAppRoute({ route: path }));
    };

    return (
        <Flex direction={'column'} UNSAFE_className={'stickyBreadcrumbBar'}>
            <div style={{ background: '#0e0e0e' }}>
                <div style={{ position: 'absolute', top: 10, right: 10, display: 'none' }} id={'defaultImportButton'}>
                    <View
                        borderWidth="thin"
                        borderColor="light"
                        borderRadius="medium"
                        padding="size-50"
                        backgroundColor="red-400"
                        minHeight="0px"
                    >
                        <Flex direction="row" gap="size-100">
                            <DialogTrigger type={'modal'}>
                                <ActionButton
                                    type="reset"
                                    alignSelf="flex-end"
                                    isDisabled={isImporting || isRefreshing}
                                    isQuiet="true"
                                    UNSAFE_style={{ cursor: 'pointer' }}
                                    aria-label="Import new deck"
                                    // onPress={handleAddNewDeckPress}
                                >
                                    <AddCircle />
                                </ActionButton>
                                {(close) => (
                                    <Dialog width={'calc(100vw - 40px)'} maxWidth={800}>
                                        <Heading>Import Deck</Heading>
                                        {/*<Header>Connection status: Connected</Header>*/}
                                        <Divider />
                                        <Content>
                                            {/*<div style={{ maxWidth: 800, width: '100%' }}>*/}
                                            {/*<Text>Start speed test?</Text>*/}
                                            <Flex direction={'row'}>
                                                {
                                                    // isImporting ? <ImportStatusBar /> : <SubmitForm /> // initialListUrl={param} />
                                                    <SubmitForm onStartCallback={close} />
                                                }
                                            </Flex>
                                        </Content>
                                        <ButtonGroup>
                                            <Button variant="secondary" onPress={close}>
                                                Cancel
                                            </Button>
                                        </ButtonGroup>
                                    </Dialog>
                                )}
                            </DialogTrigger>
                        </Flex>
                    </View>
                </div>
                <Flex direction={'row'} justifyContent={'space-around'}>
                    <Flex
                        direction="row"
                        minHeight={20}
                        marginTop={10}
                        marginStart={10}
                        marginEnd={10}
                        marginBottom={20}
                        maxWidth={`calc(100vw - 20px)`}
                        width={2000}
                        // width={'100%'}
                        // maxWidth={'2000px'}
                        justifyContent={'space-between'}
                        // UNSAFE_className={'stickyBreadcrumbBar'}
                    >
                        {/*<BreadcrumbNavigation marginTop={4} />*/}

                        <View overflow="hidden">
                            <Breadcrumbs
                                // isMultiline={true}
                                size={'S'}
                                alignSelf={'center'}
                                // position={'relative'}
                                // top={'7'}
                                UNSAFE_style={{ paddingTop: 5 }}
                                onAction={handleRouteClick}
                            >
                                {pathList.map((subSection) => {
                                    return (
                                        <Item key={subSection.path}>
                                            {/*<span*/}
                                            {/*    style={{*/}
                                            {/*        maxWidth: 300,*/}
                                            {/*        textOverflow: 'ellipsis',*/}
                                            {/*        overflow: 'hidden',*/}
                                            {/*        whiteSpace: 'nowrap',*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {subSection.label}
                                            {/*</span>*/}
                                        </Item>
                                    );
                                })}
                            </Breadcrumbs>
                        </View>
                        <div>{props?.content || <></>}</div>
                    </Flex>
                </Flex>
            </div>
            <div className={'stickyBreadcrumbBarBottom'} />
        </Flex>
    );
}
