import { Content, Dialog, DialogContainer, Divider, Flex, Heading, ProgressCircle } from '@adobe/react-spectrum';
import { useSelector } from 'react-redux';
import RealTimeCustomerProfile from '@spectrum-icons/workflow/RealTimeCustomerProfile';
import React from 'react';

export function AvatarImage(props) {
    return (
        <>
            {props.avatarUrl && props.avatarUrl != '' ? (
                <div
                    style={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: '50%',
                        width: props.size + 1,
                        height: props.size,
                    }}
                >
                    <img
                        src={props.avatarUrl}
                        style={{
                            borderRadius: '50%',
                            width: props.size - 4,
                            height: props.size - 4,
                            marginTop: 2,
                            marginLeft: 2,
                        }}
                    />
                </div>
            ) : (
                <>
                    {props?.showDefaultIcon ? (
                        <RealTimeCustomerProfile
                            UNSAFE_style={{
                                width: props.size,
                                height: props.size,
                            }}
                        />
                    ) : (
                        <div
                        // style={{
                        //     // backgroundColor: '#000000',
                        //     borderRadius: '50%',
                        //     width: props.size + 1,
                        //     height: props.size,
                        // }}
                        />
                    )}
                </>
            )}
        </>
    );
}
