import { ActionButton, Flex, Text, View } from '@adobe/react-spectrum';
import React from 'react';
import isMobile from 'ismobilejs';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { doRefresh } from '../../../data/redux/slices/importSlice';
import Refresh from '@spectrum-icons/workflow/Refresh';
import { useDispatch } from 'react-redux';

export function BreadCrumbRefreshButton(props) {
    const mobile = isMobile(window.navigator).phone || window.innerWidth <= 600;
    const dispatch = useDispatch();

    return (
        <View borderWidth="thin" borderColor="light" borderRadius="medium" padding="size-50" minHeight="0px">
            <Flex direction="row" gap="size-100">
                <ActionButton
                    alignSelf="flex-end"
                    isDisabled={props?.isDisabled}
                    aria-label="Refresh deck"
                    UNSAFE_className={'breadCrumbButton'}
                    isQuiet={true}
                    onPress={() => {
                        logEvent(getAnalytics(), 'preview_refresh', {
                            url: props?.url,
                        });
                        dispatch(doRefresh(props.url));
                    }}
                >
                    {!mobile ? (
                        <Text
                            marginTop={3}
                            UNSAFE_style={{
                                paddingLeft: '10px',
                                paddingRight: '0px',
                                paddingTop: '0px',
                                paddingBottom: '0px',
                            }}
                            UNSAFE_className={
                                props?.isDisabled ? 'reportCardSectionTitlesDisabled' : 'reportCardSectionTitles'
                            }
                        >
                            <Flex direction={'row'} justifyContent={'space-between'} columnGap={5}>
                                REFRESH
                                <Refresh />
                            </Flex>
                        </Text>
                    ) : (
                        <Refresh />
                    )}
                </ActionButton>
            </Flex>
        </View>
    );
}
