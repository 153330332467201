import './App.css';
import { Flex } from '@adobe/react-spectrum';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Outlet, ScrollRestoration } from 'react-router-dom';
import { HeaderBar } from './components/headerBar/HeaderBar';
import { FooterBar } from './components/footerBar/FooterBar';
import { handleUpdatedAppRoute } from './data/redux/slices/appSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';

export default function AppBody(props) {
    const navigate = useNavigate();
    const route = useSelector((state) => state.app.route);
    const dispatch = useDispatch();
    const auth = useAuth();
    const userProfileName = useSelector((state) => state.userDetails?.profile?.metadata?.profileName);

    const prevRoute = useRef(route?.path || '/');

    if (
        /\/user\/id\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/.test(window.location.href) &&
        userProfileName &&
        userProfileName !== ''
    ) {
        window.history.replaceState({}, null, `/user/id/${userProfileName}`);
    }

    useEffect(() => {
        if (!window.location.href.includes('?code=')) {
            let currentRoute = route?.path;

            if (prevRoute.current !== currentRoute) {
                const uri = window.location.pathname;

                if (!currentRoute && uri !== prevRoute.current) {
                    currentRoute = uri;
                }

                prevRoute.current = currentRoute || '/';

                navigate(currentRoute);
                dispatch(handleUpdatedAppRoute({ route: currentRoute, firstRouteHandled: false }));
            }
        }
    }, [route]);

    window.addEventListener('popstate', (event) => {
        if (window.location.href !== route.path && !window.location.href.includes('?code=')) {
            const uri = window.location.pathname;

            if (prevRoute.current !== uri) {
                prevRoute.current = uri;
                dispatch(handleUpdatedAppRoute({ route: uri }));
            }
        }
    });

    return (
        // <ScrollRestoration>
        <div style={{ maxWidth: '100vw', overflowX: 'clip' }}>
            <Flex id="AppContainer" direction="column">
                <HeaderBar />
                <Flex id="ContentContainer" direction="row" justifyContent="center">
                    <Flex
                        id="CenterContentContainer"
                        direction="column"
                        marginBottom="35px"
                        maxWidth={2000}
                        width={'100vw'}
                        // width={2000}
                    >
                        <Outlet />
                    </Flex>
                </Flex>
                <FooterBar />
            </Flex>
        </div>
        // </ScrollRestoration>
    );
}
