import React from 'react';
import './App.css';
import { defaultTheme, Provider as ProviderV3, Flex } from '@adobe/react-spectrum';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from './data/redux/store/AppStore';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { hydrate } from './data/redux/slices/appSlice';
import AppRouter from './AppRouter';

function App() {
    try {
        if (!store?.getState()?.app?.isHydrated) {
            const firebaseConfig = {
                apiKey: 'AIzaSyBwfiLrvKfB6lBzToMhkZbdjbot2xpBZ1U',
                authDomain: 'commandersalt-a90fc.firebaseapp.com',
                projectId: 'commandersalt-a90fc',
                storageBucket: 'commandersalt-a90fc.appspot.com',
                messagingSenderId: '1021980073373',
                appId: '1:1021980073373:web:e7590afb834b6a0f20a374',
                measurementId: 'G-4ZBGH8CG72',
            };

            try {
                const app = initializeApp(firebaseConfig);
                const analytics = getAnalytics(app);
                logEvent(analytics, 'app_load');
                global.firebaseApp = app;
            } catch (error) {
                console.log(`unable to initialize metrics`, error);
            }

            if (window.location.toString().includes(`/#/`)) {
                window.location = window.location.toString().replace(/\/#\//g, '/');
            }

            try {
                store.dispatch(hydrate());
            } catch (error) {
                console.log(`unable to hydrate app state`, error);
            }
        }

        return (
            <div>
                <Provider store={store}>
                    <ProviderV3 theme={defaultTheme} colorScheme="dark">
                        <div className={'appWrapper'}>
                            <AppRouter />
                        </div>
                    </ProviderV3>
                </Provider>
            </div>
        );
    } catch (error) {
        return (
            <div>
                <span>
                    Unable to render page! Please report error to discord server at https://discord.gg/Yb2CAxcJcf
                </span>
                <div>{JSON.stringify(error, null, 4)}</div>
            </div>
        );
    }
}

export default App;
