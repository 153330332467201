import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { AuthProvider, useAuth } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import { getClientDomainPrefix } from './data/DynamoConnector';
import { ToastContainer } from 'react-toastify';

global.signOutRedirect = () => {
    const clientId = '63des98g40o9uj0ql7kbstqgp8';
    const logoutUri = getClientDomainPrefix();
    const cognitoDomain = 'https://commandersalt.auth.us-east-1.amazoncognito.com';
    window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
};

global.cognitoAuthConfig = {
    authority: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_erfnC6lSF',
    client_id: '63des98g40o9uj0ql7kbstqgp8',
    client_secret: '1946eh194kqhs2aqie8bab0v3b3aeo1thqiij932704m1ta84ku',
    redirect_uri: getClientDomainPrefix(),
    response_type: 'code',
    scope: 'email openid',
    onSigninCallback: () => {
        window.location = getClientDomainPrefix();
    },
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    // userStore: new WebStorageStateStore({ store: window.localStorage }),
};

//
// // wrap the application with AuthProvider
// root.render(
//     <AuthProvider {...cognitoAuthConfig}>
//         <App />
//     </AuthProvider>
// );

ReactDOM.render(
    <>
        <AuthProvider {...global.cognitoAuthConfig}>
            <App />
        </AuthProvider>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            style={{ zIndex: 200000 }}
        />
    </>,
    document.getElementById('root')
);

// ReactDOM.render(<App />);
