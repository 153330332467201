/* eslint-disable no-loop-func */
import { toast } from 'react-toastify';
import { getAnalytics, logEvent } from 'firebase/analytics';
import store from './redux/store/AppStore';
import { SORT_MAP } from './redux/slices/leaderboardSlice';
import { User } from 'oidc-client-ts';

export const getUser = () => {
    const oidcStorage = localStorage.getItem(
        `oidc.user:${global.cognitoAuthConfig.authority}:${global.cognitoAuthConfig.client_id}`
    );
    if (!oidcStorage) {
        return null;
    }

    const user = User.fromStorageString(oidcStorage);
    return user;
};

export const getClientDomainPrefix = () => {
    const href = window.location.href;

    if (href.includes(`localhost`)) {
        return `http://localhost:3000`;
    } else if (href.includes(`192.168`)) {
        return `http://192.168.1.27:3000`;
    }

    return `https://commandersalt.com`;
};

export const getDomainPrefix = () => {
    // const href = window.location.href;
    //
    // if (href.includes(`localhost`)) {
    //     return `http://localhost:3333`;
    // } else if (href.includes(`192.168`)) {
    //     return `http://192.168.1.27:3333`;
    // }

    return `https://api.commandersalt.com`;
};

let isFetchingPreviewDeck = false;

export const DynamoConnector = {
    getUserDetails: async (id, callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/details?id=${id}&isUser=true`;

            const headers = {
                'Content-type': 'application/json;charset=UTF-8',
            };

            if (getUser()?.hasOwnProperty('access_token')) {
                headers['authorization'] = `Bearer ${getUser().access_token}`;
            }

            const results = await (
                await fetch(fetchUri, {
                    method: 'GET',
                    headers,
                })
            ).json();

            callback(results);
        } catch (error) {
            toast(`Unable to find user`);
            callback(null);
        }
    },
    deletePatreonLink: async (callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/patreon`;

            const results = await fetch(fetchUri, {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                    authorization: `Bearer ${getUser().access_token}`,
                },
            });

            if (results.status > 200) {
                callback(false);
            } else {
                toast(`Patreon link deleted`);
                callback(true);
            }
        } catch (error) {
            callback(false);
        }
    },
    postUpdateUserPreferences: async (payload = [], callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/user?preferences=${encodeURIComponent(JSON.stringify(payload))}`;

            await fetch(fetchUri, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                    authorization: `Bearer ${getUser().access_token}`,
                },
            });

            toast(`Preferences saved`, { type: 'success' });
            callback(null);
        } catch (error) {
            toast(`Failed to save preferences`);
            callback(null);
        }
    },
    uploadAvatar: async (file, size, callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/uploadavatar`;
            const formData = new FormData();
            formData.append(
                'data',
                JSON.stringify({
                    name: file.name,
                    description: file.description,
                })
            );
            formData.append(`files`, file, file.name);

            const results = await fetch(fetchUri, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${getUser().access_token}`,
                },
                body: formData,
            });

            toast(`Avatar updated`, { type: 'success' });
            callback(results);
        } catch (error) {
            toast(`Failed to update avatar`);
            callback(null);
        }
    },
    getRefreshPatreonMemberships: async (callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/patreon`;

            const results = await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                    authorization: `Bearer ${getUser().access_token}`,
                },
            });

            if (results.status > 200) {
                callback(false);
            } else {
                toast(`Patreon data refreshed`);
                callback(true);
            }
        } catch (error) {
            callback(false);
        }
    },
    validatePatreonCode: async (code, callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/patreon?code=${code}`;

            const results = await fetch(fetchUri, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                    authorization: `Bearer ${getUser().access_token}`,
                },
            });

            callback(results);
        } catch (error) {
            callback(null);
        }
    },
    deleteAccountLink: async (list, callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/linkaccount?list=${encodeURIComponent(JSON.stringify(list))}`;

            const results = await fetch(fetchUri, {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                    authorization: `Bearer ${getUser().access_token}`,
                },
            });

            if (results.status > 200) {
                callback(false);
            } else {
                toast(`Linked account(s) deleted`);
                callback(true);
            }
        } catch (error) {
            callback(false);
        }
    },
    postValidateLinkCode: async (provider, username, callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/linkaccount?provider=${provider}&username=${username}`;

            const results = await fetch(fetchUri, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                    authorization: `Bearer ${getUser().access_token}`,
                },
            });

            if (results.status > 200) {
                callback(false);
            } else {
                callback(true);
            }
        } catch (error) {
            callback(false);
        }
    },
    postManagedDecksOp: async (operation, deckIds = [], callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/managedecks?operation=${operation}&list=${encodeURIComponent(
                JSON.stringify(deckIds)
            )}`;

            const results = await (
                await fetch(fetchUri, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json;charset=UTF-8',
                        authorization: `Bearer ${getUser().access_token}`,
                    },
                })
            ).json();

            callback(results);
        } catch (error) {
            callback(null);
        }
    },
    getManagedDecks: async (callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/managedecks`;

            const results = await (
                await fetch(fetchUri, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json;charset=UTF-8',
                        authorization: `Bearer ${getUser().access_token}`,
                    },
                })
            ).json();

            callback(results);
        } catch (error) {
            callback(null);
        }
    },
    getAccountLinkCode: async (provider, callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/linkaccount?provider=${provider}`;

            const results = await (
                await fetch(fetchUri, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json;charset=UTF-8',
                        authorization: `Bearer ${getUser().access_token}`,
                    },
                })
            ).json();

            callback(results);
        } catch (error) {
            callback(null);
        }
    },
    getUserProfile: async (callback) => {
        try {
            let fetchUri = `${getDomainPrefix()}/user`;

            const results = await (
                await fetch(fetchUri, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json;charset=UTF-8',
                        authorization: `Bearer ${getUser().access_token}`,
                    },
                })
            ).json();
            callback(results);
        } catch (error) {
            callback(null);
        }
    },
    getBatchRefreshAuthorDeckList: async (list, callback) => {
        const promises = [];
        list.forEach((item) => {
            let fetchUri = `${getDomainPrefix()}/details?id=${item.id}`;
            promises.push(
                fetch(fetchUri, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json;charset=UTF-8',
                    },
                })
            );
        });

        await Promise.all(promises);
        callback && callback();
    },
    getStats: async (callback) => {
        let fetchUri = `${getDomainPrefix()}/stats`;

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getDeck: async (id, callback, errorCallback) => {
        try {
            const deck = store.getState().preview.cache?.[id];
            // console.log(JSON.stringify(deck, null, 4));
            if (deck) {
                // console.log(`==> returning cached deck id ${id}`);
                callback(deck);

                // bail early
                return;
            }

            if (isFetchingPreviewDeck) {
                return null;
            }

            isFetchingPreviewDeck = true;
            let fetchUri = `${getDomainPrefix()}/details?id=${id}`;

            const headers = {
                'Content-type': 'application/json;charset=UTF-8',
            };

            if (getUser()?.hasOwnProperty('access_token')) {
                headers['authorization'] = `Bearer ${getUser().access_token}`;
            }

            const results = await (
                await fetch(fetchUri, {
                    method: 'GET',
                    headers,
                })
            ).json();

            isFetchingPreviewDeck = false;
            callback && callback(results);
        } catch (error) {
            // isFetchingPreviewDeck = false;
            // errorCallback && errorCallback();
            toast(`Unable to find deck`);
            callback(null);
        }
    },
    getDeckForPreview: async (id, callback, errorCallback) => {
        try {
            const deck = store.getState().preview.cache?.[id];
            // console.log(JSON.stringify(deck, null, 4));
            if (deck) {
                // console.log(`==> returning cached deck id ${id}`);
                callback(deck);

                // bail early
                return;
            }

            if (isFetchingPreviewDeck) {
                return null;
            }

            isFetchingPreviewDeck = true;
            let fetchUri = `${getDomainPrefix()}/details?id=${id}`;

            const results = await (
                await fetch(fetchUri, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json;charset=UTF-8',
                    },
                })
            ).json();

            isFetchingPreviewDeck = false;
            callback(results);
        } catch (error) {
            isFetchingPreviewDeck = false;
            errorCallback && errorCallback();
        }
    },
    getCardDetails: async (id, callback) => {
        let fetchUri = `${getDomainPrefix()}/details?id=${id?.replace(/-/g, '_')}&isCard=true`;

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getCommanderDetails: async (id, callback) => {
        let fetchUri = `${getDomainPrefix()}/details?id=${id?.replace(/-/g, '_')}&isCommander=true`;

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getAuthorDetails: async (id, callback) => {
        let fetchUri = `${getDomainPrefix()}/details?id=${id}&isAuthor=true`;

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getCEDHMeta: async (callback) => {
        let fetchUri = `${getDomainPrefix()}/meta`;

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getDeckUpgrades: async (
        id,
        category,
        allowReservedList = true,
        colorFilters = null,
        selectedTypes = [],
        cursor,
        callback
    ) => {
        let fetchUri = `${getDomainPrefix()}/upgrades?id=${id}&category=${category}&allowReservedList=${allowReservedList}`;

        if (colorFilters) {
            fetchUri = `${fetchUri}&colorFilters=${colorFilters}`;
        }

        if (selectedTypes.length > 0) {
            fetchUri = `${fetchUri}&selectedTypes=${JSON.stringify(selectedTypes)}`;
        }

        if (cursor) {
            cursor = encodeURIComponent(JSON.stringify(cursor));
            fetchUri = `${fetchUri}&cursor=${cursor}`;
        }

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getList: async (cursor, category, index = null, filters, direction = SORT_MAP.DIRECTION.DESC, callback) => {
        let fetchUri = `${getDomainPrefix()}/list?category=${category}`;

        if (index === null) {
            index = SORT_MAP.POWERLEVEL.index;
        }

        // console.log(`=> ${JSON.stringify(direction)}`);
        const directionPrefix = direction === SORT_MAP.DIRECTION.DESC ? '' : '-';

        fetchUri = `${fetchUri}&sort=${directionPrefix}${index}`;

        if (cursor) {
            cursor = encodeURIComponent(JSON.stringify(cursor));
            fetchUri = `${fetchUri}&cursor=${cursor}`;
        }

        if (filters) {
            if (filters?.colorFilters) {
                fetchUri = `${fetchUri}&colorIdentity=${filters.colorFilters}`;
            }

            if (filters?.range) {
                fetchUri = `${fetchUri}&high=${filters.range.high}&low=${filters.range.low}`;
            }

            if (filters?.query && filters?.queryType) {
                fetchUri = `${fetchUri}&query=${filters.query}&queryType=${filters.queryType}`;
            }

            if (filters?.id) {
                fetchUri = `${fetchUri}&id=${filters.id}`;
            }

            if (filters.archetypes) {
                fetchUri = `${fetchUri}&archetypes=${filters.archetypes}`;
            }
        }

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getShallowList: async (query, category, index = null, callback) => {
        let fetchUri = `${getDomainPrefix()}/list/shallow?category=${category}&id=${query}`;

        if (index) {
            fetchUri = `${fetchUri}&index=${index}`;
        }

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    addAuthorToPod: async (authorId, podId, doneCallback, errorCallback) => {
        const analytics = getAnalytics();

        try {
            const request = await fetch(`${getDomainPrefix()}/pods/authors/add?podId=${podId}&authorId=${authorId}`, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            });
            const response = await request.json();

            if (request.status !== 200) {
                throw response;
            }

            // logEvent(analytics, 'pod_add_author_success', { podId, authorId });

            doneCallback({
                ...response?.pod,
                key: response?.pod?.id,
            });
        } catch (error) {
            logEvent(analytics, 'pod_add_author_fail', { podId, authorId });
            errorCallback(error);
            toast(`${error.message}`);
        }
    },
    importDeckList: async (url, statusCallback, doneCallback, errorCallback, isRefresh = false) => {
        const analytics = getAnalytics();

        let oldDeckId = 'sdlfksdf';
        if (window.location.pathname.includes(`/details/deck/`)) {
            oldDeckId = window.location.pathname.substring(window.location.pathname.indexOf(`/deck/`) + 6);
        }

        try {
            const startTime = performance.now();

            statusCallback({
                type: `card`,
                card: 'Loading...',
                percentage: 1,
            });

            let requestUri = `${getDomainPrefix()}/import?url=${url}`;
            if (oldDeckId && isRefresh) {
                requestUri = `${requestUri}&oldDeckId=${oldDeckId}`;
            }

            const headers = {
                'Content-type': 'application/json;charset=UTF-8',
            };

            if (getUser()?.hasOwnProperty('access_token')) {
                headers['authorization'] = `Bearer ${getUser().access_token}`;
            }

            const request = await fetch(requestUri, {
                method: 'GET',
                headers,
            });

            const response = await request.json();

            if (request.status !== 200) {
                throw response;
            }

            const endTime = performance.now();
            const responseTime = endTime - startTime;
            logEvent(getAnalytics(), 'import_time', { timing: responseTime });

            doneCallback({
                ...response.deck,
                key: response.deck.id,
            });

            // if (oldDeckId && response?.deck?.id && response?.deck?.id !== oldDeckId) {
            //     await fetch(`${getDomainPrefix()}/deletedeck?id=${oldDeckId}`, {
            //         method: 'GET',
            //         headers: {
            //             'Content-type': 'application/json;charset=UTF-8',
            //         },
            //     });
            // }
        } catch (error) {
            logEvent(analytics, 'import_failed', { url });
            errorCallback(error);
            toast(`${error.message}`);
        }
    },
    postContactPing: async (payload, callback) => {
        try {
            const email = encodeURIComponent(payload.email);
            const name = encodeURIComponent(payload.name);
            const message = encodeURIComponent(payload.message);

            const request = await fetch(`${getDomainPrefix()}/contact?email=${email}&name=${name}&message=${message}`, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            });

            const response = await request.json();

            if (request.status !== 200) {
                throw response;
            }
        } catch (error) {
            callback(); // TODO: add status to ui
            logEvent(analytics, 'email_failed', { payload });
        }

        logEvent(analytics, 'email_success', { payload });
        callback();
    },
};
